import React, { useEffect, useMemo, useState } from "react";
import NotificationsAndAvatar from "../../../components/NotificationsAndAvatar/NotificationsAndAvatar";
import { motion } from "framer-motion";
import useApi from "../../../hooks/useApi";
import { Appointment } from "../../../interfaces/Appointment";
import appointmentService from "../../../services/AppointmentService/AppointmentService";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { AllRouteConstants } from "../../../router/routes";
import { usePayments } from "../../../hooks/usePayments";
import PaymentMethodType from "../components/PaymentMethodType/PaymentMethodType";
import { cardImgs } from "../../../assets/images";
import PayStatusModal from "../components/PayStatusModal";

const { mastercard } = cardImgs;

export const CheckoutPage: React.FC = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const fetchAppointmentApi = useApi<Appointment, string>(
    appointmentService.getAppointmentById
  );

  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);

  useEffect(() => {
    const run = async () => {
      const appontment = await fetchAppointmentApi.request(state.appointmentId);
      setSelectedAppointment(appontment);
    };

    if (state && state.appointmentId) {
      run();
    }
  }, [state]);

  const getDuration = () => {
    if (!selectedAppointment) return 0;

    if (selectedAppointment.isOnDemand) {
      return selectedAppointment.duration;
    }

    const durationHour = Math.round(
      Math.abs(selectedAppointment.startTime - selectedAppointment.endTime) /
        (1000 * 60 * 60)
    );

    return durationHour;
  };

  const getAmount = () => {
    return (getDuration() * 1).toLocaleString("en-US");
  };

  const {
    cards,
    payWithCard,
    initiatingPayment,
    validatePayment,
    validatingPayment,
    selectedCard,
    onCardSelect,
    initiatingTokenizedPay,
    payStatus,
    resetPayment,
    setPayStatus,
  } = usePayments();

  const closePayStatus = () => {
    resetPayment();
    navigate(AllRouteConstants.main.appointment.index);
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const transactionId = search.get("transaction_id");
    const appointmentId = search.get("tx_ref");
    const status = search.get("status");

    if (status && appointmentId && transactionId) {
      // open modal
      // content should be determined by status field

      if (status === "successful") {
        // call the validate end point
        validatePayment(transactionId, appointmentId);
      } else {
        setPayStatus(status);
      }
    }
  }, []);

  if (validatingPayment) return <h4 className="p-4">Validating...</h4>;

  return (
    <>
      <PayStatusModal status={payStatus} handleClose={closePayStatus} />

      <div className="ml-checkout-page animate__animated animate__fadeIn">
        <div className="ml-checkout-page__row-1">
          <div className="ml-checkout-page__card-container">
            <div className="title">Choose any saved payment methods below:</div>

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
              className="card-0"
            >
              <div className="title">Choose payment method</div>

              {cards.map(({ cardId, last_4digits, expiry }) => (
                <PaymentMethodType
                  key={`checkout-card-${cardId}`}
                  image={mastercard}
                  accountNumberLastFourDigits={last_4digits}
                  expDate={expiry}
                  selected={cardId === selectedCard?.cardId}
                  onCardSelect={onCardSelect(cardId)}
                />
              ))}
            </motion.div>
          </div>

          {selectedAppointment && (
            <div className="ml-checkout-page__card-container">
              <div className="title">&nbsp;</div>

              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="card-0 appointment-details"
              >
                <div className="col head">
                  <span>Course:</span>
                  <span>Topic:</span>
                  <span>Duration:</span>
                  <span>Price:</span>
                </div>

                <div className="col">
                  <span>{selectedAppointment.course}</span>
                  <span>{selectedAppointment.topic}</span>
                  <span>{getDuration()} hour(s)</span>
                  <span>NGN {getAmount()}</span>
                </div>

                <div className="action">
                  <Button
                    label="Proceed"
                    variant="primary"
                    width="fit-content"
                    disable={initiatingPayment || initiatingTokenizedPay}
                    onClick={() => payWithCard(selectedAppointment)}
                  />
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
