import { Dispatch } from "react";
import { Profile_Interface, User } from "../../interfaces/User";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

export const signIn = (data: User) => (dispatch: Dispatch<AuthActionTypes>) => {
  dispatch({ type: ActionConstants.LOGIN, payload: data });
};

export const signOut = () => (dispatch: Dispatch<AuthActionTypes>) => {
  dispatch({ type: ActionConstants.LOGOUT });
};
export const setProfile =
  (data: Profile_Interface) => (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({ type: ActionConstants.SET_PROFILE, payload: data });
  };

export const updateProfile =
  (data: { key: keyof Profile_Interface; data: any }) =>
  (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({ type: ActionConstants.UPDATE_PROFILE, payload: data });
  };
