import platformlogo from "../../../assets/platform-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import DashboardSidebarItem from "./DashboardSidebarItems";
import "./DashboardSidebarStyles.scss";
import useApi from "../../../hooks/useApi";
import authService from "../../../services/authenticationService/AuthService";
import Loader from "../../../components/Loader/Loader";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { IoVideocamSharp } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useAuthActions } from "../../../hooks/useReduxActions";
import Button from "../../../components/Button/Button";

const DashboardSidebar = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthActions();
  const logoutApi = () => authService.logout();
  const logoutApiRequest = useApi<string, any>(logoutApi);

  const { pathname } = useLocation();

  const handleLogout = async () => {
    logoutApiRequest.reset();
    try {
      await logoutApiRequest.request();
      signOut();
      navigate(AllRouteConstants.auth.index);
    } catch (error) {}
  };

  const goToPage = (page: string) => () => {
    if (pathname === AllRouteConstants.main.call.whiteboard) {
      return;
    }

    navigate(page);
  };

  const closeCurrentTab = () => {
    window.close();
  };

  return (
    <>
      {logoutApiRequest.loading ? (
        <Loader />
      ) : (
        <div className="dashboard_sidebar_container">
          <div
            onClick={() => navigate(AllRouteConstants.main.dashboard)}
            className="dashboard_sidebar_logo"
          >
            <img src={platformlogo} alt="logo" />
          </div>

          <div className="dashboard_sidebar_items">
            <DashboardSidebarItem
              paths={["/", AllRouteConstants.main.dashboard]}
              onClick={goToPage(AllRouteConstants.main.dashboard)}
              sidebarIcon={<MdOutlineDashboard />}
              sidebarItemName="Dashboard"
            />

            <DashboardSidebarItem
              paths={[AllRouteConstants.main.appointment.index]}
              onClick={goToPage(AllRouteConstants.main.appointment.index)}
              sidebarIcon={<FaCalendarAlt />}
              sidebarItemName="Appointment"
            />

            <DashboardSidebarItem
              paths={[AllRouteConstants.main.history.index]}
              onClick={goToPage(AllRouteConstants.main.history.index)}
              sidebarIcon={<RxCounterClockwiseClock />}
              sidebarItemName="History"
            />

            <DashboardSidebarItem
              paths={[AllRouteConstants.main.call.index]}
              onClick={goToPage(AllRouteConstants.main.call.index)}
              sidebarIcon={<IoVideocamSharp />}
              sidebarItemName="Call"
            />

            <DashboardSidebarItem
              paths={[
                AllRouteConstants.main.settings.account.index,
                AllRouteConstants.main.settings.payment,
                AllRouteConstants.main.settings.support,
                AllRouteConstants.main.settings.index,
                AllRouteConstants.main.settings.profile.index,
              ]}
              onClick={goToPage(AllRouteConstants.main.settings.index)}
              sidebarIcon={<FiSettings />}
              sidebarItemName="Settings"
            />
          </div>

          {pathname !== AllRouteConstants.main.call.whiteboard && (
            <div className="dashboard_sidebar_logout" onClick={handleLogout}>
              <RiLogoutCircleRLine />
              <span>Logout</span>
            </div>
          )}

          {pathname === AllRouteConstants.main.call.whiteboard && (
            <div className="action">
              <Button
                variant="danger"
                label="Close"
                onClick={closeCurrentTab}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardSidebar;
