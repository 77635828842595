import mastercard from "./mastercard.svg";
import visacard from "./visa.svg";
import unknowncard from "./no-card-type.png";

import successAvatar from "./femaleAvatarVerification.png";
import nuetralAvatar from "./femaleAvatarNeutral.png";
import errorAvatar from "./femaleAvatarError.png";

const cardImgs = { mastercard, visacard, unknowncard };

export { cardImgs, successAvatar, nuetralAvatar, errorAvatar };
