import DashboardLeft from "../components/DashboardLeft/DashboardLeft";
import DashboardRight from "../components/DashboardRight/DashboardRight";
import DashboardHomeMobile from "./DashboardHomeMobile";
import "../DashboardHomeStyles.scss";

export const DashboardHome = () => {
  return (
    <div className="dashboard_home animate__animated animate__fadeIn">
      <div className="dashboard_home_desktop">
        <DashboardLeft />
        <DashboardRight />
      </div>
      <DashboardHomeMobile />
    </div>
  );
};
