import React, { useState } from "react";
import "./CallRightSidebarStyles.scss";
import NotificationsAndAvatar from "../../../../components/NotificationsAndAvatar/NotificationsAndAvatar";
import CallParticipants from "../CallParticipants/CallParticipants";
import { ICameraVideoTrack } from "agora-rtc-react";
import { IUserCallActions } from "../../interfaces/IUserCallActions";
import { CallUser } from "../../../../interfaces/Call";

interface CallSidebar {
  action: (
    action: IUserCallActions,
    payload?: any,
    user?: CallUser
  ) => Promise<void>;
  localVideoTrack: React.MutableRefObject<ICameraVideoTrack | null>;
  adminUser: CallUser;
}

const CallRightSidebar = (props: CallSidebar) => {
  const { action, localVideoTrack, adminUser } = props;

  return (
    <div className="ml_call_home-right_sidebar">
      <div className="ml_call_home-right_sidebar_container">
        <div className="ml_call_home-right_sidebar_navbar">
          <NotificationsAndAvatar />
        </div>
        <div className="ml_call_home-right_sidebar_body">
          <div className="container">
            <CallParticipants
              action={action}
              localVideoTrack={localVideoTrack}
              adminUser={adminUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallRightSidebar;
