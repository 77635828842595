import PaymentHistoryTable from "../components/PaymentHistoryTable/PaymentHistoryTable";
import Header from "../../../components/Header/Header";
import PaymentMethodType from "../components/PaymentMethodType/PaymentMethodType";
import MasterCard from "../../../assets/icons/mastercard.png";
import { usePayments } from "../../../hooks/usePayments";
import Spinner from "../../../components/Spinner/Spinner";

export const Payment = () => {
  const { cards, history, fetchingCards, fetchingHistory } = usePayments();

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="settings_payment_header_container">
        <Header
          message="Your Saved Payment Methods"
          size="md"
          color="#272727"
          weight="600"
        />
      </div>

      {fetchingCards && <Spinner color="#377D3F" fontSize={25} />}

      {cards.map(({ cardId, last_4digits, expiry }) => (
        <PaymentMethodType
          key={`card-${cardId}`}
          image={MasterCard}
          accountNumberLastFourDigits={last_4digits}
          expDate={expiry}
        />
      ))}

      <div className="settings_payment_header_container">
        <Header
          message="Payment History"
          size="md"
          color="#272727"
          weight="600"
        />
      </div>

      {fetchingHistory && <Spinner color="#377D3F" fontSize={25} />}

      {!fetchingHistory && <PaymentHistoryTable tableData={history} />}
    </div>
  );
};
