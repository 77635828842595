import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { PaymentActionTypes } from "./action-types";
import { Card, PaymentHistory } from "../../interfaces/Checkout";

export const initializeCards =
  (data: Card[]) => (dispatch: Dispatch<PaymentActionTypes>) => {
    dispatch({ type: ActionConstants.INIT_CARDS, payload: data });
  };

export const addCard =
  (data: Card) => (dispatch: Dispatch<PaymentActionTypes>) => {
    dispatch({ type: ActionConstants.ADD_CARD, payload: data });
  };

export const removeCard =
  (id: string) => (dispatch: Dispatch<PaymentActionTypes>) => {
    dispatch({
      type: ActionConstants.REMOVE_CARD,
      payload: id,
    });
  };

export const initializePaymentHistory =
  (data: PaymentHistory[]) => (dispatch: Dispatch<PaymentActionTypes>) => {
    dispatch({
      type: ActionConstants.INIT_HISTORY,
      payload: data,
    });
  };
