import { useEffect, useState } from "react";
import platformlogo from "../../../assets/platform-logo.svg";
import visible from "../../../assets/visible.svg";
import notvisible from "../../../assets/not-visible.svg";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import "../AuthStyles.scss";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import AuthError from "../components/AuthError/AuthError";
import { useForm } from "../../../hooks/useForm";
import {
  ResetPasswordFormInput,
  ResetPasswordInput,
} from "../../../interfaces/ResetPasswordInput";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../../../services/authenticationService/AuthService";
import { User } from "../../../interfaces/User";
import useApi from "../../../hooks/useApi";
import OtpInput from "../../../components/OtpInput/OtpInput";
import { AllRouteConstants } from "../../../router/routes";
import PasswordResetModal from "../components/AuthModals/PasswordResetModal";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordResetted, setPasswordResetted] = useState(false);

  let userEmail: string = location?.state?.userEmail;
  useEffect(() => {
    !userEmail && navigate(AllRouteConstants.auth.forgotPassword);
  }, []);

  // Password
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // Reset Password Form
  const resetPasswordForm = useForm<ResetPasswordFormInput>(
    { password: "", confirmPassword: "", code: "" },
    {
      password: emptyValidator,
      confirmPassword: emptyValidator,
      code: emptyValidator,
    }
  );

  const handlePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    resetPasswordForm.onChange("password", e.target.value);
  };

  const handleConfirmPassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    resetPasswordForm.onChange("confirmPassword", e.target.value);
  };

  const handleOtpCode = (value: string) => {
    resetPasswordForm.onChange("code", value);
  };

  const resetPasswordRequest = useApi<User, ResetPasswordInput>(
    authService.confirmpasswordreset
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    resetPasswordForm.resetFormErrors();
    resetPasswordRequest.reset();

    const valid = resetPasswordForm.validate();

    if (valid) {
      try {
        if (
          !resetPasswordForm.form.password ||
          !resetPasswordForm.form.confirmPassword ||
          !resetPasswordForm.form.code
        ) {
          return;
        }
        if (
          resetPasswordForm.form.password !==
          resetPasswordForm.form.confirmPassword
        ) {
          resetPasswordRequest.error = "Passwords don't match";
          return;
        }

        const user = await resetPasswordRequest.request(
          {
            password: resetPasswordForm.form.password,
            email: userEmail,
            code: resetPasswordForm.form.code,
          },
          false
        );

        if (user) {
          setPasswordResetted(true);
        }
      } catch (error) {}
    }
  };

  const handleCloseModal = () => {
    setPasswordResetted(false);
    setTimeout(() => {
      navigate(AllRouteConstants.auth.login);
    }, 1000);
  };

  return (
    <div className="auth_container animate__animated animate__fadeIn">
      <PasswordResetModal
        modalToggler={passwordResetted}
        onClose={handleCloseModal}
      />
      <img alt="mobile" src={platformlogo} />
      <AuthHeader message="Reset Password" />
      <p className="forgot_password_text">
        Your password should be at least 9 characters of lower case and upper
        case.
      </p>
      <form className="reset-form" onSubmit={handleSubmit}>
        <div className="inputs">
          <div>
            <p className="forgot_password_text">
              A verification code was sent to you email {userEmail}, Kindly
              input the code below
            </p>
            <OtpInput
              value={resetPasswordForm.form.code}
              valueLength={6}
              onChange={handleOtpCode}
            />
          </div>

          <Input
            id="password"
            label="Password"
            error={resetPasswordForm.formErrors.password}
            rightIcon={
              <div style={{ marginLeft: "10px" }} onClick={togglePassword}>
                {passwordType === "password" ? (
                  <img src={visible} style={{ width: "20px" }} />
                ) : (
                  <img src={notvisible} style={{ width: "20px" }} />
                )}
              </div>
            }
            inputProps={{
              type: passwordType,
              placeholder: "Enter your password",
              value: resetPasswordForm.form.password,
              onChange: handlePassword,
            }}
          />
          <Input
            id="password"
            label="Confirm Password"
            error={resetPasswordForm.formErrors.confirmPassword}
            rightIcon={
              <div style={{ marginLeft: "10px" }} onClick={togglePassword}>
                {passwordType === "password" ? (
                  <img src={visible} style={{ width: "20px" }} />
                ) : (
                  <img src={notvisible} style={{ width: "20px" }} />
                )}
              </div>
            }
            inputProps={{
              type: passwordType,
              placeholder: "Confirm your password",
              value: resetPasswordForm.form.confirmPassword,
              onChange: handleConfirmPassword,
            }}
          />
        </div>

        <AuthError error={resetPasswordRequest.error} />
        <div style={{ marginTop: "1em" }}>
          <Button
            label="Reset Password"
            disable={
              resetPasswordForm.form.code.length !== 6 ||
              !resetPasswordForm.form.password ||
              resetPasswordForm.form.password !==
                resetPasswordForm.form.confirmPassword
            }
            variant="primary"
            loading={resetPasswordRequest.loading}
          />
        </div>
      </form>
    </div>
  );
};
