import React from "react";
import { ICreateAppointment } from "../../../../interfaces/CreateAppointment";
import AppointmentRadio from "../AppointmentRadio/AppointmentRadio";
import Button from "../../../../components/Button/Button";

const SelectDemandTypeForm = (props: ICreateAppointment) => {
  const { appointmentForm, setCurrentFormStep } = props;
  const { form, onChange } = appointmentForm;

  const goForward = () => setCurrentFormStep(2);

  return (
    <>
      <form onSubmit={goForward}>
        <div className="create_appointment_drawer_body_date_information_time_card_container animate__animated animate__fadeIn">
          <AppointmentRadio
            id="on demand"
            name="on demand"
            label="On Demand"
            radioProps={{
              required: true,
              checked: form.isOnDemand,
              onClick: () => onChange("isOnDemand", !form.isOnDemand),
            }}
          />
          <Button
            label="Continue"
            variant="primary"
            onClick={goForward}
            buttonClassName="create_appointment_personal_form_buttons_back_button"
            size="md"
          />
        </div>

      </form>
    </>
  );
};

export default SelectDemandTypeForm;
