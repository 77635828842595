import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { AppointmentActionTypes } from "./action-types";
import { CallSession, CallUser } from "../../interfaces/Call";
import { CallMessage } from "./reducer";
import { Appointment } from "../../interfaces/Appointment";

export const setCurrentCallSession =
  (data: CallSession | null) =>
  (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.SET_CURRENT_SESSION, payload: data });
  };

export const addCallUser =
  (data: CallUser) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.ADD_CALL_USER, payload: data });
  };

export const clearCallUsers =
  () => (dispatch: Dispatch<AppointmentActionTypes>) => {
    console.log("CLEAR_CALL_USERS");
    dispatch({ type: ActionConstants.CLEAR_CALL_USERS });
  };

export const startCall =
  (data: boolean) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.START_CALL, payload: data });
  };

export const updateCallUsers =
  (data: CallUser[]) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.UPDATE_CALL_USERS, payload: data });
  };
export const shareScreen =
  (data: boolean) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.SHARE_SCREEN, payload: data });
  };

export const addMessage =
  (data: CallMessage) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.Add_MESSAGE, payload: data });
  };

export const setSelectedAppointment =
  (data?: Appointment) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({
      type: ActionConstants.SELECT_APPOINTMENT,
      payload: data || null,
    });
  };
