import React from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setGreetings } from "../../../utils/setGreetings";
import "../styles/CallEmptyStyles.scss";
import NoAppointmentCard from "../../appointment/components/NoAppointment/NoAppointmentCard";
export const CallEmpty = () => {
  const { profile_data } = useAppSelector((state) => state.authReducer);

  return (
    <div className="call_empty_container">
      <h1 className="call_empty_container_header">
        Good {setGreetings()},{" "}
        <span>{profile_data?.family_name ?? "Oluwapelumi"} 😇</span>
      </h1>
      <div className="call_empty_container_bottom">
        <h6>Upcoming Sessions</h6>
        <p>
          Users get to view their upcoming sessions and get reminders on pending
          classes{" "}
        </p>
      </div>
      <div className="call_empty_container_image">
        <NoAppointmentCard
          header={"No Sessions available Presently"}
          message={"Apparently, there are no classes available yet"}
        />
      </div>
    </div>
  );
};
