import "./NewRequestModalStyles.scss";
import { Appointment } from "../../interfaces/Appointment";
import { Profile_Interface } from "../../interfaces/User";
import PopModal from "../ModelLayout/ModalLayout";
import Header from "../../components/Header/Header";
import useSound from "use-sound";
import alertFx from "../../assets/raw/call-to-attention.mp3";
import { useEffect } from "react";
import { useAppointmentActions } from "../../hooks/useReduxActions";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { formatDate } from "../../utils/convertDateToString";
import { convertUnixTimeStampToTime } from "../../features/appointment/utils/convertUnixTimeStampToTime";
import userimage from "../../assets/images/dummyAvatar.png";
import appointmentService from "../../services/AppointmentService/AppointmentService";
import useApi from "../../hooks/useApi";
import { convertDateToTime } from "../../utils/convertDateToTime";

const NewRequestModal = ({
  data,
  modalToggler,
}: {
  data: { appointment: Appointment; createdBy: Profile_Interface } | null;
  modalToggler: boolean;
}) => {
  const [play, { stop, duration }] = useSound(alertFx);

  const { createdBy, appointment } = data!;

  const { newPendingRequest, addAppointment } = useAppointmentActions();

  const appointmentStartTime = () => {
    return convertUnixTimeStampToTime(appointment.startTime);
  };

  const appointmentEndTime = () => {
    return convertUnixTimeStampToTime(appointment.endTime);
  };

  useEffect(() => {
    let interval: any = null;

    if (duration && modalToggler) {
      interval = setInterval(() => {
        play();
      }, duration + 500);
    }

    return () => clearInterval(interval);
  }, [duration, modalToggler]);

  const acceptAppointmentService = (appointmentId: string) =>
    appointmentService.acceptAppointments(appointmentId);

  const acceptAppointmentRequest = useApi<any, string>(
    acceptAppointmentService
  );

  const handleAcceptAppointment = async () => {
    acceptAppointmentRequest.reset();
    try {
      const response = await acceptAppointmentRequest.request(
        appointment.appointmentId
      );
      handleClose();

      addAppointment({
        ...appointment,
        status: "Accepted",
        createdBy: createdBy?.id,
      });
      console.log(response);
    } catch (error) {}
  };

  const handleClose = () => {
    newPendingRequest(null);
    stop();
  };

  return (
    <PopModal
      onClose={() => {}}
      modalToggler={modalToggler}
      otherClassName={"app_req_modal"}
    >
      <div className="app_req_modal_container">
        <Header message="Appointment Request!" variant="primary" size="lg" />
        <div>
          <div className="appointment_user_information">
            <div className="avatar_container">
              <img src={createdBy.profile_picture ?? userimage} />
            </div>
            <div className="user_information_container">
              <h2 className="names">
                {createdBy?.given_name ?? "Okunoye"}{" "}
                {createdBy?.family_name ?? "David"}
              </h2>
              <p className="email">{createdBy?.email}</p>
            </div>
          </div>
          <div className="appointment_info_modal_course_information">
            <h3>
              Course: <span>{appointment?.course ?? "Computer Science"}</span>
            </h3>
            <h3>
              Topic: <span>{appointment?.topic ?? "History of Computer"}</span>
            </h3>
          </div>
          <div className="appointment_info_modal_time_and_date">
            <div className="appointment_info_modal_time_and_date_item">
              <MdOutlineCalendarMonth className="appointment_info_drawer_icon" />
              <span>
                {formatDate(appointment.appointmentDate) ??
                  "20th February 2023"}
              </span>
            </div>
            <div className="appointment_info_modal_time_and_date_item">
              <AiOutlineClockCircle className="appointment_info_drawer_icon" />
              <span>
                {appointmentStartTime()} - {appointmentEndTime()}
              </span>
            </div>
          </div>
          {/* Course Description */}
          <div className="appointment_info_modal_body_course_description">
            <h3>Description</h3>
            <p>
              This class is bring requested by Mr. {createdBy?.family_name} on
              Course {appointment?.course}
              The Topic that will be taught is concerning {appointment?.topic}
              {appointment.comments}
            </p>
          </div>
          <div className="appointment_info_modal_button_container">
            <button className="accept-button" onClick={handleAcceptAppointment}>
              Accept
            </button>
            <button className="decline-button" onClick={handleClose}>
              Decline
            </button>
          </div>
        </div>
      </div>
    </PopModal>
  );
};

export default NewRequestModal;
