import React from "react";
import { Rate } from "antd";
import { teacherQualificationData } from "../../../../data/TeacherQualificationData";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import "./TeacherQualificationStyles.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { IoPeopleOutline } from "react-icons/io5";

const Rating: React.FC = () => <Rate disabled defaultValue={2} />;

const TeacherQualification: React.FC = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);
  const headerSize = () => {
    switch (currentScreen) {
      case "mobile":
        return "lg";
      case "desktop":
        return "lg";
      case "tablet":
        return "md";
    }
  };
  return (
    <div>
      <DashboardLeftHeader message="Top Teachers" size={headerSize()} />

      <div className="teacher_qualifications_container">
        <ul>
          {teacherQualificationData.map((item, index) => {
            return (
              <li className="teacher_qualifications_item_container" key={index}>
                <div className="inner-container">
                  <div className="teacher_qualifications_item_personal_info_container">
                    <div className="img_container">MD</div>
                    <div className="text_container">
                      <h1>Mrs. Afolabi</h1>
                      <Rate disabled defaultValue={item.rating} />
                    </div>
                  </div>

                  <div className="second">
                    <IoPeopleOutline />
                    <span>400 people</span>
                  </div>
                </div>
                <button className="view-button">View</button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TeacherQualification;
