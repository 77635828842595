import React, { useState } from "react";
import "./DashboardCarouselStyles.scss";

const DashboardCarousel = ({ steps }: { steps: JSX.Element[] }) => {
  const [selectedStep, setSelectedStep] = useState(0);
  return (
    <div className="dashboard_carousel_container">
      <div className="dashboard_carousel_item">
        {steps.find((step, index) => index == selectedStep)}
      </div>
      <div className="dashboard_carousel_bar">
        {steps.map((step, index) => (
          <span
            key={index}
            onClick={() => setSelectedStep(index)}
            className={`dashboard_carousel_bar_item ${
              index == selectedStep && "dashboard_carousel_bar_item_active"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardCarousel;
