import "./styles.scss";
import userimage from "../../../../assets/images/dummyAvatar.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { AppointmentInviteDetails } from "../../../../interfaces/Appointment";
import { convertUnixTimeStampToTime } from "../../../../features/appointment/utils/convertUnixTimeStampToTime";
import PopModal from "../../../ModelLayout/ModalLayout";
import Header from "../../../../components/Header/Header";
import { formatDate } from "../../../../utils/convertDateToString";
import appointmentService from "../../../../services/AppointmentService/AppointmentService";
import useApi from "../../../../hooks/useApi";
import { useAppointmentActions } from "../../../../hooks/useReduxActions";

const update = (data: { hash: string; action: "accept" | "decline" }) =>
  appointmentService.updateInviteDetails(data.hash, data.action);

const AppointmentModal = ({
  data,
  modalToggler,
  handleClose,
}: {
  data: AppointmentInviteDetails | null;
  modalToggler: boolean;
  handleClose: VoidFunction;
}) => {
  const updateApiRequest = useApi<
    { message: string; data: { appointmentId: string } },
    { hash: string; action: "accept" | "decline" }
  >(update);

  const appointmentStartTime = () => {
    if (!data) return "-";

    if (data.isOnDemand) {
      return data.dateUpdated.slice(11, 16);
    } else {
      return convertUnixTimeStampToTime(data.startTime);
    }
  };

  const appointmentEndTime = () => {
    if (!data) return "-";

    if (data.isOnDemand) {
      return `${
        parseInt(data.dateUpdated.slice(11)) + data.duration
      }:${data.dateUpdated.slice(14, 16)}`;
    } else {
      return convertUnixTimeStampToTime(data.endTime);
    }
  };

  const { editAppointment, removeAppointment } = useAppointmentActions();

  const handleSubmit = async (action: "accept" | "decline") => {
    if (!data || updateApiRequest.loading) return;

    const res = await updateApiRequest.request({ hash: data.hash, action });

    if (res) {
      if (action === "accept") {
        editAppointment({
          appointmentId: data.appointmentId,
          data: {
            ...data,
            createdBy: data.createdBy.id,
            appointmentInviteAccepted: true,
          },
        });
      } else {
        // Remove Appointment
        removeAppointment(data.appointmentId);
      }
    }

    handleClose();
  };

  return (
    <PopModal
      onClose={handleClose}
      modalToggler={modalToggler}
      otherClassName={"appointment_invite_modal"}
    >
      <div className="appointment_invite_modal_container">
        <Header message="Appointment Invitation" variant="primary" size="lg" />

        <div>
          <div className="appointment_user_information">
            <div className="avatar_container">
              <img src={data?.createdBy.profile_picture ?? userimage} />
            </div>

            <div className="user_information_container">
              <h2 className="names">
                {data?.createdBy.given_name ?? "-"}{" "}
                {data?.createdBy.family_name ?? "-"}
              </h2>

              <p className="email">{data?.createdBy.id ?? ""}</p>
            </div>
          </div>

          <div className="appointment_info_modal_course_information">
            <h3>
              Course: <span>{data?.course ?? "-"}</span>
            </h3>
            <h3>
              Topic: <span>{data?.topic ?? "-"}</span>
            </h3>
          </div>

          <div className="appointment_info_modal_time_and_date">
            <div className="appointment_info_modal_time_and_date_item">
              <MdOutlineCalendarMonth className="appointment_info_drawer_icon" />

              <span>{formatDate(data?.appointmentDate) ?? "-"}</span>
            </div>
            <div className="appointment_info_modal_time_and_date_item">
              <AiOutlineClockCircle className="appointment_info_drawer_icon" />

              <span>
                {appointmentStartTime()}-{appointmentEndTime()}
              </span>
            </div>
          </div>

          {/* Course Description */}
          <div className="appointment_info_modal_body_course_description">
            <h3>Comments</h3>
            <p>{data?.comments}</p>
          </div>

          <form className="appointment_info_modal_button_container">
            <button
              className="accept-button"
              disabled={updateApiRequest.loading}
              onClick={() => handleSubmit("accept")}
            >
              Accept
            </button>

            <button
              className="decline-button"
              disabled={updateApiRequest.loading}
              onClick={() => handleSubmit("decline")}
            >
              Decline
            </button>
          </form>
        </div>
      </div>
    </PopModal>
  );
};

export default AppointmentModal;
