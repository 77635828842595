import React from "react";
import { useLocation } from "react-router-dom";

import "./SettingsLayoutStyles.scss";

interface SettingsItemProps {
  children: string;
  paths: string[];
  onClick: VoidFunction;
}

const SettingsItem: React.FC<SettingsItemProps> = ({
  children,
  paths,
  onClick,
}) => {
  const { pathname } = useLocation();
  let sidebarItemCurrentPath;
  let allPaths: string[] = [];
  if (paths.length > 1) {
    paths.map((path) => {
      allPaths.push(path.split("/")[2]);
      return
    });
  } else {
    sidebarItemCurrentPath = paths[0]?.split("/")[2];
  }

  return (
    <div className="settings-nav-item-container">
      <div
        className={`settings-nav-item settings-nav-item__${
          pathname.split("/")[2] === sidebarItemCurrentPath ||
          allPaths.includes(pathname.split("/")[2])
            ? "active"
            : undefined
        }`}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};

export default SettingsItem;
