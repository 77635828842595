import { ReactComponent as MotivationImage } from "../../../../assets/motivationimage.svg";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import "./DashboardMotivationStyles.scss";

const DashboardMotivation = () => {
  return (
    <div className="dashboard_motivation_container">
      <div>
        <DashboardLeftHeader message="Daily Motivation" color="white" />
        <p className="dashboard_motivation_content">
          “Stay away from those people who try to disparage your ambitions.
          Small minds will always do that, but great minds will give you a
          feeling that you can become great too.”
        </p>
        <p className="dashboard_motivation_author">~Mark Twain~</p>
      </div>
      <div className="dashboard_motivation_image_container">
        <MotivationImage />
      </div>
    </div>
  );
};

export default DashboardMotivation;
