import React, { ColgroupHTMLAttributes } from "react";
import "./DashboardLeftHeaderStyles.scss";
const DashboardLeftHeader = ({
  message,
  size,
  color,
}: {
  message: string;
  size?: "sm" | "md" | "lg" | "xl";
  color?: string;
}) => {
  const fontSize = () => {
    switch (size) {
      case "sm":
        return "10px";
      case "md":
        return "16px";
      case "lg":
        return "24px";
      case "xl":
        return "40px";

      default:
        return "24px";
    }
  };
  return (
    <h1
      className="dashboard_left_header"
      style={{ fontSize: fontSize(), color: color ?? "#272727" }}
    >
      {message}
    </h1>
  );
};

export default DashboardLeftHeader;
