export function setGreetings() {
  let date = new Date();
  let hours = date.getHours();
  let result = null;
  if (hours < 12) {
    result = "Morning";
  } else if (hours < 18) {
    result = "Afternoon";
  } else {
    result = "Evening";
  }
  return result;
}
