import React from "react";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import "./LearningActivityStyles.scss";
import SplineChart from "../DashboardCharts/LearningActivityChart";
const LearningActivity = () => {
  return (
    <div className="dashboard_learning_activity_container">
      <div>
        <DashboardLeftHeader message="Learning Activity" />
        <p className="dashboard_learning_activity_text">
          Users get to track the hours spent on sessions monthly and also the
          overview of each learning phase.
        </p>
        <SplineChart />
      </div>
    </div>
  );
};

export default LearningActivity;
