import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { AllRouteConstants } from "../router/routes";
import { useRedirectActions } from "../hooks/useReduxActions";
import { AfterLoginAction_Action } from "../interfaces/Redirect";

export interface RequireAuthProps {
  children: ReactElement;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { user_data } = useAppSelector((state) => state.authReducer);

  const { setAfterLoginAction } = useRedirectActions();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user_data) {
      const params = new URLSearchParams(window.location.search);

      const action = params.get("action");

      if (action) {
        const token = params.get("hash");

        if (token) {
          setAfterLoginAction({
            action: action as AfterLoginAction_Action,
            token,
          });
        }
      }

      navigate(AllRouteConstants.auth.login);
    }
  }, [user_data]);

  return children;
};
