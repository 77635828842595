import { useState } from "react";
import AppointmentDropdown from "../../appointment/components/AppointmentDropdow/AppointmentDropdown";
import HistoryTable from "../components/HistoryTable/HistoryTable";
import "./HistoryStyles.scss";
export const HistoryHome = () => {
  const [date, setDate] = useState("");
  return (
    <div>
      <div className="history_container animate__animated animate__fadeIn">
        <div className="history_search_filter_container">
          <input
            id="History Search"
            placeholder="Search History"
            className="history_search"
          />
          {/* <AppointmentDropdown
            options={["Day", "Week", "Month"]}
            label="Date"
            value={date}
            handleChange={(e: any) => setDate(e?.target.value)}
          /> */}
        </div>

        <HistoryTable />
      </div>
    </div>
  );
};
