import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

export const setNavigationItem =
  (value: JSX.Element | string) => (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_MIDDLE_NAVIGATION_ITEM,
      payload: value,
    });
  };
