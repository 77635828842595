import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

export const setScreenSize =
  (value: "desktop" | "tablet" | "mobile") =>
  (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_CURRENT_SCREEN_STATE,
      payload: value,
    });
  };
