import React from "react";
import platformlogo from "../../../assets/platform-logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import "./DashboardNavigationStyles.scss";
import { useAppSelector } from "../../../hooks/useAppSelector";

const DashboardNavigation = () => {
  const { navigationItem } = useAppSelector(
    (state) => state.navigationItemReducer
  );

  return (
    <div className="dashboard_navigation_container">
      <div className="dashboard_navigation_logo">
        <img src={platformlogo} alt="logo" />
      </div>
      {navigationItem}
      <div className="dashboard_navigation_hamburger_container">
        <RxHamburgerMenu className="dashboard_navigation_hamburger" />
      </div>
    </div>
  );
};

export default DashboardNavigation;
