import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  select: {
    height: "45px",
    outline: "none",
    border: "transparent",
    "&:focus": {
      borderColor: "red", // change the border color when focused
      boxShadow: "0 0 0 0.2rem rgba(255,0,0,0.5)", // add a box shadow when focused
    },
  },
}));

const AppointmentDropdown = ({
  options,
  value,
  label,
  handleChange,
}: {
  options: { value: string; label: string }[];
  value: string;
  label: String;
  handleChange: any;
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          className={classes.select}
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AppointmentDropdown;
