import { useEffect, useState } from "react";
import { useReduxActions } from "./hooks/useReduxActions";
import { screenActions } from "./redux/allActions";
import Router from "./router/Router";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "./hooks/useAppSelector";

import "animate.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [screen, setScreen] = useState<number | null>(null);
  const { setScreenSize } = useReduxActions(screenActions);
  const { start } = useAppSelector((state) => state.callReducer);

  // Setting the Screen Size State whenever the screen is resized
  useEffect(() => {
    const handleResize = () => setScreen(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggling the isMobile State whenever the screensize changes
  useEffect(() => {
    if (screen! <= 768) {
      setScreenSize("mobile");
    } else if (screen! > 768 && screen! < 1200) {
      setScreenSize("tablet");
    } else if (screen! > 1200) {
      setScreenSize("desktop");
    }
  }, [screen]);

  const handleDisableBrowserMovement = (callStatus: boolean) => {
    if (callStatus) {
      //  history.pushState(null, "", location.href);
      //  window.addEventListener("popstate", () => {
      //    history.pushState(null, "", location.href);
      //  });
      // Add a listener for the popstate event
      window.addEventListener("popstate", function () {
        // Add a new state to the history to prevent navigation
        window.history.pushState(null, "", window.location.href);

        // Display a message to the user
        alert(
          "You cannot navigate using the browser's back or forward button while in the video call section."
        );
      });
      return;
    }
  };

  useEffect(() => {
    handleDisableBrowserMovement(start);
  }, [start]);

  return (
    <>
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
