type Data = {
  title: string;
  date_time: string;
  teacher: string;
  color: string;
}[];

export const pendingAppointmentData: Data = [
  {
    title: "Introduction to trigonometry",
    date_time: "Thursday, 10am - 12pm",
    teacher: "Dr Pelumi Akanni",
    color: "red",
  },
];
