import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../../components/Button/Button";
import Input, { TextArea } from "../../../../../components/Input/Input";
import {
  IAppointmentInviteRequest,
  ICreateAppointment,
} from "../../../../../interfaces/CreateAppointment";
import "./CreateAppointmentFormPersonalStyles.scss";
import appointmentService from "../../../../../services/AppointmentService/AppointmentService";
import { IAppointmentRequest } from "../../../../../interfaces/CreateAppointment";
import useApi from "../../../../../hooks/useApi";
import AuthError from "../../../../auth/components/AuthError/AuthError";
import { useAppointmentActions } from "../../../../../hooks/useReduxActions";
import {
  Appointment,
  AppointmentCourse,
} from "../../../../../interfaces/Appointment";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import { convertDataToDropdownData } from "../../../../../utils/convertDataToDropdownData";
import { MdOutlineGroupAdd } from "react-icons/md";
import { BsFillSendFill, BsX } from "react-icons/bs";
import { Profile_Interface, User } from "../../../../../interfaces/User";

interface IAppointmentCourseForm extends ICreateAppointment {
  handleCloseDrawer: () => void;
  setCreatedAppointment: (val: string) => void;
}

// API SERVICE
const createAppointmentService = (data: IAppointmentRequest) =>
  appointmentService.createNewAppointment(data);

const getAppointmentCoursesService = () =>
  appointmentService.getAppointmentCourses();

const getAppointmentUserDetails = (data: IAppointmentInviteRequest) =>
  appointmentService.getUserDetails(data);

const CreateAppointmentDrawerCourseForm = (props: IAppointmentCourseForm) => {
  const {
    appointmentForm,
    setCurrentFormStep,
    handleCloseDrawer,
    setCreatedAppointment,
  } = props;
  const { form, formErrors, onChange } = appointmentForm;
  const [appointmentCourses, setAppoinmentCourses] = useState<
    { value: string; label: string }[]
  >([]);
  const [inviteGuest, setInviteGuest] = useState({
    status: false,
    email: "",
  });

  const getAppointmentCoursesRequest = useApi<
    { data: AppointmentCourse[] },
    string
  >(getAppointmentCoursesService);

  const getAllAppointmentCourses = async () => {
    try {
      const response = await getAppointmentCoursesRequest.request();
      const formattedData = convertDataToDropdownData(
        "courseName",
        "courseName",
        response?.data as AppointmentCourse[]
      );
      setAppoinmentCourses(formattedData);
      console.log(response);
    } catch (error) {}
  };

  const createAppointmentRequest = useApi<
    { data: Appointment },
    IAppointmentRequest
  >(createAppointmentService);

  // Redux Actions and States
  const { addAppointment } = useAppointmentActions();

  const goBack = () => setCurrentFormStep(2);

  // Sets the Focus to the name field
  const courseNameRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    getAllAppointmentCourses();
    courseNameRef.current?.focus();
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    createAppointmentRequest.reset();
    try {
      const response = await createAppointmentRequest.request(form);

      if (response) {
        addAppointment(response.data);
        setCreatedAppointment(response.data.appointmentId);
        if (form.isOnDemand) {
          setCurrentFormStep(4);
        } else {
          handleCloseDrawer();
        }
      }

      console.log(response);
    } catch (error) {}
  };

  const getAppointmentUserDetailsRequest = useApi<
    { data: Profile_Interface },
    IAppointmentInviteRequest
  >(getAppointmentUserDetails);

  const onGetUserClick = async () => {
    if (!inviteGuest.email || getAppointmentUserDetailsRequest.loading) return;

    getAppointmentUserDetailsRequest.reset();

    const res = await getAppointmentUserDetailsRequest.request({
      ...form,
      userId: inviteGuest.email.trim(),
    });

    if (res?.data) {
      setInviteGuest((prev) => ({ ...prev, email: "", status: false }));

      let newUsers: Profile_Interface[] = [];
      if (form.users) newUsers = [...form.users];
      newUsers = [res.data, ...newUsers];

      onChange("users", newUsers as any);
    }
  };

  const removeUserFromInvite = (id: string) => () => {
    let newUsers: Profile_Interface[] = [];
    if (form.users) newUsers = [...form.users];
    newUsers = newUsers.filter((item) => item.id !== id);

    onChange("users", newUsers as any);
  };

  return (
    <div>
      <h2 className="create_appointment_personal_form_header">
        Appointment Information
      </h2>
      <form className="" onSubmit={handleSubmit}>
        <div className="appointment_personal_form_input_container">
          <Dropdown
            id="Course"
            label="Course"
            error={formErrors.course}
            loading={getAppointmentCoursesRequest.loading}
            dropdownClassName="create_appointment_personal_form_input"
            labelClassName="create_appointment_personal_form_label"
            options={appointmentCourses}
            dropdownProps={{
              name: "course",
              placeholder: "Enter your Course Name",
              value: { value: form.course, label: form.course },
              required: true,
              onChange: (val: { value: string; label: string }) => {
                return onChange("course", val.value);
              },
            }}
          />
          <Input
            id="topic"
            label="Topic"
            error={formErrors.topic}
            inputClassName="create_appointment_personal_form_input"
            labelClassName="create_appointment_personal_form_label"
            requiredAstersik
            inputProps={{
              name: "topic",
              placeholder: "Enter your Topic",
              value: form.topic,
              onChange: (e) => onChange("topic", e.target.value),
              required: true,
            }}
          />
          <TextArea
            id="comments"
            label="Comments"
            error={formErrors.comments}
            inputClassName="create_appointment_personal_form_input"
            labelClassName="create_appointment_personal_form_label"
            requiredAstersik
            rows={7}
            textareaProps={{
              placeholder: "Leave a Message",
              value: form.comments,
              onChange: (e) => onChange("comments", e.target.value),
              name: "comments",
              required: true,
            }}
          />
          {form.type === "Group" && (
            <div>
              <button
                className="create_appointment_personal_form_invite_guest_button"
                type="button"
                disabled={getAppointmentUserDetailsRequest.loading}
                onClick={() => {
                  setInviteGuest({
                    ...inviteGuest,
                    status: !inviteGuest.status,
                  });
                }}
              >
                <MdOutlineGroupAdd className="icon" />
                Invite Guest
              </button>
              <div className="create_appointment_personal_form_invited_guest">
                {form.users?.map((user) => (
                  <div
                    key={user.id}
                    className="create_appointment_personal_form_invited_guest_container"
                  >
                    <div className="create_appointment_personal_form_invited_guest_container-left">
                      <div className="img_container"></div>
                      <div className="info_container">
                        <h4>{`${user.given_name} ${user.family_name}`}</h4>
                        <p>{user.email}</p>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="create_appointment_personal_form_invited_guest_container-right"
                      onClick={removeUserFromInvite(user.id)}
                    >
                      <BsX className="icon" />
                    </button>
                  </div>
                ))}
                {/* <div className="create_appointment_personal_form_invited_guest_container">
                  <div className="create_appointment_personal_form_invited_guest_container-left">
                    <div className="img_container"></div>
                    <div className="info_container">
                      <h4>Jayde</h4>
                      <p>Organizer</p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="create_appointment_personal_form_invited_guest_container-right"
                  >
                    <BsX className="icon" />
                  </button>
                </div> */}
              </div>
              {inviteGuest.status && (
                <div
                  id="inviteGuest"
                  className="invite_guest_form animate__animated animate__fadeIn"
                >
                  <Input
                    id="email"
                    label="Guest User ID"
                    error={null}
                    inputClassName="create_appointment_personal_form_input"
                    labelClassName="create_appointment_personal_form_label"
                    requiredAstersik
                    rightIcon={
                      <button
                        form="inviteGuest"
                        type="submit"
                        disabled={getAppointmentUserDetailsRequest.loading}
                        onClick={onGetUserClick}
                      >
                        <BsFillSendFill className="icon" />
                      </button>
                    }
                    inputProps={{
                      name: "email",
                      type: "text",
                      placeholder: "Enter the Guest User ID",
                      value: inviteGuest.email,
                      onChange: (e) =>
                        setInviteGuest({
                          ...inviteGuest,
                          email: e.target.value,
                        }),
                      required: true,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="create_appointment_personal_form_buttons">
          <Button
            label="Go Back"
            variant="secondary"
            buttonClassName="create_appointment_personal_form_buttons_back_button"
            size="md"
            onClick={goBack}
            type="button"
          />
          <Button
            label="Book Appointment"
            variant="primary"
            size="md"
            loading={createAppointmentRequest.loading}
          />
        </div>

        <AuthError
          error={
            createAppointmentRequest.error ||
            getAppointmentUserDetailsRequest.error
          }
        />
      </form>
    </div>
  );
};

export default CreateAppointmentDrawerCourseForm;
