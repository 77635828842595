import { AxiosResponse } from "axios";
import { useState } from "react";
import { useAuthActions } from "./useReduxActions";
import { toast } from "react-toastify";
import { logger } from "../utils/logger";

export default <T, K>(
  apiFunc: (arg: K) => Promise<AxiosResponse>,
  defaultError = "Something Went Wrong!"
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string>("");
  const [responseError, setResponseError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { signOut } = useAuthActions();

  const reset = () => {
    setLoading(false);
    setError("");
    setData(null);
  };

  const request = async (
    arg?: K,
    showToast: boolean = true
  ): Promise<T | null> => {
    if (loading) return null;

    reset();

    setLoading(true);

    try {
      const result = await apiFunc(arg!);
      setData(result.data);
      return result.data;
    } catch (err: any) {
      logger("[ERROR]", err);

      if (
        (err.response?.status >= 401 && err.response?.status <= 403) ||
        (err.name === "AxiosError" && err.code === "ERR_NETWORK")
      ) {
        signOut();
      }
      const error = err.response?.data || {};

      if (!error || !error.message) {
        error.message = defaultError;
      }

      if (showToast) toast(error.message, { type: "error", theme: "light" });

      setError(error.message);

      setResponseError(err.response);

      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    responseError,
    loading,
    request,
    reset,
  };
};
