import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { AppointmentActionTypes } from "./action-types";
import { Appointment, TUpdateAppoinment } from "../../interfaces/Appointment";
import { Profile_Interface } from "../../interfaces/User";

export const initializeAppointments =
  (data: Appointment[]) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.INITIALIZE, payload: data });
  };

export const addAppointment =
  (data: Appointment) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.ADD, payload: data });
  };

export const removeAppointment =
  (id: string) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({
      type: ActionConstants.REMOVE,
      payload: id,
    });
  };

export const editAppointment =
  (data: TUpdateAppoinment) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.EDIT, payload: data });
  };

export const newPendingRequest =
  (data: { appointment: Appointment; createdBy: Profile_Interface } | null) =>
  (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.APPOINTMENT_REQUEST, payload: data });
  };

export const acceptAppointment =
  (data: { appointmentId: string; acceptedBy: Profile_Interface }) =>
  (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.ACCEPT_APPOINTMENT, payload: data });
  };

export const toggleActiveAppointment =
  (data: { appointmentId: string; active: boolean }) =>
  (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({ type: ActionConstants.START_SESSION, payload: data });
  };

export const setLoadingCurrentAppointments =
  (data: boolean) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_LOADING_CUURENT,
      payload: data,
    });
  };

export const setCurrentAppointments =
  (data: Appointment[]) => (dispatch: Dispatch<AppointmentActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_CURRENT,
      payload: data,
    });
  };
