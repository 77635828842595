import CheckboxRound from "../../../../components/CheckboxRound";
import "./styles.scss";

interface IPaymentMethodType {
  image: string;
  accountNumberLastFourDigits: string;
  expDate: string;
  selected: boolean;
  onCardSelect: VoidFunction;
}

const PaymentMethodType = (props: IPaymentMethodType) => {
  const {
    image,
    accountNumberLastFourDigits,
    expDate,
    selected,
    onCardSelect,
  } = props;

  return (
    <div className="mb-checkout-card">
      <CheckboxRound checked={selected} onChange={onCardSelect} />

      <div className="img-container">
        <img src={image} alt="card" />
      </div>

      <p>xxxx xxxx xxxx {accountNumberLastFourDigits}</p>

      <p>Expires on {expDate}</p>
    </div>
  );
};

export default PaymentMethodType;
