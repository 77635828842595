import { useForm } from "../../../hooks/useForm";
import { useEffect, useState } from "react";
import {
  useAuthActions,
  useReduxActions,
} from "../../../hooks/useReduxActions";
import { navigationItemActions } from "../../../redux/allActions";
import { ProfileFormInput } from "../../../interfaces/ProfileFormInput";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import Input from "../../../components/Input/Input";
import PhoneInputField from "../../../components/Input/PhoneInput";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button from "../../../components/Button/Button";
import userimage from "../../../assets/images/dummyAvatar.png";
import { AllRouteConstants } from "../../../router/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import UploadAvatarModal from "../components/Modals/UploadAvatarModal";
import profileService from "../../../services/profileService/ProfileService";
import { UpdateProfileData } from "../../../services/profileService/types";
import useApi from "../../../hooks/useApi";
import { Profile_Interface, User } from "../../../interfaces/User";
import "./SettingsStyles.scss";
import CopyWithToolTip from "../../../components/CopyWithToolTip/CopyWithToolTip";

export const Profile = ({ mode }: { mode: "edit" | "view" }) => {
  const { setNavigationItem } = useReduxActions(navigationItemActions);
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);
  const { pathname } = useLocation();
  const updateData = (data: UpdateProfileData) =>
    profileService.updateUserProfileDetails(data);
  const updateDataRequest = useApi<User, UpdateProfileData>(updateData);
  const navigate = useNavigate();
  const [profileMode, setProfileMode] = useState<"edit" | "view">(mode);
  const [uploadAvatar, setUploadAvatar] = useState<boolean>(false);
  const { updateProfile } = useAuthActions();
  const { profile_data } = useAppSelector((state) => state.authReducer);
  const [changedValues, setChangedValues] = useState<string[]>([]);
  useEffect(() => {
    setNavigationItem("profile");
  }, []);
  useEffect(() => {
    const allPaths = pathname.split("/");
    if (allPaths.includes("edit")) {
      setProfileMode("edit");
    } else {
      setProfileMode("view");
    }
  }, [pathname]);

  const profileForm = useForm<ProfileFormInput>(
    {
      id: profile_data?.id ?? "",
      email: profile_data?.email ?? "",
      parent_email: profile_data?.parent_email ?? "",
      email_verified: profile_data?.email_verified ?? "",
      given_name: profile_data?.given_name ?? "",
      family_name: profile_data?.family_name ?? "",
      address: profile_data?.address ?? "",
      parent_phone_number: profile_data?.parent_phone_number ?? "",
      phone_number_verified: profile_data?.phone_number_verified ?? "",
      birthdate: profile_data?.birthdate ?? "",
      profile_picture: profile_data?.profile_picture ?? "",
      phone_number: profile_data?.phone_number ?? "",
    },
    {
      given_name: emptyValidator,
      family_name: emptyValidator,
    }
  );
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const itemKey = e.target.name as keyof ProfileFormInput;
    const itemValue = e.target.value;
    profileForm.onChange(itemKey, itemValue);
    if (!changedValues.includes(itemKey)) {
      setChangedValues([...changedValues, itemKey]);
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const requestPayload: any = {};
    changedValues.map((value) => {
      requestPayload[value] = profileForm.form[value as keyof ProfileFormInput];
    });
    try {
      const user = (await updateDataRequest.request(requestPayload)) as any;
      if (user?.message) {
        changedValues.map((value) => {
          updateProfile({
            key: value as keyof Profile_Interface,
            data: profileForm.form[value as keyof ProfileFormInput],
          });
        });
        setProfileMode("view");
      }
    } catch (error) {}
  };

  return (
    <div className="settings_profile animate__animated animate__fadeIn">
      <UploadAvatarModal
        modalToggler={uploadAvatar}
        handlePictureUpload={(picture: string) =>
          profileForm.onChange("profile_picture", picture)
        }
        onClose={() => {
          setUploadAvatar(false);
        }}
      />
      <div className="settings_profile_intro">
        <p className="settings_home_intro_text">Hey OluwaPelumi 👋🏿</p>
        <h2 className="settings_home_intro_header">View & Edit Your Details</h2>
      </div>
      {/* Avatar */}

      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="profile_form_avatar_container">
          <div className="profile_form_avatar">
            <img
              src={
                profileForm.form.profile_picture === ""
                  ? userimage
                  : profileForm.form.profile_picture
              }
            />
            {profileMode === "edit" && (
              <>
                <BsPencil
                  className="profile_form_avatar_icon"
                  style={{ color: "#5700A2" }}
                  onClick={() => {
                    setUploadAvatar(true);
                  }}
                />
              </>
            )}
          </div>
          <div className="profile_form_avatar_information">
            <h2>
              {profileForm.form.given_name} {profileForm.form.family_name}
            </h2>
            <div className="profile_form_avatar_information_id">
              <CopyWithToolTip
                copyItem={profileForm.form?.id ?? "Id 1"}
                message="Id Copied To Clipboard"
              />{" "}
              <p>UserID: {profileForm.form?.id ?? "Id 1"}</p>
            </div>
          </div>
        </div>
        <h3 className="settings_profile_header_text">Personal Information</h3>
        <div className="settings_profile_input_fields">
          <Input
            id="firstName"
            label="First Name"
            error={profileForm.formErrors.given_name}
            inputClassName="profile_form_input_field"
            inputProps={{
              name: "given_name",
              placeholder: "Enter your first name",
              value: profileForm.form.given_name,
              onChange: handleChange,
              readOnly: profileMode === "view" ? true : false,
            }}
          />
          <Input
            id="lastName"
            label="Last Name"
            error={profileForm.formErrors.family_name}
            inputClassName="profile_form_input_field"
            inputProps={{
              name: "family_name",
              placeholder: "Enter your last name",
              value: profileForm.form.family_name,
              onChange: handleChange,
              readOnly: profileMode === "view" ? true : false,
            }}
          />
          {profileMode === "view" ? (
            <Input
              id="birthdate"
              label="Birth Date"
              error={profileForm.formErrors.birthdate}
              inputClassName="profile_form_input_field"
              inputProps={{
                placeholder: "No Birth Date",
                value: profileForm.form.birthdate,
                onChange: handleChange,
                name: "birthdate",
                readOnly: profileMode === "view" ? true : false,
              }}
            />
          ) : (
            <Input
              id="birthdate"
              label="Birth Date"
              error={profileForm.formErrors.birthdate}
              inputClassName="profile_form_input_field"
              inputProps={{
                name: "birthdate",
                placeholder:
                  profileMode === "edit"
                    ? "Enter your Birth Date"
                    : "No birth Date",
                value: profileForm.form.birthdate,
                onChange: handleChange,
                readOnly: profileMode === "edit" ? false : true,
                type: "date",
              }}
            />
          )}

          <Input
            id="address"
            label="Address"
            error={profileForm.formErrors.address}
            inputClassName="profile_form_input_field"
            inputProps={{
              name: "address",
              placeholder:
                profileMode === "edit" ? "Enter your Address" : "No Address",

              value: profileForm.form.address,
              onChange: handleChange,
              readOnly: profileMode === "view" ? true : false,
            }}
          />
        </div>

        <p className="settings_profile_candidate_information">
          For candidates under 18, you will need to fill in your guardians
          details below, <span>View</span>
        </p>
        <div className="settings_profile_input_fields">
          <Input
            id="email"
            label="Parent Email Adddress"
            error={profileForm.formErrors.parent_email}
            inputClassName="profile_form_input_field"
            inputProps={{
              placeholder:
                profileMode === "edit"
                  ? "Enter your Email Address"
                  : "No Email Address",

              value: profileForm.form.parent_email,
              onChange: handleChange,
              name: "parent_email",
              readOnly: profileMode === "view" ? true : false,
            }}
          />
          {profileMode === "view" ? (
            <Input
              id="phone_number"
              label="Parent Phone Number"
              error={profileForm.formErrors.parent_phone_number}
              inputClassName="profile_form_input_field"
              inputProps={{
                placeholder: "No Phone Number",
                value: profileForm.form.parent_phone_number,
                onChange: handleChange,
                name: "parent_phone_number",
                readOnly: profileMode === "view" ? true : false,
              }}
            />
          ) : (
            <PhoneInputField
              id="Parent phoneNumber"
              label="Parent Phone Number"
              error={profileForm.formErrors.parent_phone_number}
              value={profileForm.form.parent_phone_number}
              inputClassName="profile_form_input_field"
              onChange={(value: string) => {
                profileForm.onChange("parent_phone_number", `+${value}`);
                setChangedValues([...changedValues, "parent_phone_number"]);
              }}
              inputProps={{
                name: "parent_phone_number",
                required: true,
                autoFocus: true,
                readOnly: profileMode == "edit" ? false : true,
              }}
            />
          )}
        </div>
        {profileMode === "edit" ? (
          <div className="settings_profile_submit_cancel_buttons">
            <Button
              variant="primary"
              label={"Save Changes"}
              loading={updateDataRequest.loading}
            />
            <Button
              buttonClassName="settings_profile_cancel"
              variant="secondary"
              label={"Cancel"}
              type="button"
              onClick={() => {
                navigate(AllRouteConstants.main.settings.profile.index);
                setProfileMode("view");
              }}
            />
          </div>
        ) : (
          <Button
            variant="primary"
            label={"Edit Profile"}
            width={currentScreen === "mobile" ? "100%" : "20%"}
            type="button"
            onClick={() => {
              navigate(AllRouteConstants.main.settings.profile.edit);
              setProfileMode("edit");
            }}
          />
        )}
      </form>
    </div>
  );
};
