import ProfilePic from "../assets/images/profilepic.svg";
type Data = {
  image: string;
  name: string;
  degree: string;
  rating: number;
}[];

export const teacherQualificationData: Data = [
  {
    image: ProfilePic,
    name: "Mrs. Afolabi",
    degree: "PhD Computer science ",
    rating: 4,
  },
  {
    image: ProfilePic,
    name: "Mrs. Afolabi",
    degree: "PhD Computer science ",
    rating: 3,
  },
];
