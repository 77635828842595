import axios from "axios";
import { API_URL } from "../../config/env";
import { LoginInput } from "../../interfaces/LoginInput";
import { ResetPasswordInput } from "../../interfaces/ResetPasswordInput";
import { SignupInput } from "../../interfaces/SignupInput";
import { VerifySignInInput } from "../../interfaces/VerifySignInInput";

class AuthService {
  login = (data: LoginInput) => {
    return axios.post(`${API_URL}/auth/signin`, data, {
      withCredentials: true,
    });
  };

  logout = () => {
    return axios.get(`${API_URL}/auth/signout`, { withCredentials: true });
  };

  verifySignIn = (data: VerifySignInInput) => {
    return axios.post(`${API_URL}/auth/signin/verify`, data, {
      withCredentials: true,
    });
  };

  signup = (data: SignupInput) => {
    return axios.post(`${API_URL}/auth/signup`, data);
  };

  sendverificationemail = (data: { email: string }) => {
    console.log(data);
    return axios.post(`${API_URL}/auth/send-verification-mail`, data);
  };

  comfirmemail = (data: { email: string; code: string }) => {
    return axios.post(`${API_URL}/auth/confirm-signup`, data);
  };

  requestpasswordreset = (email: string) => {
    return axios.get(`${API_URL}/auth/forgot-password?email=${email}`);
  };
  confirmpasswordreset = (data: ResetPasswordInput) => {
    console.log(data);
    return axios.post(`${API_URL}/auth/forgot-password`, data);
  };
  getTOTP = () => {
    return axios.get(`${API_URL}/settings/setup-totp`, {
      withCredentials: true,
    });
  };
  postTOTP = (data: { code: string }) => {
    return axios.post(`${API_URL}/settings/setup-totp`, data, {
      withCredentials: true,
    });
  };
  disableTOTP = () => {
    return axios.post(
      `${API_URL}/settings/disable-totp`,
      {},
      {
        withCredentials: true,
      }
    );
  };
}

const authService = new AuthService();
export default authService;
