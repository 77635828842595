import React from "react";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import "./PendingAppointmentStyles.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { pendingAppointmentData } from "../../../../data/PendingAppointmentData";

const PendingAppointments = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);
  const headerSize = () => {
    switch (currentScreen) {
      case "mobile":
        return "lg";
      case "desktop":
        return "lg";
      case "tablet":
        return "md";
    }
  };
  return (
    <div>
      <div className="pending_appointment_header">
        <DashboardLeftHeader
          message="Pending Appointment"
          size={headerSize()}
        />

        <span>View All</span>
      </div>

      <div className="pending_appoointments_container">
        {pendingAppointmentData.map((item, index) => {
          return <div></div>;
        })}
      </div>
    </div>
  );
};

export default PendingAppointments;
