import axios from "axios";
import { API_URL } from "../../config/env";
import { ChangePasswordInput } from "../../interfaces/settingsInterfaces/SettingsAcountInputs";

class ProfileService {
  getUserProfileDetails = () => {
    return axios.get(`${API_URL}/settings/profile`, {
      withCredentials: true,
    });
  };
  updateUserProfileDetails = (data: any) => {
    return axios.put(`${API_URL}/settings/profile`, data, {
      withCredentials: true,
    });
  };
  getVerificationCode = () => {
    return axios.get(`${API_URL}/settings/request-phone-verification`, {
      withCredentials: true,
    });
  };
  confirmVerificationCode = () => {
    return axios.get(`${API_URL}/settings/request-phone-verification`, {
      withCredentials: true,
    });
  };
  changePassword = (data: ChangePasswordInput) => {
    return axios.post(`${API_URL}/settings/change-password`, data, {
      withCredentials: true,
    });
  };
}

const profileService = new ProfileService();
export default profileService;
