import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { CheckoutPage } from "../pages";

const CheckoutRoutes = () => {
  return (
    <>
      <Route
        path={AllRouteConstants.main.checkout.index}
        element={<CheckoutPage />}
      />
    </>
  );
};

export default CheckoutRoutes;
