// import userimage from "../../assets/userimage.svg";
import userimage from "../../assets/images/dummyAvatar.png";

import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../router/routes";
import "./NotificationsAndAvatarStyles.scss";
import { MdNotifications } from "react-icons/md";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAuthActions } from "../../hooks/useReduxActions";

const NotificationsAndAvatar = () => {
  const { signOut } = useAuthActions();
  const { profile_data } = useAppSelector((state) => state.authReducer);

  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate(AllRouteConstants.auth.index);
  };

  return (
    <div className="notifications_and_avatar_container">
      <div className="dashboard_notifications">
        <MdNotifications className="dashboard_notifications_icon" />
        <div className="dashboard_notifications_counter" />
      </div>

      <div className="dashboard_avatar">
        <img
          className=""
          src={profile_data?.profile_picture ?? userimage}
          alt="user"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default NotificationsAndAvatar;
