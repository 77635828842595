import "./CallParticipantsStyles.scss";
import {
  BsMic,
  BsMicMute,
  BsCameraVideo,
  BsCameraVideoOff,
} from "react-icons/bs";
import { SlOptionsVertical } from "react-icons/sl";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CallUser } from "../../../../interfaces/Call";
import React from "react";
import { ICameraVideoTrack } from "agora-rtc-react";
import { IUserCallActions } from "../../interfaces/IUserCallActions";

interface ICallParticipants {
  action: (
    action: IUserCallActions,
    payload?: any,
    user?: CallUser
  ) => Promise<void>;
  localVideoTrack: React.MutableRefObject<ICameraVideoTrack | null>;
  adminUser: CallUser;
}

const CallParticipants = (props: ICallParticipants) => {
  const users = useAppSelector((state) => state.callReducer.users);
  const { profile_data } = useAppSelector((state) => state.authReducer);
  const { action, localVideoTrack, adminUser } = props;

  const getUserName = (user: CallUser) => {
    if (user.uid === profile_data.email) {
      return "You";
    }

    return user.name || "";
  };

  const handleToggleClientMute = (type: IUserCallActions, user: CallUser) => {
    if (user.client && props.adminUser.uid === profile_data?.email) {
      action(type, undefined, user);
    } else if (user.client && type === "video") {
      action(type);
    }
  };

  return (
    <div className="call_participants">
      <p>In Call</p>
      <div className="call_participants_list">
        {users.map((user: CallUser, index: number) => {
          if (user.uid === adminUser?.uid) {
            return null;
          }

          const track =
            user.uid === profile_data.email
              ? localVideoTrack.current
              : user.videoTrack;

          if (track) {
            track.play(`participant-video-${user.uid}`);
          }

          return (
            <div key={index}>
              <div
                id={`participant-video-${user.uid}`}
                className="participant-video"
              ></div>

              <div className="call_participant">
                <div className="call_participant_left">
                  <h4 className="call_participant_left_text">
                    {getUserName(user)}
                  </h4>
                </div>

                <div className="call_participant_right">
                  {adminUser && (
                    <button
                      className="mic-button button"
                      onClick={() => handleToggleClientMute("audio", user)}
                    >
                      {user.audio ? <BsMic /> : <BsMicMute />}
                    </button>
                  )}

                  {adminUser && (
                    <button
                      className="mic-button button"
                      onClick={() => handleToggleClientMute("video", user)}
                    >
                      {user.video ? <BsCameraVideo /> : <BsCameraVideoOff />}
                    </button>
                  )}

                  {/* <button className="options-button button">
                    <SlOptionsVertical />
                  </button> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CallParticipants;
