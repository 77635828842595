import React from "react";
import "./Loader.scss";
import Logo from "../../assets/platform-logo.svg";

const Loader = () => {
  return (
    <div className="loader_container">
      <div>
        <div className="loader_logo_container">
          <img src={Logo} alt='logo' />
          <div className="circle-ring"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
