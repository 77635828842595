import React from "react";
import AppointmentBody from "../components/AppointmentBody/AppointmentBody";
import "./AppointmentStyles.scss";

export const AppointmentHome: React.FC = () => {
  return (
    <div className="appointment_container animate__animated animate__fadeIn">
      <AppointmentBody />
    </div>
  );
};
