import { useState, useEffect } from "react";
import platformlogo from "../../../assets/platform-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import useApi from "../../../hooks/useApi";
import Button from "../../../components/Button/Button";
import authService from "../../../services/authenticationService/AuthService";
import OtpInput from "../../../components/OtpInput/OtpInput";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import { AllRouteConstants } from "../../../router/routes";
import AuthError from "../components/AuthError/AuthError";
import VerifiedNoticeModal from "../components/AuthModals/VerifiedNoticeModal";

export const OtpVerification = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState<number>(0);
  const [mailVerified, setMailVerified] = useState<boolean>(false);

  const onChange = (value: string) => setOtp(value.trim());
  const location = useLocation();

  let userEmail: string = location?.state?.userEmail;

  const verificationemailApiRequest = useApi<string, { email: string }>(
    authService.sendverificationemail
  );

  const confirmemailApiRequest = useApi<
    string,
    { email: string; code: string }
  >(authService.comfirmemail);

  const sendMail = async () => {
    if (disable) return;

    const result = await verificationemailApiRequest.request({
      email: userEmail,
    });

    if (result) {
      setSeconds(59);
      setMinutes(1);
      setDisable(true);
    }
  };

  useEffect(() => {
    if (minutes < 0) {
      setDisable(false);
    } else {
      if (!disable) setDisable(true);
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds > 0) {
      if (seconds < 10) {
        const interval = setInterval(
          () => setSeconds(parseInt(String(seconds - 1).padStart(2, "0"))),
          1000
        );
        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => setSeconds(seconds - 1), 1000);
        return () => clearInterval(interval);
      }
    } else {
      setMinutes(minutes - 1);
      setSeconds(59);
    }
  }, [seconds]);

  useEffect(() => {
    !userEmail && navigate(AllRouteConstants.auth.login);
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    confirmemailApiRequest.reset();

    const result = await confirmemailApiRequest.request(
      {
        email: userEmail,
        code: otp,
      },
      false
    );
    if (result) {
      setMailVerified(true);
    }
  };

  const handleCloseModal = () => {
    setMailVerified(false);
    setTimeout(() => {
      navigate(AllRouteConstants.auth.login);
    }, 1000);
  };

  return (
    <section className="auth_container animate__animated animate__fadeIn">
      <VerifiedNoticeModal
        modalToggler={mailVerified}
        onClose={handleCloseModal}
        email={userEmail}
      />

      <img alt="mobile" src={platformlogo} />

      <AuthHeader message="Verification Code" />

      <p className="forgot_password_text">
        A verification code was sent to you email {userEmail}, Kindly input the
        code below
      </p>

      <form onSubmit={handleSubmit}>
        <OtpInput value={otp} valueLength={6} onChange={onChange} />

        <AnimateHeight duration={300} height={minutes < 0 ? 0 : "auto"}>
          <p className="auth_text_2">
            Resend code in{" "}
            <span style={{ color: "#F6374E" }}>{`${minutes}:${seconds}`}</span>
          </p>
        </AnimateHeight>

        <AuthError error={confirmemailApiRequest.error} />

        <Button
          label="Verify"
          disable={otp.length !== 6}
          loading={confirmemailApiRequest.loading}
          variant="primary"
        />

        <div className="mt-[16px]" />

        <Button
          label={"Resend"}
          loading={verificationemailApiRequest.loading}
          disable={disable}
          variant="secondary"
          type="button"
          onClick={sendMail}
        />
      </form>
    </section>
  );
};
