import React from "react";
import { IconType } from "react-icons/lib";
import { Tooltip } from "react-tooltip";

interface Props {
  description: string;
  Icon: IconType;
  onClick: VoidFunction;
  id: string;
}

const ActionButton: React.FC<Props> = (props) => {
  const { description, Icon, onClick, id } = props;

  return (
    <>
      <Tooltip id={id} />

      <a
        className="action"
        onClick={onClick}
        data-tooltip-id={id}
        data-tooltip-content={description}
        data-tooltip-place="top"
      >
        <Icon />
      </a>
    </>
  );
};

export default ActionButton;
