import "./AuthLayout.scss";
import { useEffect } from "react";
import { AllRouteConstants } from "../../router/routes";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import WelcomeSectionLeft from "../../features/auth/components/WelcomeSectionLeft/WelcomeSectionLeft";
import { useAppSelector } from "../../hooks/useAppSelector";
import SignInMaleimage from "../../assets/images/signin-male-image.svg";
import SignUpMaleimage from "../../assets/images/signup-male-image.svg";
import SignInFemaleimage from "../../assets/images/signin-female-image.svg";
import SignUpFemaleimage from "../../assets/images/signup-female-image.svg";

interface AuthLayoutProps {}

const AuthLayout = (props: AuthLayoutProps) => {
  const { pathname } = useLocation();

  const getMaleImage = () => {
    if (pathname === AllRouteConstants.auth.signup) {
      return SignUpMaleimage;
    } else {
      return SignInMaleimage;
    }
  };

  const getFemaleImage = () => {
    if (pathname === AllRouteConstants.auth.signup) {
      return SignUpFemaleimage;
    } else {
      return SignInFemaleimage;
    }
  };

  const navigate = useNavigate();

  const { user_data } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (user_data) {
      navigate(AllRouteConstants.main.dashboard);
    }
  }, [user_data]);

  return (
    <div className="auth_layout">
      <WelcomeSectionLeft
        maleImage={getMaleImage()}
        femaleImage={getFemaleImage()}
      />
      <div className="outlet_container">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
