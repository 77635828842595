import { Route } from "react-router-dom";
import { RequireAuth } from "../../../HoC/RequireAuth";
import { AllRouteConstants } from "../../../router/routes";
import { HistoryHome } from "../pages";

const HistoryRoutes = () => {
  return (
    <>
      <Route
        path={AllRouteConstants.main.history.index}
        index
        element={<HistoryHome />}
      />
    </>
  );
};

export default HistoryRoutes;
