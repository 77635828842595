import "./DashboardLayoutStyles.scss";
import { RequireAuth } from "../../HoC/RequireAuth";
import DashboardMobile from "./DashboardMobile/DashboardMobile";
import DashboardDesktop from "./DashboardDesktop/DashboardDesktop";
import profileService from "../../services/profileService/ProfileService";
import { useEffect, useRef, useState } from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/Loader/Loader";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  useAppointmentActions,
  useAuthActions,
  useRedirectActions,
} from "../../hooks/useReduxActions";
import { getWSService } from "../../services/AppointmentSocketService/AppointmentSocketService";
import {
  Appointment,
  AppointmentInviteDetails,
} from "../../interfaces/Appointment";
import { Profile_Interface } from "../../interfaces/User";
import NewRequestModal from "./NewRequestModal";
import { AfterLoginAction_Action } from "../../interfaces/Redirect";
import appointmentService from "../../services/AppointmentService/AppointmentService";
import AppointmentModal from "./DashboardModals/AppointmentInvite";
import { useMediaQuery } from "@mui/material";
import { logger } from "../../utils/logger";
import { EventInput } from "@fullcalendar/core";

interface LayoutProps {}

const getProfileData = () => profileService.getUserProfileDetails();
const getAppointmentInviteData = (hash: string) =>
  appointmentService.getInviteDetails(hash);

const DashboardLayout = ({}: LayoutProps) => {
  const { setProfile } = useAuthActions();

  const matches = useMediaQuery("(max-width: 768px)");

  const { profile_data, user_data } = useAppSelector(
    (state) => state.authReducer
  );

  const getProfileRequest = useApi<{ data: Profile_Interface }, never>(
    getProfileData
  );

  const getUserProfileDetailsPromise = async () => {
    getProfileRequest.reset();

    const user = await getProfileRequest.request();

    if (user) {
      setProfile(user.data);
    }
  };

  const { setAfterLoginAction } = useRedirectActions();
  const { after_login_action } = useAppSelector(
    (state) => state.redirectReducer
  );

  const { pendingAppointmentRequest, currentAppointments: appointments } =
    useAppSelector((state) => state.appointmentReducer);

  useEffect(() => {
    if (!profile_data || !profile_data.id) {
      getUserProfileDetailsPromise();
    }

    const params = new URLSearchParams(window.location.search);

    const action = params.get("action");

    if (action) {
      const token = params.get("hash");

      if (token) {
        setAfterLoginAction({
          action: action as AfterLoginAction_Action,
          token,
        });
      }
    }
  }, []);

  const {
    newPendingRequest,
    acceptAppointment,
    toggleActiveAppointment,
    editAppointment,
  } = useAppointmentActions();

  const onNewAppoinmentRequest = (data: {
    appointment: Appointment;
    createdBy: Profile_Interface;
  }) => {
    if (!pendingAppointmentRequest) {
      newPendingRequest(data);
    }
  };

  const onAppointmentAccepted = (data: {
    appointmentId: string;
    acceptedBy: Profile_Interface;
  }) => {
    acceptAppointment(data);
  };

  const onAppointmentSessionStart = (data: { appointmentId: string }) => {
    toggleActiveAppointment({ ...data, active: true });
  };

  const onAppointmentPaid = (data: { appointmentId: string }) => {
    logger("Appointment Paid", data.appointmentId);

    editAppointment({
      appointmentId: data.appointmentId,
      data: {
        paid: true,
      },
    });
  };

  useEffect(() => {
    if (user_data) {
      getWSService().initSocket(user_data);
    } else {
      getWSService().closeSocket();
    }
  }, [user_data]);

  useEffect(() => {
    if (user_data && profile_data) {
      getWSService().addMessageListener("appointment_paid", onAppointmentPaid);

      if (profile_data.role === "TEACHER") {
        getWSService().addMessageListener(
          "appointment_request",
          onNewAppoinmentRequest
        );
      }

      if (profile_data.role === "STUDENT") {
        getWSService().addMessageListener(
          "appointment_accepted",
          onAppointmentAccepted
        );

        getWSService().addMessageListener(
          "appointment_session_started",
          onAppointmentSessionStart
        );
      }
    }
  }, [profile_data, user_data]);

  const [appointmentInviteDetails, setAppointmentInviteDetails] =
    useState<AppointmentInviteDetails | null>(null);

  const getAppointmentInviteApiRequest = useApi<
    { data: AppointmentInviteDetails },
    string
  >(getAppointmentInviteData);

  useEffect(() => {
    if (!getProfileRequest.loading && after_login_action) {
      if (after_login_action.action === "appointmentInvite") {
        // Open Accept Or Decline Modal
        getAppointmentInviteApiRequest
          .request(after_login_action.token)
          .then((data) => {
            if (data)
              setAppointmentInviteDetails({
                ...data.data,
                hash: after_login_action.token,
              });
          })
          .finally(setAfterLoginAction);
      }
    }
  }, [getProfileRequest.loading, after_login_action]);

  return (
    <RequireAuth>
      {getProfileRequest.loading ? (
        <Loader />
      ) : (
        <div className="dashboard_layout_container">
          {pendingAppointmentRequest !== null && (
            <NewRequestModal
              modalToggler={pendingAppointmentRequest !== null}
              data={pendingAppointmentRequest}
            />
          )}

          <AppointmentModal
            modalToggler={appointmentInviteDetails !== null}
            data={appointmentInviteDetails}
            handleClose={() => setAppointmentInviteDetails(null)}
          />

          {/* Desktop View */}
          {!matches && <DashboardDesktop />}
          {/* Mobile View */}
          {matches && <DashboardMobile />}
        </div>
      )}
    </RequireAuth>
  );
};

export default DashboardLayout;
