import { Card, Space, Statistic } from "antd";
import "./DashboardCardStyles.scss";
interface CardProps {
  title: string;
  value: number;
  lefticon: string;
  righticon: string;
  color: string;
  customCardClass?: string;
}

const DashboardCard: React.FC<CardProps> = ({
  title,
  value,
  lefticon,
  righticon,
  color,
  customCardClass,
}) => {
  return (
    <Card className={customCardClass} style={{ background: color }}>
      <Space direction="vertical" className="w-full">
        <Space className="dashboard_card_space_component">
          <img src={lefticon} />
          <img src={righticon} />
        </Space>
        <Space>
          <Statistic title={title} value={value} />
        </Space>
      </Space>
    </Card>
  );
};

export default DashboardCard;
