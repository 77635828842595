import Select from "react-select";
import "./DropdownStyles.scss";

interface DropdownProps {
  options: { value: string; label: string }[];
  disabled?: boolean;
  loading?: boolean;
  id: string;
  label?: string;
  error?: string | null;
  dropdownClassName?: string;
  labelClassName?: string;
  dropdownProps?: any;
}

const Dropdown = (props: DropdownProps) => {
  const {
    options,
    disabled,
    loading,
    label = null,
    labelClassName,
    id,
    error = null,
    dropdownProps,
    dropdownClassName,
  } = props;
  return (
    <div className="custom-input">
      {label && (
        <label htmlFor={id} className={`custom-input__label ${labelClassName}`}>
          {label}{" "}
          {dropdownProps?.required && (
            <span className="input_required_asterisk">*</span>
          )}
        </label>
      )}

      <Select
        className={dropdownClassName}
        classNamePrefix="select"
        isDisabled={disabled}
        isLoading={loading}
        options={options}
        required={dropdownProps?.required}
        {...dropdownProps}
      />
      <span
        className={`custom-input__error ${
          !error ? "custom-input__error-hidden" : undefined
        } animate__animated animate__fadeIn`}
      >{`${id} ${error}`}</span>
    </div>
  );
};

export default Dropdown;
