import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { DashboardHome } from "../pages";

const DashboardHomeRoutes = () => {
  return (
    <>
      <Route
        path={AllRouteConstants.main.dashboard}
        index
        element={<DashboardHome />}
      />
    </>
  );
};

export default DashboardHomeRoutes;
