import {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "./AppointmentFullCalendarStyles.scss";
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import CustomHeader from "./CustomHeader";
import { TuseAppointmentsCalendar } from "../../hooks/useAppointmentsCalendar";
import { Appointment } from "../../../../interfaces/Appointment";
import { CreateAppointmentData } from "../../../../interfaces/CreateAppointment";
import FullScreenLoader from "../FullScreenLoader";
import { isDateInPast } from "../../utils/appointmentDateHandler";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface Props {
  addbutton: JSX.Element;
  openAppointmentDetail: (appointment: Appointment) => void;
  openCreateAppointment: (data: CreateAppointmentData | null) => void;
  appointmentCalendarController: TuseAppointmentsCalendar;
}

const AppointmentFullCalendar: React.FC<Props> = ({
  addbutton,
  openAppointmentDetail,
  openCreateAppointment,
  appointmentCalendarController,
}) => {
  const { profile_data } = useAppSelector((state) => state.authReducer);

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <span className="ml-appointment">
        <b>{eventContent.timeText}</b>
        <span>{eventContent.event.title}</span>
      </span>
    );
  }

  const [title, setTitle] = useState("");
  const [mode, setMode] = useState("dayGridMonth");

  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    if (calendarRef.current) {
      setTitle(calendarRef.current.getApi().view.title);
    }
  }, [calendarRef.current]);

  const next = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      setTitle(calendarRef.current.getApi().view.title);
    }
  };

  const prev = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      setTitle(calendarRef.current.getApi().view.title);
    }
  };

  const today = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
      setTitle(calendarRef.current.getApi().view.title);
    }
  };

  const handleModeChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (calendarRef.current) {
      setMode(e.target.value);
      calendarRef.current.getApi().changeView(e.target.value);
      setTitle(calendarRef.current.getApi().view.title);
    }
  };

  const handleDates = (rangeInfo: DatesSetArg) => {
    appointmentCalendarController.getAppointments(
      rangeInfo.start,
      rangeInfo.end
    );
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    openAppointmentDetail(clickInfo.event.extendedProps as Appointment);
  };

  const handleDateClick = (dateInfo: DateSelectArg) => {
    const date = dateInfo.start;

    if (isDateInPast(date) || profile_data.role === "TEACHER") return;

    const data: CreateAppointmentData = {
      date,
    };

    if (mode !== "dayGridMonth") {
      data.start = dateInfo.start;
      data.end = dateInfo.end;
    }

    openCreateAppointment(data);
  };

  return (
    <>
      <FullScreenLoader
        loading={appointmentCalendarController.loadingCurrentAppointments}
      />

      <div className="appointment_full_calendar_container">
        <CustomHeader
          mode={mode}
          title={title}
          next={next}
          prev={prev}
          today={today}
          handleModeChange={handleModeChange}
          addbutton={addbutton}
        />

        <div className="calendar-container">
          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            headerToolbar={false}
            eventTextColor="#000"
            initialView="dayGridMonth"
            eventDisplay="block"
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            displayEventEnd={true}
            events={appointmentCalendarController.currentAppointments}
            datesSet={handleDates}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            select={handleDateClick}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentFullCalendar;
