import { useEffect, useState } from "react";
import { useAppSelector } from "./useAppSelector";
import useApi from "./useApi";
import {
  AuthorizePayData,
  Card,
  InitPayData,
  InitPayResponse,
  InitTokenizedPayData,
  InitTokenizedPayResponse,
  PaymentHistory,
} from "../interfaces/Checkout";
import paymentService from "../services/PaymentService";
import { usePaymentActions } from "./useReduxActions";
import { Appointment } from "../interfaces/Appointment";
import { toast } from "react-toastify";

export const usePayments = () => {
  const [payStatus, setPayStatus] = useState<string | null>(null);

  const { initialized_cards, cards, history, initialized_history } =
    useAppSelector((state) => state.paymentReducer);

  const { initializeCards, initializePaymentHistory } = usePaymentActions();

  const [selectedCard, setSelectedCard] = useState<Card | null>(null);

  const onCardSelect = (cardId: string) => () => {
    if (cardId === selectedCard?.cardId) {
      setSelectedCard(null);
    } else {
      const card = cards.filter((e) => e.cardId === cardId)[0];

      if (card) {
        setSelectedCard(card);
      }
    }
  };

  const fetchCardsApi = useApi<{ data: Card[] }, never>(
    paymentService.getUserCards
  );

  const fetchHistoryApi = useApi<{ data: PaymentHistory[] }, never>(
    paymentService.getUserPaymentHistory,
    "Failed to fetch payment history"
  );

  const initiatePaymentApi = useApi<InitPayResponse, InitPayData>(
    paymentService.initializePayment
  );

  const initTokenizedPayApi = useApi<
    InitTokenizedPayResponse,
    InitTokenizedPayData
  >(paymentService.initializeTokenizedPayment);

  const validatePaymentApi = useApi<
    { message: string; status: string },
    AuthorizePayData
  >(paymentService.authorizePayment);

  const fetchCards = async () => {
    const response = await fetchCardsApi.request();

    if (response) initializeCards(response.data);
  };

  useEffect(() => {
    if (!initialized_cards) {
      fetchCards();
    }
  }, [initialized_cards]);

  const fetchPaymentHistory = async () => {
    const response = await fetchHistoryApi.request();

    if (response) initializePaymentHistory(response.data);
  };

  useEffect(() => {
    if (!initialized_history) {
      fetchPaymentHistory();
    }
  }, [initialized_history]);

  const payWithNewCard = async (appointmentId: string) => {
    const response = await initiatePaymentApi.request({
      appointmentId,
    });

    if (!response) return;

    window.open(response.data.data.link);
  };

  const payWithExistingCard = async (appointmentId: string) => {
    if (!selectedCard) return;

    const response = await initTokenizedPayApi.request({
      appointmentId,
      cardId: selectedCard.cardId,
    });

    if (response) {
      setPayStatus(response.status);
    }
  };

  const payWithCard = async (selectedAppointment: Appointment) => {
    if (!selectedAppointment) return;

    if (!selectedCard) {
      await payWithNewCard(selectedAppointment.appointmentId);
    } else {
      await payWithExistingCard(selectedAppointment.appointmentId);
    }
  };

  const validatePayment = async (
    transactionId: string,
    appointmentId: string,
    save: boolean = true
  ) => {
    const response = await validatePaymentApi.request({
      appointmentId,
      transactionId,
      save,
    });

    setPayStatus(response?.status || null);
  };

  useEffect(() => {
    if (fetchCardsApi.error) {
      toast.error(fetchCardsApi.error);
    }

    if (fetchHistoryApi.error) {
      toast.error(fetchHistoryApi.error);
    }
  }, [fetchCardsApi.error, fetchHistoryApi.error]);

  const resetPayment = () => {
    setPayStatus(null);
    validatePaymentApi.reset();
    initTokenizedPayApi.reset();
    initiatePaymentApi.reset();
    setSelectedCard(null);
  };

  return {
    cards,
    history,
    payWithCard,
    initiatingPayment: initiatePaymentApi.loading,
    validatePayment,
    validatingPayment: validatePaymentApi.loading,
    initiatingTokenizedPay: initTokenizedPayApi.loading,
    selectedCard,
    onCardSelect,
    payStatus,
    resetPayment,
    setPayStatus,
    fetchingCards: fetchCardsApi.loading,
    fetchingHistory: fetchHistoryApi.loading,
  };
};
