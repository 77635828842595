import React from "react";
import "./styles.scss";
import { CallUser } from "../../../../interfaces/Call";

import {
  BsCameraVideo,
  BsCameraVideoOff,
  BsMic,
  BsMicMute,
  BsRecordCircle,
} from "react-icons/bs";
import { MdScreenShare, MdStopScreenShare } from "react-icons/md";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { IUserCallActions } from "../../interfaces/IUserCallActions";
import { AiOutlineMessage } from "react-icons/ai";
import { FaChalkboardTeacher } from "react-icons/fa";
import { AllRouteConstants } from "../../../../router/routes";
import ActionButton from "./ActionButton";

interface VideoCallControlsProps {
  adminUser: CallUser;
  action: (action: IUserCallActions) => Promise<void>;
  toggleMessage: VoidFunction;
}

const VideoCallControls: React.FC<VideoCallControlsProps> = ({
  adminUser,
  action,
  toggleMessage,
}) => {
  const { data, isScreenShared, selectedAppointment } = useAppSelector(
    (state) => state.callReducer
  );

  const { profile_data } = useAppSelector((state) => state.authReducer);

  const openWhiteBoard = () => {
    if (!data || !selectedAppointment || !selectedAppointment.whiteBoardRoom) {
      return;
    }

    window.open(
      `${AllRouteConstants.main.call.whiteboard}?token=${data.whiteBoardToken}&room=${selectedAppointment.whiteBoardRoom}`,
      "_blank"
    );
  };

  return (
    <div className="ml_call_home-left_sidebar_footer">
      <div className="ml_call_home-left_sidebar_footer-controls">
        <div className="volume"></div>

        <div className="controls">
          {adminUser && (
            <ActionButton
              description="Messages"
              Icon={AiOutlineMessage}
              id="message-action"
              onClick={toggleMessage}
            />
          )}

          {adminUser && adminUser.uid === profile_data.email && (
            <ActionButton
              Icon={adminUser?.audio ? BsMic : BsMicMute}
              description="Audio"
              id="audio-action"
              onClick={() => action("audio")}
            />
          )}

          {adminUser && adminUser.uid === profile_data.email && (
            <ActionButton
              Icon={adminUser?.video ? BsCameraVideo : BsCameraVideoOff}
              description="Video"
              id="video-action"
              onClick={() => action("video")}
            />
          )}

          {adminUser && adminUser.uid === profile_data.email && (
            <ActionButton
              Icon={!isScreenShared ? MdScreenShare : MdStopScreenShare}
              description={"Screen sharing"}
              onClick={() =>
                action(isScreenShared ? "endscreenshare" : "startscreenshare")
              }
              id="screenshare-action"
            />
          )}

          {adminUser && adminUser.uid === profile_data.email && (
            <ActionButton
              Icon={BsRecordCircle}
              description="Toggle Recording"
              id="recording-action"
              onClick={() => {}}
            />
          )}

          <ActionButton
            onClick={openWhiteBoard}
            Icon={FaChalkboardTeacher}
            id="whiteboard-action"
            description="Whiteboard"
          />
        </div>

        <button className="leave" onClick={() => action("leave")}>
          Leave Class
        </button>
      </div>
    </div>
  );
};

export default VideoCallControls;
