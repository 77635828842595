import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import platformlogo from "../../../assets/platform-logo.svg";
import visible from "../../../assets/visible.svg";
import notvisible from "../../../assets/not-visible.svg";
import { emailValidator } from "../../../utils/validators/emailValidator";
import { passwordValidator } from "../../../utils/validators/passwordValidator";
import { nameValidator } from "../../../utils/validators/nameVaildator";
import { emptyValidator } from "../../../utils/validators/emptyValidator";

import { useForm } from "../../../hooks/useForm";
import authService from "../../../services/authenticationService/AuthService";
import { SignupInput } from "../../../interfaces/SignupInput";
import useApi from "../../../hooks/useApi";
import { User } from "../../../interfaces/User";
import { AllRouteConstants } from "../../../router/routes";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import "../AuthStyles.scss";
import AuthError from "../components/AuthError/AuthError";
import PhoneInputField from "../../../components/Input/PhoneInput";
import { logger } from "../../../utils/logger";

export const Registration = () => {
  const navigate = useNavigate();

  const signupForm = useForm<SignupInput>(
    {
      email: "",
      password: "",
      given_name: "",
      family_name: "",
      role: "STUDENT",
      phone_number: "",
    },
    {
      email: emailValidator,
      password: passwordValidator,
      given_name: nameValidator,
      family_name: nameValidator,
      phone_number: emptyValidator,
    }
  );

  const signupApiRequest = useApi<User, SignupInput>(authService.signup);

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const firstNameRef = useRef<null | HTMLInputElement>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    signupForm.onChange(e.target.name as keyof SignupInput, e.target.value);
  };

  useEffect(() => {
    firstNameRef.current?.focus();
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    signupForm.resetFormErrors();

    signupApiRequest.reset();

    const valid = signupForm.validate();

    if (valid) {
      const success = await signupApiRequest.request(signupForm.form, false);

      if (success) {
        navigate(AllRouteConstants.auth.login);
      }
    } else {
      logger("Invalid Form", signupForm.formErrors);
    }
  };

  return (
    <div className="auth_container animate__animated animate__fadeIn">
      <img alt="mobile" src={platformlogo} />
      <AuthHeader message=" Create your account" />

      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="inputs">
          <Input
            id="firstName"
            label="First Name"
            ref={firstNameRef}
            error={signupForm.formErrors.given_name}
            inputProps={{
              name: "given_name",
              placeholder: "Enter your first name",
              value: signupForm.form.given_name,
              onChange: handleChange,
            }}
          />

          <Input
            id="lastName"
            label="Last Name"
            error={signupForm.formErrors.family_name}
            inputProps={{
              name: "family_name",
              placeholder: "Enter your last name",
              value: signupForm.form.family_name,
              onChange: handleChange,
            }}
          />

          <Input
            id="email"
            label="Email Address"
            error={signupForm.formErrors.email}
            inputProps={{
              name: "email",
              placeholder: "Enter your email address",
              value: signupForm.form.email,
              onChange: handleChange,
            }}
          />
          <PhoneInputField
            id="phoneNumber"
            label="Phone Number"
            error={signupForm.formErrors.phone_number}
            value={signupForm.form.phone_number}
            onChange={(value: string) =>
              signupForm.onChange("phone_number", `+${value}`)
            }
            inputProps={{
              name: "phone_number",
              required: true,
              autoFocus: true,
            }}
          />

          <Input
            id="password"
            label="Password"
            error={signupForm.formErrors.password}
            rightIcon={
              <div
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={togglePassword}
              >
                {passwordType === "password" ? (
                  <img src={visible} className="w-[20px]" />
                ) : (
                  <img src={notvisible} className="w-[20px]" />
                )}
              </div>
            }
            inputProps={{
              name: "password",
              type: passwordType,
              placeholder: "Enter your password",
              value: signupForm.form.password,
              onChange: handleChange,
            }}
          />
        </div>

        <div style={{ height: 16 }} />

        <Button
          variant="primary"
          label={"Register"}
          loading={signupApiRequest.loading}
        />
        <AuthError error={signupApiRequest.error} />

        <p>
          Already have an account?{" "}
          <Link
            to={signupApiRequest.loading ? "#" : AllRouteConstants.auth.login}
            className="forgot_password"
          >
            Login
          </Link>
        </p>
      </form>
    </div>
  );
};
