import { CallMessage } from "../redux/call/reducer";

export function hasMessage(
  messages: CallMessage[],
  search: CallMessage
): boolean {
  return messages.some((msg: CallMessage) => {
    return msg.message === search.message && msg.senderId === search.senderId;
  });
}
