import { Dispatch } from "react";
import { ActionConstants } from "./action-constants";
import { RedirectActionTypes } from "./action-types";
import { AfterLoginAction } from "../../interfaces/Redirect";

export const setAuthRedirect =
  (route?: string) => (dispatch: Dispatch<RedirectActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_AUTH_REDIRECT,
      payload: route || null,
    });
  };

export const setAfterLoginAction =
  (action?: AfterLoginAction) => (dispatch: Dispatch<RedirectActionTypes>) => {
    dispatch({
      type: ActionConstants.SET_AFTER_LOGIN_ACTION,
      payload: action || null,
    });
  };
