import axios from "axios";
import {
  AuthorizePayData,
  InitPayData,
  InitTokenizedPayData,
} from "../../interfaces/Checkout";
import { API_URL } from "../../config/env";

class PaymentService {
  constructor() {}

  initializePayment = (data: InitPayData) => {
    return axios.post(`${API_URL}/payments/pay`, data, {
      withCredentials: true,
    });
  };

  initializeTokenizedPayment = (data: InitTokenizedPayData) => {
    return axios.post(`${API_URL}/payments/pay/tokenized`, data, {
      withCredentials: true,
    });
  };

  authorizePayment = (data: AuthorizePayData) => {
    return axios.post(`${API_URL}/payments/pay/authorize`, data, {
      withCredentials: true,
    });
  };

  getUserCards = () => {
    return axios.get(`${API_URL}/payments/cards`, {
      withCredentials: true,
    });
  };

  getUserPaymentHistory = () => {
    return axios.get(`${API_URL}/payments/history`, {
      withCredentials: true,
    });
  };
}

const paymentService = new PaymentService();
export default paymentService;
