import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SplineChart = () => {
  const data = [
    { month: "Jan", value: 11 },
    { month: "Feb", value: 12 },
    { month: "Mar", value: 8 },
    { month: "Apr", value: 20 },
    { month: "May", value: 50 },
    { month: "Jun", value: 60 },
    { month: "Jul", value: 70 },
    { month: "Aug", value: 80 },
    { month: "Sep", value: 90 },
    { month: "Oct", value: 100 },
    { month: "Nov", value: 85 },
    { month: "Dec", value: 90 },
  ];

  const yTicks = Array.from(Array(11), (_, i) => i * 10);

  return (
    <ResponsiveContainer
      className="animate__animated animate__fadeIn"
      width="100%"
      height={500}
    >
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis ticks={yTicks} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#6C60FF"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SplineChart;

// import React, { FC, useState } from "react";
// import { LineChart } from "recharts";

// const SplineChart: FC = () => {
//   const [data, setData] = useState([
//     {
//       month: "Jan",
//       value: 10,
//     },
//     {
//       month: "Feb",
//       value: 20,
//     },
//     {
//       month: "Mar",
//       value: 30,
//     },
//     {
//       month: "Apr",
//       value: 40,
//     },
//     {
//       month: "May",
//       value: 50,
//     },
//     {
//       month: "Jun",
//       value: 60,
//     },
//     {
//       month: "Jul",
//       value: 70,
//     },
//     {
//       month: "Aug",
//       value: 80,
//     },
//     {
//       month: "Sep",
//       value: 90,
//     },
//     {
//       month: "Oct",
//       value: 100,
//     },
//     {
//       month: "Nov",
//       value: 90,
//     },
//     {
//       month: "Dec",
//       value: 80,
//     },
//   ]);

//   return (
//     <div>
//       <LineChart
//         data={data}
//         xAxis={[
//           {
//             type: "category",
//             dataKey: "month",
//           },
//         ]}
//         yAxis={[
//           {
//             type: "linear",
//             min: 0,
//             max: 100,
//             step: 10,
//           },
//         ]}
//         series={[
//           {
//             name: "Spline",
//             type: "spline",
//             dataKey: "value",
//             borderColor: "#6C60FF",
//             borderWidth: 2,
//           },
//         ]}
//       />
//     </div>
//   );
// };

// export default SplineChart;
