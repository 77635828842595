import { useAppSelector } from "../../../../hooks/useAppSelector";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import AbsenteeCard from "./Cards/AbsenteeCard";
import AttendedCard from "./Cards/AttendedCard";
import "./WeeklyPerformanceStyles.scss";
const WeeklyPerformance = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);
  const headerSize = () => {
    switch (currentScreen) {
      case "mobile":
        return "lg";
      case "desktop":
        return "lg";
      case "tablet":
        return "md";
    }
  };
  return (
    <div>
      <DashboardLeftHeader
        message="Your Performance this week"
        size={headerSize()}
      />
      <div className="weekly_performance_card_container">
        {/* Custom Card */}
        <div className="flex_1">
          <AttendedCard />
        </div>
        {/* Absentee Card */}
        <div className="flex_1">
          <AbsenteeCard />
        </div>
      </div>
    </div>
  );
};

export default WeeklyPerformance;
