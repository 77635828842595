import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../router/routes";
import SettingsItem from "./SettingsItem";
import "./SettingsLayoutStyles.scss";
import { useAppSelector } from "../../hooks/useAppSelector";

const SettingsNavigation = () => {
  const navigate = useNavigate();

  const { profile_data } = useAppSelector((state) => state.authReducer);

  return (
    <div>
      <h1 className="settings_layout_header">Settings</h1>
      <div className="settings_layout_navigation_container">
        <div className="settings_layout_navigation_inner_container">
          <SettingsItem
            paths={["/settings", AllRouteConstants.main.settings.profile.index]}
            onClick={() =>
              navigate(AllRouteConstants.main.settings.profile.index)
            }
          >
            Profile
          </SettingsItem>
          <SettingsItem
            paths={[AllRouteConstants.main.settings.account.index]}
            onClick={() =>
              navigate(AllRouteConstants.main.settings.account.index)
            }
          >
            Account
          </SettingsItem>
          {profile_data.role === "STUDENT" && (
            <SettingsItem
              paths={[AllRouteConstants.main.settings.payment]}
              onClick={() => navigate(AllRouteConstants.main.settings.payment)}
            >
              Payment
            </SettingsItem>
          )}
          <SettingsItem
            paths={[AllRouteConstants.main.settings.support]}
            onClick={() => navigate(AllRouteConstants.main.settings.support)}
          >
            Support
          </SettingsItem>
        </div>
      </div>
    </div>
  );
};

export default SettingsNavigation;
