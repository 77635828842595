import { Outlet } from "react-router-dom";
import SettingsNavigation from "./SettingsNavigation";
import "./SettingsLayoutStyles.scss";
import { useAppSelector } from "../../hooks/useAppSelector";

const SettingsLayout = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);
  return (
    <div>
      {!(currentScreen === "mobile") && <SettingsNavigation />}
      <div className="settings_layout_outlet">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
