import React from "react";
import { useLocation } from "react-router-dom";
import "./DashboardSidebarStyles.scss";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface NavItemProps {
  paths: string[];
  onClick: VoidFunction;
  sidebarIcon: JSX.Element;
  sidebarItemName: string;
}

const DashboardSidebarItem: React.FC<NavItemProps> = ({
  paths,
  sidebarIcon,
  sidebarItemName,
  onClick,
}) => {
  const { pathname } = useLocation();
  const sidebarItemCurrentPath = paths[0]?.split("/")[1];
  const start = useAppSelector((state) => state.callReducer.start);

  return (
    <div
      className={`dashboard_sidebar_item_container ${
        pathname.split("/")[1] === sidebarItemCurrentPath
          ? "dashboard_sidebar_item_container_active"
          : ""
      }`}
    >
      <div
        className={`dashboard_sidebar_item_icon ${start ? "" : "disabled"} `}
        onClick={() => {
          if (!start) {
            return onClick();
          }
        }}
      >
        {sidebarIcon}
      </div>
      <span className={`dashboard_sidebar_item_text`}>{sidebarItemName}</span>
    </div>
  );
};

export default DashboardSidebarItem;
