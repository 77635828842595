export enum ActionConstants {
  INITIALIZE = "INITIALIZE_APPOINTMENTS",
  ADD = "ADD_APPOINTMENT",
  EDIT = "EDIT_APPOINTMENT",
  APPOINTMENT_REQUEST = "APPOINTMENT_REQUEST",
  ACCEPT_APPOINTMENT = "ACCEPT_APPOINTMENT",
  START_SESSION = "START_APPOINTMENT_SESSION",
  REMOVE = "REMOVE_APPOINTMENT",
  SET_LOADING_CUURENT = "SET_LOADING_CUURENT_APPOINTMENT",
  SET_CURRENT = "SET_CURRENT_APPOINTMENT",
}
