// Package Imports
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

// Drawer Styles
import "./AppointmentDrawerStyles.scss";
import PopDrawer from "../../../../layouts/DrawerLayout/DrawerLayout";
import CreateAppointmentDrawerInitialPage from "../CreateAppointmentDrawerComponents/CreateAppointmentDrawerInitialPage/CreateAppointmentDrawerInitialPage";
import {
  CreateAppointmentData,
  IAppointmentRequest,
} from "../../../../interfaces/CreateAppointment";
import CreateAppointmentDrawerCourseForm from "../CreateAppointmentDrawerComponents/CreateAppointmentDrawerCourseForm/CreateAppointmentDrawerCourseForm";
import { useForm } from "../../../../hooks/useForm";
import { emptyValidator } from "../../../../utils/validators/emptyValidator";
import OnDemandAppointmentWaiting from "../CreateAppointmentDrawerComponents/OnDemandAppointmentWaiting/OnDemandAppointmentWaiting";
import { Appointment } from "../../../../interfaces/Appointment";

interface ICreateAppointmentDrawer {
  onClose: () => void;
  drawerToggler: boolean;
  openAppointmentDetail: (appointment: Appointment) => void;
  createAppointmentData: CreateAppointmentData | null;
}

const CreateAppointmentDrawer = (props: ICreateAppointmentDrawer) => {
  const {
    onClose,
    drawerToggler,
    openAppointmentDetail,
    createAppointmentData,
  } = props;

  const [createdAppointment, setCreatedAppointment] = useState("");

  const [createAppointmentFormSteps, setCreateAppointmentFormSteps] =
    useState(1);

  const createAppointmentForm = useForm<IAppointmentRequest>(
    {
      course: "",
      topic: "",
      comments: "",
      isOnDemand: false,
      startTime: "",
      endTime: "",
      type: "Individual",
      duration: 0,
      users: [],
    },
    {
      course: emptyValidator,
      topic: emptyValidator,
      startTime: emptyValidator,
    }
  );

  // Preload Selected Data [TODO]
  useEffect(() => {
    if (createAppointmentData?.start && createAppointmentData?.end) {
      createAppointmentForm.onChange("isOnDemand", false);
      createAppointmentForm.onChange(
        "startTime",
        createAppointmentData.start.getTime()
      );
      createAppointmentForm.onChange(
        "endTime",
        createAppointmentData.end.getTime()
      );
      setCreateAppointmentFormSteps(2);
    }
  }, [createAppointmentData?.start, createAppointmentData?.end]);

  const onDrawerClose = () => {
    onClose();
    createAppointmentForm.reset();
    setCreatedAppointment("1");
    setCreateAppointmentFormSteps(1);
  };

  return (
    <PopDrawer onClose={onDrawerClose} drawerToggler={drawerToggler}>
      <div className="create_appointment_drawer">
        <div className="create_appointment_drawer_container">
          {/* This is the Share and Cancel Button Container */}
          <div className="create_appointment_drawer_head">
            <div className="create_appointment_drawer_head_container">
              <AiOutlineClose
                className="create_appointment_drawer_head_icon"
                onClick={onDrawerClose}
              />
            </div>
          </div>
          {createAppointmentFormSteps < 3 && (
            <>
              {/* This is the appointment initial Body */}
              <CreateAppointmentDrawerInitialPage
                appointmentForm={createAppointmentForm}
                currentFormStep={createAppointmentFormSteps}
                setCurrentFormStep={setCreateAppointmentFormSteps}
                initialDate={createAppointmentData?.date}
                drawerToggler={drawerToggler}
              />
            </>
          )}

          {createAppointmentFormSteps === 3 && (
            <CreateAppointmentDrawerCourseForm
              appointmentForm={createAppointmentForm}
              currentFormStep={createAppointmentFormSteps}
              setCurrentFormStep={setCreateAppointmentFormSteps}
              handleCloseDrawer={onDrawerClose}
              setCreatedAppointment={setCreatedAppointment}
            />
          )}

          {createAppointmentFormSteps === 4 && createdAppointment && (
            <OnDemandAppointmentWaiting
              appointmentId={createdAppointment}
              modalToggler={Boolean(
                createAppointmentFormSteps === 4 && createdAppointment
              )}
              handleCloseDrawer={onDrawerClose}
              openAppointmentDetail={openAppointmentDetail}
            />
          )}
        </div>
      </div>
    </PopDrawer>
  );
};

export default CreateAppointmentDrawer;
