export enum ActionConstants {
  SET_CURRENT_SESSION = "SET_CURRENT_CALL_SESSION",
  ADD_CALL_USER = "ADD_CALL_USER",
  START_CALL = "START_CALL",
  CLEAR_CALL_USERS = "CLEAR_CALL_USERS",
  UPDATE_CALL_USERS = "UPDATE_CALL_USERS",
  SHARE_SCREEN = "SHARE_SCREEN",
  Add_MESSAGE = "ADD_MESSAGE",
  SELECT_APPOINTMENT = "SELECT_CALL_APPOINTMENT",
}
