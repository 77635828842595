import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import {
  ForgotPassword,
  Login,
  OtpVerification,
  Registration,
  ResetPassword,
  VerifySignIn,
} from "../pages";

const AuthRoutes = () => {
  return (
    <>
      <Route index element={<Login />} />
      <Route path={AllRouteConstants.auth.login} element={<Login />} />
      <Route
        path={AllRouteConstants.auth.tfaVerification}
        element={<VerifySignIn />}
      />
      <Route path={AllRouteConstants.auth.signup} element={<Registration />} />
      <Route
        path={AllRouteConstants.auth.forgotPassword}
        element={<ForgotPassword />}
      />
      <Route
        path={AllRouteConstants.auth.signUpVerification}
        element={<OtpVerification />}
      />
      <Route
        path={AllRouteConstants.auth.resetPassword}
        element={<ResetPassword />}
      />
    </>
  );
};

export default AuthRoutes;
