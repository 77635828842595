import "./SettingsStyles.scss";
import { AccountInfoCard2 } from "../components/AccountInfoCard/AccountInfoCard";
import { AllRouteConstants } from ".././../../router/routes";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAuthActions } from "../../../hooks/useReduxActions";

export const SettingsHome = () => {
  const { signOut } = useAuthActions();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate(AllRouteConstants.auth.index);
  };
  const { profile_data } = useAppSelector((state) => state.authReducer);

  return (
    <div>
      <p className="settings_home_intro_text animate__animated animate__fadeIn">
        Hey {profile_data?.family_name ?? "OluwaPelumi"} 👋🏿
      </p>
      <h2 className="settings_home_intro_header">View & Edit Your Details</h2>
      <div className="settings_home_info_card_container">
        <AccountInfoCard2
          message="Profile"
          route={AllRouteConstants.main.settings.profile.index}
        />
        <AccountInfoCard2
          message="Account"
          route={AllRouteConstants.main.settings.account.index}
        />

        {profile_data.role === "STUDENT" && (
          <AccountInfoCard2
            message="Payment"
            route={AllRouteConstants.main.settings.payment}
          />
        )}

        <AccountInfoCard2
          message="Support"
          route={AllRouteConstants.main.settings.support}
        />
        <Button label="Logout" variant="primary" onClick={handleLogout} />
      </div>
    </div>
  );
};
