export const convertDateToTime = (date: Date | string) => {
  if (typeof date === "string") {
    date = new Date();
  }

  let hour = date.getHours();
  const minute = date.getMinutes();

  const timePrefix = hour > 11 ? "pm" : "am";

  if (hour !== 0 && hour > 12) hour = hour - 12;
  else if (hour === 0) hour = 12;

  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")} ${timePrefix}`;
};
