import React, { useEffect } from "react";
import { useFastboard, Fastboard } from "@netless/fastboard-react";
import { WhiteBoardMountProps } from "../../../../interfaces/Call";

const WhiteBoardMount: React.FC<WhiteBoardMountProps> = (props) => {
  const fastboard = useFastboard(() => ({
    sdkConfig: {
      appIdentifier: "oTtRQAnUEe61u_3ggCQTcw/GKMZTVWersRwXw",
      region: "us-sv",
    },
    joinRoom: {
      ...props,
    },
  }));

  useEffect(() => {
    return () => {
      fastboard?.destroy();
    };
  }, []);

  return (
    <div className="mount">
      <Fastboard app={fastboard} />
    </div>
  );
};

export default WhiteBoardMount;
