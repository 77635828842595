import buttonicon from "../../../../assets/buttonicon.svg";
import { useNavigate } from "react-router-dom";
import "./BackButtonStyles.scss";
const BackButton = ({
  route,
  onClick,
}: {
  route?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    if (route) {
      navigate(route);
      return;
    } else if (onClick) {
      onClick();
    }
  };
  return (
    <button onClick={goBackHandler} className="settings_account_back_button">
      <img src={buttonicon} alt="icon" />
      Back
    </button>
  );
};

export default BackButton;
