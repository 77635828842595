import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import platformlogo from "../../../assets/platform-logo.svg";
import visible from "../../../assets/visible.svg";
import notvisible from "../../../assets/not-visible.svg";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import { emailValidator } from "../../../utils/validators/emailValidator";
import { useForm } from "../../../hooks/useForm";
import Input from "../../../components/Input/Input";
import authService from "../../../services/authenticationService/AuthService";
import { LoginInput } from "../../../interfaces/LoginInput";
import useApi from "../../../hooks/useApi";
import { User } from "../../../interfaces/User";
import { AllRouteConstants } from "../../../router/routes";
import Button from "../../../components/Button/Button";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import "../AuthStyles.scss";
import AuthError from "../components/AuthError/AuthError";
import { useAuthActions } from "../../../hooks/useReduxActions";

export const Login = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const loginForm = useForm<LoginInput>(
    { email: "", password: "" },
    { email: emailValidator, password: emptyValidator }
  );

  const loginApiRequest = useApi<User, LoginInput>(authService.login);

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    loginForm.onChange("email", e.target.value);
  };

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    loginForm.onChange("password", e.target.value);
  };

  useEffect(() => {
    if (
      loginApiRequest.responseError?.data?.message?.toLowerCase() ===
      "User is not confirmed.".toLowerCase()
    ) {
      navigate(AllRouteConstants.auth.signUpVerification, {
        state: { userEmail: loginForm.form.email },
        replace: true,
      });
    } else if (loginApiRequest.responseError?.data?.code == "RequireTfa") {
      if (loginApiRequest.responseError?.data?.data)
        navigate(AllRouteConstants.auth.tfaVerification, {
          state: {
            ...loginApiRequest.responseError.data.data,
            email: loginForm.form.email,
          },
        });
    }
  }, [loginApiRequest.responseError]);

  const { signIn } = useAuthActions();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    loginForm.resetFormErrors();
    loginApiRequest.reset();
    const valid = loginForm.validate();
    if (valid) {
      if (!loginForm.form.email || !loginForm.form.password) {
        return;
      }

      try {
        const user = await loginApiRequest.request(loginForm.form, false);

        if (user) {
          signIn({
            token: user.token,
            access_token: user.access_token,
            refresh_token: user.refresh_token,
          });
        }
      } catch (error) {}
    }
  };

  return (
    <div className="auth_container animate__animated animate__fadeIn">
      <img alt="mobile" src={platformlogo} />

      <AuthHeader message="Login to your Account" />
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="inputs">
          <Input
            id="email"
            label="Email Address"
            error={loginForm.formErrors.email}
            inputProps={{
              placeholder: "Enter your email address",
              value: loginForm.form.email,
              onChange: handleChangeEmail,
            }}
          />

          <Input
            id="password"
            label="Password"
            error={loginForm.formErrors.password}
            rightIcon={
              <div
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={togglePassword}
              >
                {passwordType === "password" ? (
                  <img src={visible} style={{ width: "20px" }} />
                ) : (
                  <img src={notvisible} style={{ width: "20px" }} />
                )}
              </div>
            }
            inputProps={{
              type: passwordType,
              placeholder: "Enter your password",
              value: loginForm.form.password,
              onChange: handleChangePassword,
            }}
          />
        </div>

        <p>
          <Link
            className="forgot_password"
            style={{ fontWeight: 500 }}
            to={
              loginApiRequest.loading
                ? "#"
                : AllRouteConstants.auth.forgotPassword
            }
          >
            Forgot Password?
          </Link>
        </p>

        <Button
          label={"Login"}
          loading={loginApiRequest.loading}
          variant="primary"
        />

        <AuthError error={loginApiRequest.error} />

        <p>
          Don’t have an account?{" "}
          <Link
            to={loginApiRequest.loading ? "#" : AllRouteConstants.auth.signup}
            className="forgot_password"
          >
            Create an account
          </Link>
        </p>
      </form>
    </div>
  );
};
