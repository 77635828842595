import "./DashboardRightStyles.scss";
import DashboardSearch from "../DashboardSearch/DashboardSearch";
import NotificationsAndAvatar from "../../../../components/NotificationsAndAvatar/NotificationsAndAvatar";
import WeeklyPerformance from "../WeeklyPerformance/WeeklyPerformance";
import TeacherQualification from "../TeacherQualification/TeacherQualification";
import LearningActivity from "../LearningActivity/LearningActivity";
import PendingAppointments from "../PendingAppointment/PendingAppointments";
const DashboardRight = () => {
  return (
    <div className="dashboard_right_container">
      <div className="dashboard_right_inner_container">
        <div className="dashboard_right_top_section">
          <DashboardSearch />
          <NotificationsAndAvatar />
        </div>
        <div className="dashboard_right_bottom_section">
          <TeacherQualification />
          <PendingAppointments />
        </div>
      </div>
    </div>
  );
};

export default DashboardRight;
