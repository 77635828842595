import { BrowserRouter, Routes, Route } from "react-router-dom";
// Route Constants
import { AllRouteConstants } from "./routes";
// scroll
import Scroll from "../components/scrollToTop/ScrollToTop";
// Routes
import AuthRoutes from "../features/auth/routes/Routes";
import SettingsRoutes from "../features/settings/routes/Routes";
// Layouts
import AuthLayout from "../layouts/AuthLayout/AuthLayout";
import SettingsLayout from "../layouts/settingsLayout/SettingsLayout";
import DashboardLayout from "../layouts/dashboardLayout/DashboardLayout";
import AppointmentRoutes from "../features/appointment/routes/Routes";
import DashboardHomeRoutes from "../features/dashboardHome/routes/Routes";
import HistoryRoutes from "../features/history/routes/Routes";
import CallRoutes from "../features/call/routes/Routes";
import NotFound from "../pages/NotFound/NotFound";
import CheckoutRoutes from "../features/checkout/routes/Routes";

function Router() {
  return (
    <BrowserRouter>
      <Scroll />
      <Routes>
        {/* Auth Routes */}
        <Route path={AllRouteConstants.auth.index} element={<AuthLayout />}>
          {AuthRoutes()}
        </Route>

        {/* Dashboard Routes */}
        <Route
          path={AllRouteConstants.main.dashboard}
          element={<DashboardLayout />}
        >
          {DashboardHomeRoutes()}

          {AppointmentRoutes()}

          {CheckoutRoutes()}

          {HistoryRoutes()}

          {CallRoutes()}

          <Route
            path={AllRouteConstants.main.settings.index}
            element={<SettingsLayout />}
          >
            {SettingsRoutes()}
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
