import "./DashboardTableStyles.scss";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import DashboardLeftHeader from "../DashboardLeftHeaders/DashboardLeftHeader";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface DataType {
  key: string;
  courses: string;
  timetaken: string;
  score: string;
  performance: string;
}

const data: DataType[] = [
  {
    key: "1",
    courses: "John Brown",
    timetaken: "2 hours",
    score: "73%",
    performance: "Good",
  },
  {
    key: "2",
    courses: "Jim Green",
    timetaken: "1 hour",
    score: "48%",
    performance: "Poor",
  },
  {
    key: "3",
    courses: "Joe Black",
    timetaken: "1 hour",
    score: "56%",
    performance: "Fair",
  },
];

const columnsDesktop: ColumnsType<DataType> = [
  {
    title: "courses",
    dataIndex: "courses",
    key: "courses",
  },
  {
    title: "Time Taken",
    dataIndex: "timetaken",
    key: "timetaken",
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "Performance",
    dataIndex: "performance",
    key: "performance",
  },
];
const columnsMobile: ColumnsType<DataType> = [
  {
    title: "courses",
    dataIndex: "courses",
    key: "courses",
  },
  {
    title: "Performance",
    dataIndex: "performance",
    key: "performance",
  },
];
const DashboardTable = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);

  return (
    <div>
      <DashboardLeftHeader message="Student Record" />
      <Table
        columns={currentScreen === "mobile" ? columnsMobile : columnsDesktop}
        dataSource={data}
        pagination={false}
        bordered={true}
      />
    </div>
  );
};

export default DashboardTable;
