import { useEffect, useState } from "react";
import Button from "../../../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import { AllRouteConstants } from "../../../../../router/routes";
import OtpInput from "../../../../../components/OtpInput/OtpInput";
import AnimateHeight from "react-animate-height";
import useApi from "../../../../../hooks/useApi";
import profileService from "../../../../../services/profileService/ProfileService";
import Spinner from "../../../../../components/Spinner/Spinner";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import "../../SettingsStyles.scss";
import PhoneVerifiedModal from "../../../components/Modals/PhoneVerifiedModal";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";

export const PhoneVerification = () => {
  const onChange = (value: string) => setOtp(value.trim());
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState<number>(1);
  const [disable, setDisable] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const getVerificationCode = () => profileService.getVerificationCode();
  const confirmVerificationCode = () =>
    profileService.confirmVerificationCode();
  const getPhoneVerificationRequest = useApi<string, any>(getVerificationCode);
  const confirmPhoneVerificationRequest = useApi<string, { code: string }>(
    confirmVerificationCode
  );

  const { profile_data } = useAppSelector((state) => state.authReducer);

  const sendPhoneVerificationCode = async () => {
    getPhoneVerificationRequest.reset();
    try {
      const user = await getPhoneVerificationRequest.request();
      console.log(user);
    } catch (error) {}
  };

  useEffect(() => {
    if (minutes < 0) {
      setDisable(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds > 0) {
      if (seconds < 10) {
        const interval = setInterval(
          () => setSeconds(parseInt(String(seconds - 1).padStart(2, "0"))),
          1000
        );
        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => setSeconds(seconds - 1), 1000);
        return () => clearInterval(interval);
      }
    } else {
      setMinutes(minutes - 1);
      setSeconds(59);
    }
  }, [seconds]);

  // Send the Phone Verification Code
  useEffect(() => {
    sendPhoneVerificationCode();
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const result = await confirmPhoneVerificationRequest.request({
      code: otp,
    });
    if (result) {
      setPhoneVerified(true);
    }
  };

  const handleCloseModal = () => {
    setPhoneVerified(false);
    navigate(AllRouteConstants.main.settings.profile.index);
  };

  return (
    <div className="settings_account_container">
      {getPhoneVerificationRequest.loading ? (
        <Loader />
      ) : (
        <div className="settings_phone_verification">
          <PhoneVerifiedModal
            modalToggler={phoneVerified}
            onClose={handleCloseModal}
          />
          <BackButton
            route={AllRouteConstants.main.settings.account.enable2Fa.index}
          />
          <div style={{ marginTop: "2rem" }}>
            <div className="settings_phone_verification_container">
              <div className="settings_phone_verification_inner_container">
                <h2>Phone Number Verification</h2>
                <p>Receive OTP to your registered number</p>
              </div>
              <div className="settings_phone_verification_inner_container">
                <h2>Enter Verification Code</h2>
                <p>
                  Enter the 6-Digit code we sent to your phone number:
                  <span> {profile_data.phone_number}</span>
                </p>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="enable_2fa_phone_verification_otp">
              <OtpInput value={otp} valueLength={6} onChange={onChange} />
              <AnimateHeight duration={300} height={minutes < 0 ? 0 : "auto"}>
                <p className="auth_text_2">
                  Resend code in{" "}
                  <span
                    style={{ color: "#377E3F", fontWeight: "bold" }}
                  >{`${minutes}:${seconds}`}</span>
                </p>
              </AnimateHeight>
              <div className="">
                {/* Error Handler */}
                {/* <AuthError error={"is"} /> */}
                <Button
                  label="Verify"
                  variant="primary"
                  disable={otp.length !== 6}
                  loading={confirmPhoneVerificationRequest.loading}
                />
                <Button
                  label="Resend"
                  variant="secondary"
                  disable={disable}
                  type="button"
                  onClick={() => {
                    setSeconds(59);
                    setMinutes(1);
                    setDisable(true);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
