import React, { useEffect, useState } from "react";
import { ICreateAppointment } from "../../../../interfaces/CreateAppointment";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import Popover from "@mui/material/Popover";
import { convertDateStringToDate } from "../../utils/convertStringToDate";
import { convertUnixTimeStampToTime } from "../../utils/convertUnixTimeStampToTime";

interface ISetAppointmentTime extends ICreateAppointment {
  date: any;
}

const SetAppointmentTime = (props: ISetAppointmentTime) => {
  const { appointmentForm, setCurrentFormStep, date } = props;

  const { form, onChange, formErrors } = appointmentForm;

  const goBack = () => setCurrentFormStep(1);

  const goForward = () => setCurrentFormStep(3);

  // Setting the Anchor Element from the Pop Over
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleUpdateDate = (type: "startTime" | "endTime", time: string) => {
    const dateTimeString = `${convertDateStringToDate(date)}T${time}:00`;
    const newCurrentDate = new Date(dateTimeString);
    const unixTimestamp = newCurrentDate.getTime();
    return onChange(type, unixTimestamp);
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return timeString;
  };

  useEffect(() => {
    if (form.isOnDemand) {
      const currentTime = getCurrentTime();
      handleUpdateDate("startTime", currentTime);
    }
  }, [form.isOnDemand]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <form
      className="appointment-time-form animate__animated animate__fadeIn"
      onSubmit={handleSubmit}
    >
      <div className="inputs">
        {form.isOnDemand ? (
          <Input
            id="duration"
            label="Duration"
            error={formErrors.duration!}
            inputProps={{
              placeholder: "Appointment Duration in Hours",
              value: form.duration,
              onChange: (e) => onChange("duration", parseInt(e.target.value)),
              type: "number",
              required: true,
              min: 1,
              max: 8,
            }}
          />
        ) : (
          <>
            <Input
              id="start Time"
              label="Start Time"
              error={formErrors.startTime}
              inputProps={{
                placeholder: "Enter your Start Time",
                value: convertUnixTimeStampToTime(form.startTime as number),
                onChange: (e) => handleUpdateDate("startTime", e.target.value),
                type: "time",
                required: true,
                step: "3600",
              }}
            />

            <Input
              id="End Time"
              label="End Time"
              error={formErrors.endTime!}
              inputProps={{
                placeholder: "Enter your End Time",
                value: convertUnixTimeStampToTime(form.endTime! as number),
                onChange: (e) => handleUpdateDate("endTime", e.target.value),
                type: "time",
                required: true,
                step: "3600",
              }}
            />
          </>
        )}
      </div>
      <div className="create_appointment_btn_container">
        <Button
          type="button"
          label="Back"
          variant="secondary"
          buttonClassName="create_appointment_personal_form_buttons_back_button"
          size="md"
          onClick={goBack}
        />
        <>
          <Button
            label="Confirm Appointment"
            variant="primary"
            buttonClassName="create_appointment_personal_form_buttons_back_button"
            size="md"
            buttonProps={{
              "aria-describedby": id,
            }}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className="appointment_timer_dialog_box_container"
          >
            <div className="appointment_timer_dialog_box">
              Select from the below option what the meeting is allocated for?
              <div className="appointment_timer_dialog_box_button_container">
                <Button
                  label="Person"
                  variant="primary"
                  buttonClassName="appointment_timer_dialog_box_button"
                  onClick={() => {
                    onChange("type", "Individual");
                    return goForward();
                  }}
                />
                <Button
                  label="Group"
                  variant="secondary"
                  buttonClassName="appointment_timer_dialog_box_button organizational_button"
                  onClick={() => {
                    onChange("type", "Group");
                    return goForward();
                  }}
                />
              </div>
            </div>
          </Popover>
        </>
      </div>
    </form>
  );
};

export default SetAppointmentTime;
