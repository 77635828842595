import React, { useEffect, useState } from "react";
import "./AppointmentRadioStyles.scss";

interface IAppointmentRadio {
  id: string;
  radioProps: React.InputHTMLAttributes<HTMLInputElement>;
  name: string;
  disabled?: boolean;
  label: string;
}

const AppointmentRadio = ({
  id,
  name,
  disabled,
  radioProps,
  label,
}: IAppointmentRadio) => {
  return (
    <div className="appointment_radio_container">
      <div className={`appointment_radio ${disabled && "disabled"}`}>
        <input
          aria-describedby={id}
          id={id}
          disabled={disabled}
          type="radio"
          name={name}
          className="appointment_radio_input"
          {...radioProps}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

export default AppointmentRadio;
