import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import "./HistoryTableStyles.scss";

interface DataType {
  key: string;
  date: string;
  time: string;
  course: string;
  teacher: string;
  duration: string;
}

const data: DataType[] = [
  {
    key: "1",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "2",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "3",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "4",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "5",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "6",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "7",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "8",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "9",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "10",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "11",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
  {
    key: "12",
    date: "24th Feb, 2023",
    time: "10:30 am",
    course: "Chemistry",
    teacher: "Mr. Adekoya",
    duration: "2 hours",
  },
];

const columns: ColumnsType<DataType> = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Course",
    dataIndex: "course",
    key: "course",
  },
  {
    title: "Teacher",
    dataIndex: "teacher",
    key: "teacher",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
];

const HistoryTable = () => {
  return (
    <div className="history_table_container">
      <Table columns={columns} dataSource={data} bordered={true} />
    </div>
  );
};

export default HistoryTable;
