import Header from "../../../../../components/Header/Header";
import BackButton from "../../../components/BackButton/BackButton";
import { AllRouteConstants } from "../../../../../router/routes";
import AccountInfoCard from "../../../components/AccountInfoCard/AccountInfoCard";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import "../../SettingsStyles.scss";
import authService from "../../../../../services/authenticationService/AuthService";
import useApi from "../../../../../hooks/useApi";
import { useAuthActions } from "../../../../../hooks/useReduxActions";

const requestTOTPCode = () => authService.getTOTP();
const disableTOTPVerification = () => authService.disableTOTP();

export const Enable2FA = () => {
  const navigate = useNavigate();

  const { profile_data } = useAppSelector((state) => state.authReducer);

  const getTOTPCodeRequest = useApi<{ SecretCode: string }, string>(
    requestTOTPCode
  );

  const disableTOTPVerificationRequest = useApi<undefined, undefined>(
    disableTOTPVerification
  );

  const getTOTPCode = async () => {
    try {
      const response = await getTOTPCodeRequest.request();
      navigate(
        AllRouteConstants.main.settings.account.enable2Fa.twofaVerification,
        {
          state: { secretCode: response?.SecretCode },
          replace: true,
        }
      );
    } catch (error) {}
  };

  const { updateProfile } = useAuthActions();

  const handleSoftwareAuthenticator = async () => {
    if (profile_data?.activeMfa?.includes("SOFTWARE_TOKEN_MFA")) {
      const response = await disableTOTPVerificationRequest.request();

      if (response) {
        updateProfile({ key: "activeMfa", data: [] });
      }
    } else {
      getTOTPCode();
    }
  };

  return (
    <div className="settings_account_container">
      <section>
        <BackButton route={AllRouteConstants.main.settings.account.index} />
        <Header message="Two Factor Authentication" />
        <p className="enable_2fa_message">
          Enhance the security of your account with 2FA
        </p>
        <div className="settings_account_inner_container_two">
          <AccountInfoCard
            title="Authenticator App (Recommended)"
            info="Protect your account"
            buttonContent={
              profile_data?.activeMfa?.includes("SOFTWARE_TOKEN_MFA")
                ? "Disable"
                : "Enable"
            }
            buttonProps={{
              // disable: profile_data?.activeMfa.includes("SOFTWARE_TOKEN_MFA"),
              loading:
                getTOTPCodeRequest.loading ||
                disableTOTPVerificationRequest.loading,
            }}
            onClick={handleSoftwareAuthenticator}
          />

          <AccountInfoCard
            title="Phone Number Verification"
            info="Protect your account"
            buttonContent={
              profile_data.phone_number_verified === "true"
                ? "Disable"
                : "Enable"
            }
            buttonProps={{
              disable: true,
            }}
            onClick={() =>
              navigate(
                AllRouteConstants.main.settings.account.enable2Fa
                  .phoneVerification
              )
            }
          />
        </div>
      </section>
    </div>
  );
};
