import produce from "immer";
import { ActionConstants } from "./action-constants";
import { AppointmentActionTypes } from "./action-types";
import { Appointment, AppointmentStatus } from "../../interfaces/Appointment";
import { Profile_Interface } from "../../interfaces/User";
import { EventInput } from "@fullcalendar/core";

const getColor = (status: AppointmentStatus) => {
  switch (status) {
    case "Pending":
      return "rgba(250, 175, 229, 0.5)";

    case "Accepted":
      return "rgba(84, 46, 238, 0.15)";

    case "Completed":
      return "rgba(235, 242, 236, 0.7)";

    default:
      return "rgba(250, 175, 229, 0.5)";
  }
};

const convertAppointment = (appointment: Appointment): EventInput => {
  return {
    id: appointment.appointmentId,
    title: appointment.course,
    start: new Date(appointment.startTime),
    end: new Date(appointment.endTime),
    backgroundColor: getColor(appointment.status),
    borderColor: getColor(appointment.status),
    allDay: false,
    extendedProps: appointment,
  };
};

interface initialStateInterface {
  data: Appointment[];
  currentAppointments: EventInput[];
  loadingCurrentAppointments: boolean;
  pendingAppointmentRequest: {
    appointment: Appointment;
    createdBy: Profile_Interface;
  } | null;
  initialized: boolean;
}

const initialState: initialStateInterface = {
  data: [],
  currentAppointments: [],
  loadingCurrentAppointments: false,
  initialized: false,
  pendingAppointmentRequest: null,
};

export const appointmentReducer = (
  state: initialStateInterface = initialState,
  Action: AppointmentActionTypes
): initialStateInterface => {
  return produce(state, (appointmentState) => {
    switch (Action.type) {
      case ActionConstants.INITIALIZE:
        appointmentState.data = Action.payload;
        if (Action.payload.length > 0) appointmentState.initialized = true;
        return appointmentState;

      case ActionConstants.ADD:
        appointmentState.currentAppointments = [
          convertAppointment(Action.payload),
          ...appointmentState.currentAppointments,
        ];
        return appointmentState;

      case ActionConstants.EDIT:
        appointmentState.currentAppointments =
          appointmentState.currentAppointments.map((appointment) => {
            if (appointment.id === Action.payload.appointmentId) {
              let oldAppointment = appointment.extendedProps as Appointment;
              oldAppointment = { ...oldAppointment, ...Action.payload.data };
              return convertAppointment(oldAppointment);
            }

            return appointment;
          });
        return appointmentState;

      case ActionConstants.REMOVE:
        appointmentState.currentAppointments =
          appointmentState.currentAppointments.filter(
            (item) => item.id !== Action.payload
          );
        return appointmentState;

      case ActionConstants.APPOINTMENT_REQUEST:
        appointmentState.pendingAppointmentRequest = Action.payload;
        return appointmentState;

      case ActionConstants.ACCEPT_APPOINTMENT:
        appointmentState.currentAppointments =
          appointmentState.currentAppointments.map((appointment) => {
            if (appointment.id === Action.payload.appointmentId) {
              const newAppointment = {
                ...appointment.extendedProps,
                acceptedBy: Action.payload.acceptedBy,
                status: "Accepted",
              };
              return convertAppointment(newAppointment as Appointment);
            }

            return appointment;
          });

        return appointmentState;

      case ActionConstants.START_SESSION:
        appointmentState.currentAppointments =
          appointmentState.currentAppointments.map((appointment) => {
            if (appointment.id === Action.payload.appointmentId) {
              const newAppointment = {
                ...appointment.extendedProps,
                active: Action.payload.active,
              };
              return convertAppointment(newAppointment as Appointment);
            }

            return appointment;
          });
        return appointmentState;

      case ActionConstants.SET_CURRENT:
        appointmentState.currentAppointments =
          Action.payload.map(convertAppointment);
        return appointmentState;

      case ActionConstants.SET_LOADING_CUURENT:
        appointmentState.loadingCurrentAppointments = Action.payload;
        return appointmentState;

      default:
        return appointmentState;
    }
  });
};
