// Package Imports
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import "react-calendar/dist/Calendar.css";
import "./AppointmentCalendarStyles.scss";

import { dateToStringAlgorithm } from "../../../../../utils/convertDateToString";

import {
  formatShortWeekday,
  isDateInPast,
  isWeekendDay,
} from "../../../utils/appointmentDateHandler";
import { ICreateAppointment } from "../../../../../interfaces/CreateAppointment";
import SelectDemandTypeForm from "../SelectDemandTypeForm";
import NoAvailableSlots from "../NoAvailableSlots";
import SetAppointmentTime from "../SetAppointmentTime";

const CreateAppointmentDrawerInitialPage = (props: ICreateAppointment) => {
  const {
    appointmentForm,
    currentFormStep,
    setCurrentFormStep,
    initialDate,
    drawerToggler,
  } = props;
  const { form } = appointmentForm;

  const [date, setDate] = useState(new Date());

  const [formattedDate, setFormattedDate] = useState<string>("");

  const renderedForm = () => {
    switch (currentFormStep) {
      case 1:
        return (
          <SelectDemandTypeForm
            appointmentForm={appointmentForm}
            currentFormStep={currentFormStep}
            setCurrentFormStep={setCurrentFormStep}
          />
        );
      case 2:
        return (
          <SetAppointmentTime
            appointmentForm={appointmentForm}
            currentFormStep={currentFormStep}
            setCurrentFormStep={setCurrentFormStep}
            date={date}
          />
        );
    }
  };

  // Formats the Date to a string anytime a date is selected on the calendar
  useEffect(() => {
    setFormattedDate(dateToStringAlgorithm(date));
  }, [date]);

  useEffect(() => {
    if (form.isOnDemand) {
      setDate(new Date());
    }
  }, [form.isOnDemand]);

  useEffect(() => {
    if (initialDate) {
      setDate(initialDate);
    }
  }, [initialDate]);

  useEffect(() => {
    if (typeof drawerToggler === "boolean" && !drawerToggler) {
      setDate(new Date());
    }
  }, [drawerToggler]);

  return (
    <div className="create_appointment_drawer_body">
      <div className="create_appointment_drawer_body_container">
        {/* This is the Appointment Calendar */}
        <div className="create_appointment_drawer_body_calendar">
          <Calendar
            calendarType="ISO 8601"
            formatShortWeekday={formatShortWeekday}
            tileClassName={({ date }) =>
              isWeekendDay(date)
                ? `weekend ${isDateInPast(date) ? "weekend_past" : ""}`
                : isDateInPast(date)
                ? "react_calendar_tile_disabled"
                : ""
            }
            showNeighboringMonth={false}
            onChange={(date: any) => setDate(date as Date)}
            value={date}
            view={"month"}
            nextLabel={<AiOutlineRight />}
            prevLabel={<AiOutlineLeft />}
            className={`${form.isOnDemand ? "disable-calendar" : ""}`}
          />
        </div>

        <div className="create_appointment_drawer_body_date_information">
          <h2 className="create_appointment_drawer_body_date_information_date">
            {formattedDate}
          </h2>

          {isDateInPast(date) ? <NoAvailableSlots /> : <>{renderedForm()}</>}
        </div>
      </div>
    </div>
  );
};

export default CreateAppointmentDrawerInitialPage;
