import React from "react";
import DashboardCard from "../components/DashboardCard/DashboardCard";
import DashboardCarousel from "../components/DashboardCarousel/DashboardCarousel";
import lefticoncourse from "../../../assets/icons/dashboardCardIconOne.svg";
import righticoncourse from "../../../assets/icons/dashboardCardIconTwo.svg";
import lefticonprogress from "../../../assets/icons/dashboardCardIconThree.svg";
import righticonnumbercourse from "../../../assets/icons/dashboardCardIconFour.svg";
import "../DashboardHomeStyles.scss";
import DashboardLeftHeader from "../components/DashboardLeftHeaders/DashboardLeftHeader";
import Header from "../../../components/Header/Header";
import WeeklyPerformance from "../components/WeeklyPerformance/WeeklyPerformance";
import DashboardTable from "../components/DashboardTable/DashboardTable";
import TeacherQualification from "../components/TeacherQualification/TeacherQualification";
import LearningActivity from "../components/LearningActivity/LearningActivity";

const DashboardHomeMobile = () => {
  return (
    <div className="dashboard_home_mobile">
      <DashboardCarousel
        steps={[
          <DashboardCard
            title="Available Courses"
            value={32}
            lefticon={lefticoncourse}
            righticon={righticoncourse}
            color="#EBF2EC"
            customCardClass="dashboard_carousel_card"
          />,
          <DashboardCard
            title="Available Courses"
            value={32}
            lefticon={lefticonprogress}
            righticon={righticoncourse}
            color="#EAE2EC"
            customCardClass="dashboard_carousel_card"
          />,
          <DashboardCard
            title="Available Courses"
            value={32}
            lefticon={lefticoncourse}
            righticon={righticonnumbercourse}
            color="#ECECEE"
            customCardClass="dashboard_carousel_card"
          />,
        ]}
      />

      <WeeklyPerformance />
      <DashboardTable />
      <TeacherQualification />
      <LearningActivity />
    </div>
  );
};

export default DashboardHomeMobile;
