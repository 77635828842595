import React, { useRef } from 'react';
import './CopyWithToolTipStyles.scss'
import { AiOutlineLink } from 'react-icons/ai'

const CopyWithToolTip = ({ copyItem, message }: { copyItem: string, message?: string }) => {

    const tooltipRef = useRef<HTMLDivElement>(null);

    const myFunction = () => {
        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.setAttribute('value', copyItem);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(tempInput);

        const tooltip = tooltipRef.current;
        if (tooltip) {
            tooltip.classList.add('active');
            setTimeout(() => {
                tooltip.classList.remove('active');
            }, 1500);
        }
    };
    return (
        <div className="container" onClick={myFunction}>
            <AiOutlineLink /><div id="tooltip" ref={tooltipRef} className="tooltip">
                {message ?? 'Id Copied to Clipboard'}
            </div>
        </div>
    )
}

export default CopyWithToolTip