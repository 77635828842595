import { useState } from "react";
import Header from "../../../../../components/Header/Header";
import Input from "../../../../../components/Input/Input";
import { useForm } from "../../../../../hooks/useForm";
import { emptyValidator } from "../../../../../utils/validators/emptyValidator";
import { ChangePhoneNumberInput } from "../../../../../interfaces/settingsInterfaces/SettingsAcountInputs";
import Button from "../../../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import { AllRouteConstants } from "../../../../../router/routes";

export const ChangePhoneNumber = () => {
  const changePhoneForm = useForm<ChangePhoneNumberInput>(
    { phoneNumber: "" },
    { phoneNumber: emptyValidator }
  );

  /**
   * Adds whatever we input into our form handler for the Phone Number
   * @param e
   */
  const handleChangePhoneNumber: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    changePhoneForm.onChange("phoneNumber", e.target.value);
  };

  return (
    <div className="settings_account_container">
      <div style={{ marginLeft: "35px" }}>
        <BackButton route={AllRouteConstants.main.settings.account.index} />
        <Header message="Change Phone Number" size="md" />
        <p className="account_change_phone_text">
          Please input the new phone number, you would like to use.
        </p>
        <form>
          <div className="account_change_phone_container">
            <Input
              id="phoneNumber"
              label="New Phone Number"
              inputClassName="account_change_phone_input"
              error={changePhoneForm.formErrors.phoneNumber}
              inputProps={{
                name: "phone_number",
                placeholder: "Enter your phone",
                value: changePhoneForm.form.phoneNumber,
                onChange: handleChangePhoneNumber,
              }}
            />
          </div>
          <div className="account_form_error_container">
            {/* Error Handler */}

            {/* <AuthError error={changePasswordRequest.error?.message} /> */}

            <Button
              // loading={changePasswordRequest.loading}
              label="Verify"
              variant="primary"
              width="fit-content"
              disable={!changePhoneForm.form.phoneNumber}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
