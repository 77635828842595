import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { AppointmentHome } from "../pages";

const AppointmentRoutes = () => {
  return (
    <>
      <Route
        path={AllRouteConstants.main.appointment.index}
        element={<AppointmentHome />}
      />
      {/* <Route
        path={AllRouteConstants.main.appointment.edit}
        element={<AppointmentHome />}
      /> */}
    </>
  );
};

export default AppointmentRoutes;
