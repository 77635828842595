import produce from "immer";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

interface initialStateInterface {
  currentScreen: "mobile" | "desktop" | "tablet";
}

const initialState = {
  currentScreen: "desktop",
} as initialStateInterface;

export const screenStateReducer = (
  state: initialStateInterface = initialState,
  Action: AuthActionTypes
): initialStateInterface => {
  return produce(state, (screen_state) => {
    switch (Action.type) {
      case ActionConstants.SET_CURRENT_SCREEN_STATE:
        screen_state.currentScreen = Action.payload;
        return screen_state;

      default:
        return screen_state;
    }
  });
};
