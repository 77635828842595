import "./styles.scss";
import PopModal from "../../../../layouts/ModelLayout/ModalLayout";
import { IPayStatusModalProps } from "../../../../interfaces/Checkout";
import React from "react";

import {
  errorAvatar,
  nuetralAvatar,
  successAvatar,
} from "../../../../assets/images";
import Button from "../../../../components/Button/Button";

const getAvatar = (status: string | null) => {
  switch (status) {
    case "successful":
      return successAvatar;

    case "cancelled":
    case "failed":
      return errorAvatar;

    default:
      return nuetralAvatar;
  }
};

const getMessage = (status: string | null) => {
  switch (status) {
    case "successful":
      return "Congratulations! You have been able to successfully make payments";

    case "cancelled":
    case "failed":
      return "It appears there has been some errors following up with the transaction, please try again later.";

    default:
      return "You transaction is yet to be confirmed, kindly wait for a few minutes.";
  }
};

const PayStatusModal: React.FC<IPayStatusModalProps> = ({
  handleClose,
  status,
}) => {
  return (
    <PopModal onClose={() => {}} modalToggler={Boolean(status)}>
      <div className="mb-pay-status-modal">
        <div className="img-container">
          <img src={getAvatar(status)} alt="status_avatar" />
        </div>

        <p>{getMessage(status)}</p>

        <div className="actions">
          <Button
            label="Continue"
            onClick={handleClose}
            variant="primary"
            width="fit-content"
          />
        </div>
      </div>
    </PopModal>
  );
};

export default PayStatusModal;
