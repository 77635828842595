import {
  TableBodyContainer,
  TableBodyRow,
  TableBodyRowChild,
  TableHead,
  TableHeadContainer,
  TableLayout,
} from "../../../../components/TableLayout/TableLayout";
import "./PaymentHistoryTableStyles.scss";
import { PaymentHistory } from "../../../../interfaces/Checkout";
import { formatDate } from "../../../../utils/convertDateToString";
import { cardImgs } from "../../../../assets/images";

interface IPaymentHistoryTable {
  tableData: PaymentHistory[];
}

const tableHead = ["Date", "Course Name", "Amount", "Status", "Payment Method"];

const getCardImg = (type: string) => {
  type = type.toLowerCase();

  switch (type) {
    case "mastercard":
      return cardImgs.mastercard;

    case "visa":
      return cardImgs.visacard;

    default:
      return cardImgs.unknowncard;
  }
};

const PaymentHistoryTable = ({ tableData }: IPaymentHistoryTable) => {
  return (
    <div>
      <TableLayout className="ml-payment-history-table">
        <TableHeadContainer>
          <>
            {tableHead.map((head) => (
              <TableHead label={head} key={head} />
            ))}
          </>
        </TableHeadContainer>
        <TableBodyContainer>
          <>
            {tableData.map((item) => (
              <TableBodyRow
                className="payment_history_table_body"
                key={`payment-history-${item.historyId}`}
              >
                <TableBodyRowChild>
                  {formatDate(item.datePaid)}
                </TableBodyRowChild>
                <TableBodyRowChild>{item.courseTitle}</TableBodyRowChild>
                <TableBodyRowChild>
                  NGN {item.amount.toFixed(2)}
                </TableBodyRowChild>
                <TableBodyRowChild>{item.status}</TableBodyRowChild>
                <TableBodyRowChild className="card_type">
                  <span>{item.last_4digits}</span>{" "}
                  <img
                    className="card_type__img"
                    src={getCardImg(item.type)}
                    alt={item.type}
                  />
                </TableBodyRowChild>
              </TableBodyRow>
            ))}
          </>
        </TableBodyContainer>
      </TableLayout>
    </div>
  );
};

export default PaymentHistoryTable;
