import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { WhiteBoardMountProps } from "../../../interfaces/Call";
import WhiteBoardMount from "../components/WiteboardMount";

export const WhiteBoard: React.FC = () => {
  const navigate = useNavigate();

  const { profile_data } = useAppSelector((state) => state.authReducer);
  const [tokenData, setTokenData] = useState<WhiteBoardMountProps>({
    roomToken: "",
    uid: "",
    uuid: "",
  });

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const token = search.get("token");
    const room = search.get("room");

    if (!token || !room) {
      navigate(AllRouteConstants.main.dashboard);
    } else {
      setTokenData({ roomToken: token, uuid: room, uid: profile_data.id });
    }
  }, []);

  const showWhiteBoard = useMemo(() => {
    if (tokenData.roomToken && tokenData.uid && tokenData.uuid) {
      return { ...tokenData };
    }

    return null;
  }, [tokenData]);

  useEffect(() => {
    setTokenData((prev) => ({ ...prev, uid: profile_data.id }));
  }, [profile_data.id]);

  return (
    <div className="ml-whiteboard">
      {showWhiteBoard && <WhiteBoardMount {...showWhiteBoard} />}
    </div>
  );
};
