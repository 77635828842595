import { useRef } from "react";
import "./modal.scss";
import { CSSTransition } from "react-transition-group";

function PopModal({
  children,
  onClose,
  modalToggler,
  otherClassName = undefined,
}: {
  children: JSX.Element;
  onClose: () => void;
  modalToggler: boolean;
  otherClassName?: string;
}) {
  const overlayRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={overlayRef}
      in={modalToggler}
      classNames={`modal_container`}
      unmountOnExit
      timeout={500}
    >
      <div
        ref={overlayRef}
        onClick={onClose}
        className={`modal_container ${otherClassName}`}
      >
        <div onClick={(e) => e.stopPropagation()} className={`modal_card`}>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

export default PopModal;
