import { useLocation, useNavigate } from "react-router-dom";
import platformlogo from "../../../assets/platform-logo.svg";
import Button from "../../../components/Button/Button";
import useApi from "../../../hooks/useApi";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import OtpInput from "../../../components/OtpInput/OtpInput";
import authService from "../../../services/authenticationService/AuthService";
import { useForm } from "../../../hooks/useForm";
import { VerifySignInInput } from "../../../interfaces/VerifySignInInput";
import { tfaValidator } from "../../../utils/validators/tfaValidator";
import { AllRouteConstants } from "../../../router/routes";
import { useEffect } from "react";
import AuthError from "../components/AuthError/AuthError";
import { useAuthActions } from "../../../hooks/useReduxActions";

export const VerifySignIn = () => {
  const verifyForm = useForm<VerifySignInInput>(
    { ChallengeName: "", code: "", email: "", Session: "" },
    { code: tfaValidator }
  );

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      verifyForm.onChange("ChallengeName", state.ChallengeName);
      verifyForm.onChange("Session", state.Session);
      verifyForm.onChange("email", state.email);
    }
  }, [state]);

  const verifyApiRequest = useApi<any, VerifySignInInput>(
    authService.verifySignIn
  );

  const { signIn } = useAuthActions();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    verifyForm.resetFormErrors();
    verifyApiRequest.reset();

    const valid = verifyForm.validate();

    if (valid) {
      try {
        const result = await verifyApiRequest.request(verifyForm.form, false);

        if (result?.token) {
          signIn({
            token: result.token,
            access_token: result.access_token,
            refresh_token: result.refresh_token,
          });

          navigate(AllRouteConstants.main.dashboard);
        }
      } catch (error) {}
    }
  };

  return (
    <div className="auth_container animate__animated animate__fadeIn">
      <img alt="mobile" src={platformlogo} />

      <AuthHeader message="Two Factor Verification" />

      <p className="forgot_password_text">
        Please Enter your Time based/SMS code to proceed.
      </p>

      <form onSubmit={handleSubmit}>
        <OtpInput
          value={verifyForm.form.code}
          valueLength={6}
          onChange={(code) => verifyForm.onChange("code", code)}
        />

        <Button
          label="Verify"
          disable={verifyForm.form.code.length !== 6}
          loading={verifyApiRequest.loading}
          variant="primary"
        />

        <AuthError error={verifyApiRequest.error} />
      </form>
    </div>
  );
};
