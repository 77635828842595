import { isPast, isToday, isWeekend } from "date-fns";

// Checks if a date is in the past
export function isDateInPast(selectedDate: number | Date) {
  return isPast(selectedDate) && !isToday(selectedDate);
}
// Checks if it is weekened
export function isWeekendDay(selectedDate: number | Date) {
  return isWeekend(selectedDate);
}
// Formats the weekdays
export const formatShortWeekday = (locale: any, value: any) => {
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
  return weekdays[value.getDay()];
};
