import { Outlet } from "react-router-dom";
import DashboardNavigation from "../DashboardNavigation/DashboardNavigation";
import "../DashboardLayoutStyles.scss";

const DashboardMobile = () => {
  return (
    <div className="dashboard_layout_mobile">
      <DashboardNavigation />
      <div className="dashboard_layout_outlet_container">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardMobile;
