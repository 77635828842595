import produce from "immer";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

interface initialStateInterface {
  navigationItem: JSX.Element | string;
}

const initialState = {
  navigationItem: "",
} as initialStateInterface;

export const navigationItemReducer = (
  state: initialStateInterface = initialState,
  Action: AuthActionTypes
): initialStateInterface => {
  return produce(state, (screen_state) => {
    switch (Action.type) {
      case ActionConstants.SET_MIDDLE_NAVIGATION_ITEM:
        screen_state.navigationItem = Action.payload;
        return screen_state;

      default:
        return screen_state;
    }
  });
};
