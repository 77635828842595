import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import "./AccountinfoCardStyles.scss";
import { AiOutlineRight } from "react-icons/ai";

interface AccountInfoCardProps {
  title: string;
  info: string | number;
  buttonContent?: string;
  onClick?: () => void;
  buttonProps?:any
}

const AccountInfoCard: React.FC<AccountInfoCardProps> = ({
  title,
  info,
  buttonContent,
  onClick,
  buttonProps
  
}) => {
  return (
    <div className="account_information_card">
      <div className="account_information_inner_container">
        <h2>{title}</h2>
        <p>{info}</p>
      </div>
      {buttonContent && (
        <div className="account_information_card_button_container">
          <Button
            label={buttonContent}
            variant="primary"
            onClick={onClick}
            size="md"
            width="100%"
            {...buttonProps}
          />
        </div>
      )}
    </div>
  );
};

export default AccountInfoCard;

export const AccountInfoCard2 = ({
  message,
  route,
}: {
  message: string;
  route: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="account_information_card account_information_card_2"
      onClick={() => navigate(route)}
    >
      <div className="account_information_inner_container">
        <h2 className="account_information_header">{message}</h2>
      </div>
      <AiOutlineRight />
    </div>
  );
};
