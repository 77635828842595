import { Link, useNavigate } from "react-router-dom";
import platformlogo from "../../../assets/platform-logo.svg";
import { AllRouteConstants } from "../../../router/routes";
import Button from "../../../components/Button/Button";
import "../AuthStyles.scss";
import { AuthHeader } from "../components/AuthHeader/AuthHeader";
import Input from "../../../components/Input/Input";
import AuthError from "../components/AuthError/AuthError";
import { useForm } from "../../../hooks/useForm";
import { emailValidator } from "../../../utils/validators/emailValidator";
import authService from "../../../services/authenticationService/AuthService";
import useApi from "../../../hooks/useApi";
import { User } from "../../../interfaces/User";

export const ForgotPassword = () => {
  const forgotPasswordForm = useForm<any>(
    { email: "" },
    { email: emailValidator }
  );
  const navigate = useNavigate();
  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    forgotPasswordForm.onChange("email", e.target.value);
  };
  const resetPasswordRequest = useApi<User, string>(
    authService.requestpasswordreset
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    forgotPasswordForm.resetFormErrors();
    resetPasswordRequest.reset();

    const valid = forgotPasswordForm.validate();

    if (valid) {
      try {
        if (!forgotPasswordForm.form.email) {
          return;
        }
        const user = await resetPasswordRequest.request(
          forgotPasswordForm.form.email,
          false
        );
        if (user) {
          navigate(AllRouteConstants.auth.resetPassword, {
            state: { userEmail: forgotPasswordForm.form.email },
            replace: true,
          });
        }
      } catch (error) {}
    }
  };

  return (
    <section className="auth_container animate__animated animate__fadeIn">
      <img alt="mobile" src={platformlogo} />
      <AuthHeader message="Forgot Password" />

      <p className="forgot_password_text">
        Enter the email registered with your account and we’ll send an email
        with instructions to reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          id="email"
          label="Email Address"
          error={forgotPasswordForm.formErrors.email}
          inputProps={{
            placeholder: "Enter your email address",
            value: forgotPasswordForm.form.email,
            onChange: handleChangeEmail,
          }}
        />

        <p>
          <Link
            className="forgot_password"
            style={{ fontWeight: 500 }}
            to={
              resetPasswordRequest.loading ? "#" : AllRouteConstants.auth.login
            }
          >
            Go Back to Login?
          </Link>
        </p>
        {/* Error */}
        <AuthError error={resetPasswordRequest.error} />

        <Button
          variant="primary"
          label={"Continue"}
          loading={resetPasswordRequest.loading}
        />
      </form>
    </section>
  );
};
