import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  BsFullscreen,
  BsFullscreenExit,
  BsMic,
  BsMicMute,
} from "react-icons/bs";
import { CallUser } from "../../../../interfaces/Call";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface VideoCallProps {
  adminUser: CallUser;
}

const VideoCall: React.FC<VideoCallProps> = ({ adminUser }) => {
  const { isScreenShared } = useAppSelector((state) => state.callReducer);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    console.log("[adminUser]", adminUser);
    if (!adminUser?.videoTrack) return;

    const run = async () => {
      adminUser.videoTrack.play("ml-video-call");
    };

    run();
  }, [adminUser]);

  const toggleFullScreen = () => {
    const container = document.getElementById("ml-video-call");

    if (!document.fullscreenElement) {
      container?.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  return (
    <div
      id="ml-video-call"
      className={`ml-video-call ${isScreenShared ? "ml-screen-video" : ""}`}
      onDoubleClick={toggleFullScreen}
    >
      <div className="ml_call_home-left_sidebar_body_call_speaker_info">
        <button className="speaker_mic">
          {adminUser?.audio ? <BsMic /> : <BsMicMute />}
        </button>

        <div>
          {adminUser?.name && <span>{adminUser.name}</span>}

          <button className="speaker_mic" onClick={toggleFullScreen}>
            {isFullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
