import { APPOINTMENT_SOCKET_URL } from "../../config/env";
import WebSocket from "isomorphic-ws";
import { User } from "../../interfaces/User";

let WSService: WebSocketService | null = null;

class WebSocketService {
  private websocket: WebSocket | null;
  private messageListeners: {
    type: string;
    listener: (data: any) => void;
  }[];
  private isOpen: boolean;

  constructor() {
    this.websocket = null;
    this.messageListeners = [];
    this.isOpen = false;
  }

  /**
   *  Set up WebSocket connection for a new user and
   *  basic listeners to handle events
   */
  initSocket = (user: User) => {
    console.log("Init soccet");
    this.websocket = new WebSocket(
      `${APPOINTMENT_SOCKET_URL}?token=${user.token}&access_token=${user.access_token}`
    );
    this.websocket.onopen = this.onConnOpen;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onclose = this.onConnClose;
  };

  closeSocket = () => {
    this.websocket?.close();
  };

  /**
   *  Show connection status to us in the log
   */
  onConnOpen = () => {
    this.isOpen = true;
    console.log("Websocket connected!");
  };

  /**
   *  Log lost connection for now
   */
  onConnClose = () => {
    this.isOpen = false;
    console.log("Websocket closed!");
  };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   *  message {
   *    room,
   *    type,
   *    msg,
   *    username,
   *    for
   *  }
   */
  sendMessage = (routeKey: string, message: any) => {
    if (this.websocket && this.isOpen) {
      console.log("Send Message", message);
      this.websocket.send(
        JSON.stringify({
          action: routeKey,
          data: JSON.stringify({ ...message, action: routeKey }),
        })
      );
    } else {
      console.log(`Websocket connection not found!!`);
    }
  };

  /**
   *  Used by application to register different listeners for
   *  different message types
   *  @param room Room name
   *  @param type Message type ['all', 'pm', 'userlist', 'useradd']
   *  @param listener Function to handle message type
   */
  addMessageListener = (type: string, listener: (data: any) => void) => {
    console.log("[ADD LISTENER]", type);

    const exist = this.messageListeners.filter((item) => item.type === type);

    if (exist.length === 0)
      this.messageListeners.push({
        type,
        listener,
      });
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body to the appropriate
   * registered handler
   * @param data Message body received from WebSocket
   */
  onMessage = (data: any) => {
    console.log("onMessage", data);

    if (data) {
      const message = JSON.parse(data.data);

      console.log("Message", message);

      const typeListener = this.messageListeners.find(
        (listener) => listener.type === message.type
      );

      if (typeListener) {
        typeListener.listener(message.message);
      } else {
        console.log("No handler found for message type");
      }
    }
  };

  static initWSService() {
    if (!WSService) {
      WSService = new WebSocketService();
      // WSService.initSocket(user);
      return WSService;
    }

    return WSService;
  }
}

export const getWSService = WebSocketService.initWSService;
