import React from "react";

const AttendedCard = () => {
  return (
    <div className="card">
      <p className="card_text">7</p>
      <p className="card_text">Attended classes</p>
    </div>
  );
};

export default AttendedCard;
