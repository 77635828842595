import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { CallHome, WhiteBoard } from "../pages";

const CallRoutes = () => {
  return (
    <>
      <Route path={AllRouteConstants.main.call.index} element={<CallHome />} />
      <Route
        path={AllRouteConstants.main.call.whiteboard}
        element={<WhiteBoard />}
      />
    </>
  );
};

export default CallRoutes;
