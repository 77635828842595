import produce from "immer";
import { ActionConstants } from "./action-constants";
import { AppointmentActionTypes } from "./action-types";
import { CallSession, CallUser } from "../../interfaces/Call";
import { hasMessage } from "../../utils/hasMessage";
import { Appointment } from "../../interfaces/Appointment";

export interface CallMessage {
  senderId: string;
  message: string;
}

interface initialStateInterface {
  data: CallSession | null;
  users: CallUser[];
  start: boolean;
  isScreenShared: boolean;
  messages: CallMessage[];
  selectedAppointment: Appointment | null;
}

const initialState: initialStateInterface = {
  data: null,
  users: [],
  start: false,
  isScreenShared: false,
  messages: [],
  selectedAppointment: null,
};

export const callReducer = (
  state: initialStateInterface = initialState,
  Action: AppointmentActionTypes
): initialStateInterface => {
  return produce(state, (callState) => {
    switch (Action.type) {
      case ActionConstants.SET_CURRENT_SESSION:
        callState.data = Action.payload;
        return callState;

      case ActionConstants.ADD_CALL_USER:
        callState.users = [Action.payload, ...callState.users];
        return callState;

      case ActionConstants.START_CALL:
        callState.start = Action.payload;
        return callState;

      case ActionConstants.CLEAR_CALL_USERS:
        callState.users = [];
        return callState;

      case ActionConstants.UPDATE_CALL_USERS:
        callState.users = Action.payload;
        return callState;

      case ActionConstants.SHARE_SCREEN:
        callState.isScreenShared = Action.payload;
        return callState;

      case ActionConstants.Add_MESSAGE:
        if (!hasMessage(callState.messages, Action.payload)) {
          callState.messages = [...callState.messages, Action.payload];
        }
        return callState;

      case ActionConstants.SELECT_APPOINTMENT:
        callState.selectedAppointment = Action.payload;
        return callState;

      default:
        return callState;
    }
  });
};
