import React from "react";

interface IPaymentMethodType {
  image: string;
  accountNumberLastFourDigits: string;
  expDate: string;
}

const PaymentMethodType = (props: IPaymentMethodType) => {
  const { image, accountNumberLastFourDigits, expDate } = props;
  return (
    <div className="mb_settings_payment_payment_type">
      <div className="mb_settings_payment_payment_type_container">
        <div className="mb_settings_payment_payment_type_left">
          <div className="mb_settings_payment_payment_type_left_img_container">
            <img src={image} alt="card" />
          </div>
          <p>xxxx xxxx xxxx {accountNumberLastFourDigits}</p>
          <p>Expires on {expDate}</p>
        </div>
        <button className="mb_settings_payment_payment_type_right">
          Remove
        </button>
      </div>
    </div>
  );
};

export default PaymentMethodType;
