import React, { useEffect, useState } from "react";
import "./styles.scss";
import imgl from "../../../../assets/images/femaleAvatarVerification.png";
import Button from "../../../../components/Button/Button";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CallUser } from "../../../../interfaces/Call";
import { IUserCallActions } from "../../interfaces/IUserCallActions";

interface SelectMediaProps {
  init: () => Promise<void>;
  localVideoTrack: React.MutableRefObject<ICameraVideoTrack | null>;
  localAudioTrack: React.MutableRefObject<IMicrophoneAudioTrack | null>;
  action: (
    action: IUserCallActions,
    payload?: any,
    user?: CallUser
  ) => Promise<void>;
}

const SelectMedia: React.FC<SelectMediaProps> = ({
  init,
  localAudioTrack,
  localVideoTrack,
  action,
}) => {
  const [selectedMic, setSelectedMic] = useState<MediaDeviceInfo | null>(null);
  const [selectedSpeaker, setSelectedSpeaker] =
    useState<MediaDeviceInfo | null>(null);
  const [selectedCam, setSelectedCam] = useState<MediaDeviceInfo | null>(null);

  const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([]);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  const [loading, setLoading] = useState(false);

  const run = async () => {
    const microphones = await AgoraRTC.getMicrophones();
    const micRes: MediaDeviceInfo[] = [];

    microphones.forEach((mic) => {
      const exist = micRes.filter((item) => item.groupId === mic.groupId);

      if (exist.length === 0) {
        micRes.push(mic);
      }

      if (mic.deviceId === "default") {
        setSelectedMic(mic);
      }
    });
    setMics(micRes);

    const speakers = await AgoraRTC.getPlaybackDevices();
    const speakerRes: MediaDeviceInfo[] = [];

    speakers.forEach((speaker) => {
      const exist = speakerRes.filter(
        (item) => item.groupId === speaker.groupId
      );

      if (exist.length === 0) {
        speakerRes.push(speaker);
      }

      if (speaker.deviceId === "default") {
        setSelectedSpeaker(speaker);
      }
    });
    setSpeakers(speakerRes);

    const cameras = await AgoraRTC.getCameras();
    const camRes: MediaDeviceInfo[] = [];

    cameras.forEach((cam) => {
      const exist = camRes.filter((item) => item.groupId === cam.groupId);

      if (exist.length === 0) {
        camRes.push(cam);
      }

      if (cam.deviceId === "default") {
        setSelectedCam(cam);
      }
    });
    setCameras(camRes);
  };

  useEffect(() => {
    run();
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!selectedCam || !selectedMic || !selectedSpeaker) {
      return;
    }

    // if (selectedMic.groupId !== selectedSpeaker.groupId) {
    //   return;
    // }

    setLoading(true);

    localAudioTrack.current?.setDevice(selectedMic.deviceId);

    localVideoTrack.current?.setDevice(selectedCam.deviceId);

    await init();

    setLoading(false);
  };

  const handleCancel = () => action("leave");

  const { users } = useAppSelector((state) => state.callReducer);

  useEffect(() => {
    users.forEach((user) => {
      if (user.audio && user.audioTrack && selectedSpeaker) {
        console.log("[SET PLAYBACK DEVICE]", user.audioTrack, selectedSpeaker);

        user.audioTrack?.setPlaybackDevice(selectedSpeaker.deviceId);
      }
    });
  }, [users, selectedSpeaker]);

  return (
    <div className="ml-select-media">
      <form className="ml-select-media__content" onSubmit={handleSubmit}>
        <img src={imgl} alt="hero" className="hero" />

        <p className="p-1">
          You’re currently about to initiate a session, select preferred devices
          and click continue
        </p>

        <Dropdown
          id="local-mic"
          label="Microphone"
          error={""}
          dropdownClassName="select_media_form_input"
          labelClassName="select_media_form_label"
          options={mics.map((item) => ({
            value: item.deviceId,
            label: item.label,
          }))}
          dropdownProps={{
            name: "local-mic",
            placeholder: "Select Microphone Device",
            value: { value: selectedMic?.deviceId, label: selectedMic?.label },
            required: true,
            onChange: (val: { value: string; label: string }) => {
              const mic =
                mics.filter((item) => item.deviceId === val.value)[0] || null;
              setSelectedMic(mic);
            },
          }}
        />

        <div className="hor-gap"></div>

        <Dropdown
          id="local-speaker"
          label="Speaker"
          error={""}
          dropdownClassName="select_media_form_input"
          labelClassName="select_media_form_label"
          options={speakers.map((item) => ({
            value: item.deviceId,
            label: item.label,
          }))}
          dropdownProps={{
            name: "local-speaker",
            placeholder: "Select Speaker Device",
            value: {
              value: selectedSpeaker?.deviceId,
              label: selectedSpeaker?.label,
            },
            required: true,
            onChange: (val: { value: string; label: string }) => {
              const mic =
                speakers.filter((item) => item.deviceId === val.value)[0] ||
                null;
              setSelectedSpeaker(mic);
            },
          }}
        />

        <div className="hor-gap"></div>

        <Dropdown
          id="local-camera"
          label="Camera"
          error={""}
          dropdownClassName="select_media_form_input"
          labelClassName="select_media_form_label"
          options={cameras.map((item) => ({
            value: item.deviceId,
            label: item.label,
          }))}
          dropdownProps={{
            name: "local-camera",
            placeholder: "Select Camera Device",
            value: { value: selectedCam?.deviceId, label: selectedCam?.label },
            required: true,
            onChange: (val: { value: string; label: string }) => {
              const cam =
                cameras.filter((item) => item.deviceId === val.value)[0] ||
                null;
              setSelectedCam(cam);
            },
          }}
        />

        <div className="hor-gap"></div>

        <div className="btn-container">
          <Button
            label="Cancel"
            variant="secondary"
            type="button"
            loading={loading}
            width="fit-content"
            onClick={handleCancel}
          />

          <Button
            label="Continue"
            variant="primary"
            type="submit"
            loading={loading}
            width="fit-content"
          />
        </div>
      </form>
    </div>
  );
};

export default SelectMedia;
