import React, { useEffect, useMemo, useRef } from "react";
import "./OnDemandAppointmentWaiting.scss";
import useSound from "use-sound";
import loadingFx from "../../../../../assets/raw/loading.wav";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useAppointmentActions } from "../../../../../hooks/useReduxActions";
import { Appointment } from "../../../../../interfaces/Appointment";

interface Props {
  appointmentId: string;
  handleCloseDrawer: () => void;
  modalToggler: boolean;
  openAppointmentDetail: (appointment: Appointment) => void;
}

const OnDemandAppointmentWaiting: React.FC<Props> = ({
  modalToggler,
  appointmentId,
  handleCloseDrawer,
  openAppointmentDetail,
}) => {
  const [play, { stop, duration }] = useSound(loadingFx);

  const timer = useRef<any>(null);

  const { currentAppointments: data } = useAppSelector(
    (state) => state.appointmentReducer
  );
  const { removeAppointment } = useAppointmentActions();

  const selectedAppointment = useMemo(() => {
    if (!appointmentId || data.length === 0) {
      return null;
    }

    return (
      (data.filter((e) => e.id === appointmentId)[0]
        ?.extendedProps as Appointment | null) || null
    );
  }, [data, appointmentId]);

  useEffect(() => {
    let interval: any = null;

    if (duration && modalToggler) {
      interval = setInterval(() => {
        play();
      }, duration + 100);
    }

    return () => clearInterval(interval);
  }, [duration, modalToggler]);

  useEffect(() => {
    if (selectedAppointment && selectedAppointment.status === "Accepted") {
      handleCloseDrawer();
      openAppointmentDetail(selectedAppointment);
    }
  }, [selectedAppointment]);

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (selectedAppointment && selectedAppointment.status === "Pending") {
        removeAppointment(appointmentId);
        handleCloseDrawer();
      }
    }, 1000 * 60 * 5);
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      stop();
    };
  }, []);

  return (
    <div className="ml-ondemand-appointment-waiting">
      <svg viewBox="0 0 100 100">
        <g
          fill="none"
          stroke="#28602E"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="6"
        >
          <path d="M 21 40 V 59">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              values="0 21 59; 180 21 59"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>

          <path d="M 79 40 V 59">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              values="0 79 59; -180 79 59"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>

          <path d="M 50 21 V 40">
            <animate
              attributeName="d"
              values="M 50 21 V 40; M 50 59 V 40"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>

          <path d="M 50 60 V 79">
            <animate
              attributeName="d"
              values="M 50 60 V 79; M 50 98 V 79"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>

          <path d="M 50 21 L 79 40 L 50 60 L 21 40 Z">
            <animate
              attributeName="stroke"
              values="rgba(40,96,46,1); rgba(100,100,100,0)"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>

          <path d="M 50 40 L 79 59 L 50 79 L 21 59 Z" />

          <path d="M 50 59 L 79 78 L 50 98 L 21 78 Z">
            <animate
              attributeName="stroke"
              values="rgba(100,100,100,0); rgba(40,96,46,1)"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            values="0 0; 0 -19"
            dur="2s"
            repeatCount="indefinite"
          />
        </g>
      </svg>

      <h4>
        Please wait. We are looking for a suitable teacher for your On-Demand
        request
      </h4>
    </div>
  );
};

export default OnDemandAppointmentWaiting;
