import { Route } from "react-router-dom";
import { RequireAuth } from "../../../HoC/RequireAuth";
import { AllRouteConstants } from "../../../router/routes";
import { Account, Payment, Support, Profile, SettingsHome } from "../pages";
import { TwoFAVerification } from "../pages/Sub_Pages/accountSubPages/2FAVerification";
import { ChangePassword } from "../pages/Sub_Pages/accountSubPages/ChangePassword";
import { ChangePhoneNumber } from "../pages/Sub_Pages/accountSubPages/ChangePhoneNumber";
import { Enable2FA } from "../pages/Sub_Pages/accountSubPages/Enable2FA";
import { PhoneVerification } from "../pages/Sub_Pages/accountSubPages/PhoneVerification";
import { useAppSelector } from "../../../hooks/useAppSelector";

const SettingsRoutes = () => {
  const { currentScreen } = useAppSelector((state) => state.screenStateReducer);

  return (
    <>
      <Route
        index
        element={
          currentScreen === "mobile" ? (
            <SettingsHome />
          ) : (
            <Profile mode="view" />
          )
        }
      />
      <Route
        path={AllRouteConstants.main.settings.profile.index}
        element={<Profile mode="view" />}
      />
      <Route
        path={AllRouteConstants.main.settings.profile.edit}
        element={<Profile mode="edit" />}
      />
      <Route
        path={AllRouteConstants.main.settings.account.index}
        element={<Account />}
      />

      <Route
        path={AllRouteConstants.main.settings.account.changePassword}
        element={<ChangePassword />}
      />
      <Route
        path={AllRouteConstants.main.settings.account.changePhone}
        element={<ChangePhoneNumber />}
      />
      <Route
        path={AllRouteConstants.main.settings.account.enable2Fa.index}
        element={<Enable2FA />}
      />
      <Route
        path={
          AllRouteConstants.main.settings.account.enable2Fa.phoneVerification
        }
        element={<PhoneVerification />}
      />
      <Route
        path={
          AllRouteConstants.main.settings.account.enable2Fa.twofaVerification
        }
        element={<TwoFAVerification />}
      />
      <Route
        path={AllRouteConstants.main.settings.payment}
        element={<Payment />}
      />
      <Route
        path={AllRouteConstants.main.settings.support}
        element={<Support />}
      />
    </>
  );
};

export default SettingsRoutes;
