import AccountInfoCard from "../components/AccountInfoCard/AccountInfoCard";
import "./SettingsStyles.scss";
import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { useAppSelector } from "../../../hooks/useAppSelector";

export const Account = () => {
  const navigate = useNavigate();
  const { profile_data } = useAppSelector((state) => state.authReducer);

  const goToPhoneNumber = () => {
    navigate(AllRouteConstants.main.settings.account.changePhone);
  };
  const goToPassword = () => {
    navigate(AllRouteConstants.main.settings.account.changePassword);
  };
  const goTo2FA = () => {
    navigate(AllRouteConstants.main.settings.account.enable2Fa.index);
  };
  return (
    <div className="settings_account_container animate__animated animate__fadeIn">
      <div className="settings_account_inner_container">
        <AccountInfoCard
          title="Email"
          info={profile_data?.email ?? "Pelumisoneye@gmail.com"}
        />
        <AccountInfoCard
          title="Phone Number"
          info={profile_data?.phone_number || "-"}
          buttonContent="Edit"
          onClick={goToPhoneNumber}
        />
        <AccountInfoCard
          title="Password"
          info="Change your password"
          buttonContent="Change"
          onClick={goToPassword}
        />
        <AccountInfoCard
          title="Two-Factor Authentication (2FA)"
          info={profile_data?.activeMfa.length > 0 ? "Enabled" : "Disabled"}
          buttonContent="Modify"
          onClick={goTo2FA}
        />
      </div>
    </div>
  );
};
