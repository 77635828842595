const DEV_BASE_URL = "https://dev-api.mobilearnings.com";

const PROD_BASE_URL = "https://api.mobilearnings.com";

export const API_URL =
  process.env.REACT_APP_STAGE === "development" ? DEV_BASE_URL : PROD_BASE_URL;

const DEV_SOCKET_URL =
  "wss://a5zvxq0cvk.execute-api.us-east-1.amazonaws.com/dev";

const PROD_SOCKET_URL =
  "wss://6d7lv2g3pl.execute-api.us-east-1.amazonaws.com/dev";

export const APPOINTMENT_SOCKET_URL =
  process.env.REACT_APP_STAGE === "development"
    ? DEV_SOCKET_URL
    : PROD_SOCKET_URL;

export const AGORA_ID = "48d1afea17be43b39a3cd31b34de56f7";
