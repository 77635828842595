import React, { ChangeEventHandler } from "react";
import AppointmentDropdown from "../AppointmentDropdow/AppointmentDropdown";
import { IconContext } from "react-icons";
import { MdRefresh, MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface Props {
  mode: string;
  title: string;
  addbutton: JSX.Element;
  next: VoidFunction;
  prev: VoidFunction;
  today: VoidFunction;
  handleModeChange: ChangeEventHandler<HTMLSelectElement>;
}

const modes = [
  {
    value: "dayGridMonth",
    label: "Month",
  },
  {
    value: "timeGridWeek",
    label: "Week",
  },
  {
    value: "timeGridDay",
    label: "Day",
  },
  {
    value: "listWeek",
    label: "List",
  },
];

const CustomHeader: React.FC<Props> = (props) => {
  const { profile_data } = useAppSelector((state) => state.authReducer);

  return (
    <IconContext.Provider value={{ color: "#fff", size: "24px" }}>
      <div className="ml-calendar-custom-header">
        <div className="nav">
          <button className="left" type="button" onClick={props.prev}>
            <MdNavigateBefore />
          </button>
          <button className="right" type="button" onClick={props.next}>
            <MdNavigateNext />
          </button>

          <button className="today" onClick={props.today}>
            <MdRefresh />
          </button>
        </div>

        <div className="title">
          <h1>{props.title}</h1>
        </div>

        <div className="actions">
          <AppointmentDropdown
            options={modes}
            label=""
            value={props.mode}
            handleChange={props.handleModeChange}
          />

          {profile_data?.role === "STUDENT" && props.addbutton}
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default CustomHeader;
