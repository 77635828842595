import React from "react";

import "./CallMessagesStyles.scss";
import { CallMessage } from "../../../../redux/call/reducer";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface CallMessageProps {
  message: CallMessage;
}
const SingleCallMessage = (props: CallMessageProps) => {
  const { message } = props;

  const { users } = useAppSelector((state) => state.callReducer);
  const { profile_data } = useAppSelector((state) => state.authReducer);

  const currentUser = users.filter((user) => user.uid === message.senderId)[0];

  return (
    <div
      className={`call_message ${
        profile_data.email === message.senderId ? "personal" : "external"
      }`}
    >
      <p>{currentUser.name}</p>
      <h3>{message.message}</h3>
    </div>
  );
};

export default SingleCallMessage;
