import React from "react";
import "./styles.scss";

import { ReactComponent as WarningIcon } from "../../assets/bx-warning.svg";
import Button from "../Button/Button";

interface ICustomAlert {
  header?: string;
  actionLabel?: string;
  onActionClick?: VoidFunction;
  message: string;
}

const CustomAlert: React.FC<ICustomAlert> = (props) => {
  const { header, message, actionLabel, onActionClick } = props;

  return (
    <div className="ml-custom-alert">
      <div>
        <div>
          <WarningIcon />
          <p>
            <span>{header} </span> {message}
          </p>
        </div>

        {actionLabel && onActionClick && (
          <span>
            <Button
              size="md"
              label={actionLabel}
              variant="primary"
              onClick={onActionClick}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomAlert;
