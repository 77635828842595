import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import useApi from "../../../hooks/useApi";
import { Appointment } from "../../../interfaces/Appointment";
import { useAppointmentActions } from "../../../hooks/useReduxActions";
import { Profile_Interface } from "../../../interfaces/User";
import appointmentService from "../../../services/AppointmentService/AppointmentService";
import { toast } from "react-toastify";

function useAppointmentsCalendar() {
  const appointmentActions = useAppointmentActions();
  const { currentAppointments, loadingCurrentAppointments } = useAppSelector(
    (state) => state.appointmentReducer
  );

  const httpGetAppointments = useApi<Appointment[], { start: Date; end: Date }>(
    appointmentService.getAppointments
  );

  const getAppointments = useCallback(async (start: Date, end: Date) => {
    appointmentActions.setLoadingCurrentAppointments(true);
    const appointments = await httpGetAppointments.request({ start, end });

    if (appointments) {
      appointmentActions.setCurrentAppointments(appointments);
    }

    appointmentActions.setLoadingCurrentAppointments(false);
  }, []);

  const httpGetAppointmentTeacher = useApi<Profile_Interface | null, string>(
    appointmentService.getAppointmentTeacher
  );

  const getTeacher = useCallback(async (id: string) => {
    const teacher = await httpGetAppointmentTeacher.request(id);
    if (teacher) {
      appointmentActions.editAppointment({
        appointmentId: id,
        data: {
          acceptedBy: teacher,
        },
      });
    }
  }, []);

  const httpDeleteAppointment = useApi<{ message: string }, string>(
    appointmentService.deleteAppointment
  );

  const deleteAppointment = useCallback(async (appointmentId: string) => {
    try {
      const result = await httpDeleteAppointment.request(appointmentId);

      if (result) {
        appointmentActions.removeAppointment(appointmentId);
        return true;
      }
    } catch (error) {}

    return false;
  }, []);

  return {
    getAppointments,
    getTeacher,
    deleteAppointment,
    currentAppointments,
    loadingCurrentAppointments,
    deleting: httpDeleteAppointment.loading,
  };
}

export type TuseAppointmentsCalendar = ReturnType<
  typeof useAppointmentsCalendar
>;

export default useAppointmentsCalendar;
