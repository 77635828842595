export const RE_DIGIT = new RegExp(/^\d+$/);

export const AllRouteConstants = {
  auth: {
    index: "/auth",
    login: "/auth/signin",
    signup: "/auth/signup",
    forgotPassword: "/auth/forgot-password",
    verification: "/auth/verification",
    resetPassword: "/auth/reset-password",
    signUpVerification: "/auth/signupverification/",
    tfaVerification: "/auth/signin/verify",
  },
  main: {
    dashboard: "/",
    appointment: {
      index: "/appointment",
      edit: "/appointment/edit",
    },
    history: {
      index: "/history",
    },
    checkout: {
      index: "/checkout",
    },
    call: {
      index: "/call",
      whiteboard: "/call/whiteboard",
    },
    settings: {
      index: "/settings",
      profile: {
        index: "/settings/profile",
        edit: "/settings/profile/edit",
      },
      account: {
        index: "/settings/account",
        changePassword: "/settings/account/change-password",
        changePhone: "/settings/account/change-phone-number",
        enable2Fa: {
          index: "/settings/account/enable-2fa",
          phoneVerification: "/settings/account/enable-2fa/phone-verification",
          twofaVerification: "/settings/account/enable-2fa/2fa",
        },
      },
      payment: "/settings/payment",
      support: "/settings/support",
    },
  },
};
