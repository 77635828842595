import produce from "immer";
import { ActionConstants } from "./action-constants";
import { Card, PaymentHistory } from "../../interfaces/Checkout";
import { PaymentActionTypes } from "./action-types";

interface initialStateInterface {
  cards: Card[];
  history: PaymentHistory[];
  initialized_cards: boolean;
  initialized_history: boolean;
}

const initialState: initialStateInterface = {
  cards: [],
  history: [],
  initialized_cards: false,
  initialized_history: false,
};

export const paymentReducer = (
  state: initialStateInterface = initialState,
  Action: PaymentActionTypes
): initialStateInterface => {
  return produce(state, (paymentState) => {
    switch (Action.type) {
      case ActionConstants.INIT_CARDS:
        paymentState.cards = Action.payload;
        paymentState.initialized_cards = true;
        return paymentState;

      case ActionConstants.ADD_CARD:
        paymentState.cards = [Action.payload, ...paymentState.cards];
        return paymentState;

      case ActionConstants.REMOVE_CARD:
        paymentState.cards = paymentState.cards.filter(
          (item) => item.cardId !== Action.payload
        );
        return paymentState;

      case ActionConstants.INIT_HISTORY:
        paymentState.history = Action.payload;
        paymentState.initialized_history = true;
        return paymentState;

      default:
        return paymentState;
    }
  });
};
