import { useState } from "react";
import visible from "../../../../../assets/visible.svg";
import notvisible from "../../../../../assets/not-visible.svg";
import Header from "../../../../../components/Header/Header";
import Input from "../../../../../components/Input/Input";
import { useForm } from "../../../../../hooks/useForm";
import { ChangePasswordInput } from "../../../../../interfaces/settingsInterfaces/SettingsAcountInputs";
import Button from "../../../../../components/Button/Button";
import BackButton from "../../../components/BackButton/BackButton";
import { AllRouteConstants } from "../../../../../router/routes";
import { passwordValidator } from "../../../../../utils/validators/passwordValidator";
import settingsService from "../../../../../services/profileService/ProfileService";
import useApi from "../../../../../hooks/useApi";
import AuthError from "../../../../auth/components/AuthError/AuthError";

const changePassword = (data: ChangePasswordInput) =>
  settingsService.changePassword(data);

export const ChangePassword = () => {
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const changePasswordForm = useForm<ChangePasswordInput>(
    { oldPassword: "", newPassword: "" },
    { newPassword: passwordValidator }
  );

  const changePasswordRequest = useApi<
    ChangePasswordInput,
    ChangePasswordInput
  >(changePassword);

  /**
   * Adds whatever we input into our form handler for the Old password
   * @param e
   */
  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    changePasswordForm.onChange("oldPassword", e.target.value);
  };

  /**
   * Adds whatever we input into our form handler for the new password
   * @param e
   */
  const handleChangeNewPassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    changePasswordForm.onChange("newPassword", e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    changePasswordForm.resetFormErrors();
    changePasswordRequest.reset();

    const valid = changePasswordForm.validate();

    if (valid) {
      try {
        const result = await changePasswordRequest.request(
          changePasswordForm.form
        );

        if (result) {
          // SHOW SUCCESS MODAL
          changePasswordForm.reset();
        }
      } catch (error) {}
    }
  };

  return (
    <div className="settings_account_container">
      <div style={{ marginLeft: "35px" }}>
        <BackButton route={AllRouteConstants.main.settings.account.index} />

        <Header message="Password" />

        <form onSubmit={handleSubmit}>
          <div className="account_change_password_container">
            <Input
              id="password"
              label="Current Password"
              inputClassName="account_change_password_input"
              error={changePasswordForm.formErrors.oldPassword}
              rightIcon={
                <div style={{ marginLeft: "10px" }} onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <img src={visible} style={{ width: "20px" }} />
                  ) : (
                    <img src={notvisible} style={{ width: "20px" }} />
                  )}
                </div>
              }
              inputProps={{
                type: passwordType,
                placeholder: "Enter your Old password",
                value: changePasswordForm.form.oldPassword,
                onChange: handleChangePassword,
              }}
            />
            <Input
              id="newPassword"
              label="New Password"
              inputClassName="account_change_password_input"
              error={changePasswordForm.formErrors.newPassword}
              rightIcon={
                <div style={{ marginLeft: "10px" }} onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <img src={visible} style={{ width: "20px" }} />
                  ) : (
                    <img src={notvisible} style={{ width: "20px" }} />
                  )}
                </div>
              }
              inputProps={{
                type: passwordType,
                placeholder: "Enter your New password",
                value: changePasswordForm.form.newPassword,
                onChange: handleChangeNewPassword,
              }}
            />
          </div>
          <div className="account_form_error_container">
            {/* Error Handler */}

            <AuthError error={changePasswordRequest.error} />

            <Button
              loading={changePasswordRequest.loading}
              label="Save Changes"
              variant="primary"
              width="fit-content"
              disable={
                !changePasswordForm.form.newPassword ||
                !changePasswordForm.form.oldPassword
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
