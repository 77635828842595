import { Outlet, useLocation } from "react-router-dom";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import "../DashboardLayoutStyles.scss";
import NotificationsAndAvatar from "../../../components/NotificationsAndAvatar/NotificationsAndAvatar";
import { useMemo } from "react";
import { AllRouteConstants } from "../../../router/routes";

const hiddenNotifHeaderPaths = [
  AllRouteConstants.main.dashboard,
  AllRouteConstants.main.call.index,
  AllRouteConstants.main.call.whiteboard,
  AllRouteConstants.main.settings.index,
  AllRouteConstants.main.settings.account.index,
  AllRouteConstants.main.settings.account.changePassword,
  AllRouteConstants.main.settings.account.changePhone,
  AllRouteConstants.main.settings.account.enable2Fa.index,
  AllRouteConstants.main.settings.account.enable2Fa.phoneVerification,
  AllRouteConstants.main.settings.account.enable2Fa.twofaVerification,
  AllRouteConstants.main.settings.payment,
  AllRouteConstants.main.settings.profile.index,
  AllRouteConstants.main.settings.profile.edit,
  AllRouteConstants.main.settings.support,
];

const DashboardDesktop = () => {
  const { pathname } = useLocation();

  const hideNotifHeader = useMemo(() => {
    return hiddenNotifHeaderPaths.includes(pathname);
  }, [pathname]);

  const title = useMemo(() => {
    switch (pathname) {
      case AllRouteConstants.main.appointment.index:
        return "Appointments";

      case AllRouteConstants.main.history.index:
        return "History";

      case AllRouteConstants.main.checkout.index:
        return "Checkout";

      default:
        return "";
    }
  }, [pathname]);

  return (
    <div className="dashboard_layout_desktop">
      <DashboardSidebar />
      <div className="dashboard_layout_outlet_container">
        {!hideNotifHeader && (
          <div className="header">
            <h2>{title}</h2>

            <NotificationsAndAvatar />
          </div>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default DashboardDesktop;
