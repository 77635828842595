import axios from "axios";
import { API_URL } from "../../config/env";
import {
  IAppointmentInviteRequest,
  IAppointmentRequest,
} from "../../interfaces/CreateAppointment";

class AppointmentService {
  createNewAppointment = (data: IAppointmentRequest) => {
    return axios.post(
      `${API_URL}/appointments`,
      {
        ...data,
        users: data.users ? data.users.map((e) => e.id) : [],
      },
      {
        withCredentials: true,
      }
    );
  };

  deleteAppointment = (appointmentId: string) => {
    return axios.delete(
      `${API_URL}/appointments?appointment=${appointmentId}`,
      {
        withCredentials: true,
      }
    );
  };

  acceptAppointments = (appointmentId: string) => {
    return axios.post(
      `${API_URL}/appointments/accept`,
      { appointmentId },
      {
        withCredentials: true,
      }
    );
  };

  getAppointmentById = async (appointmentId: string) => {
    return await axios.get(`${API_URL}/appointments/${appointmentId}`, {
      withCredentials: true,
    });
  };

  getAppointmentCourses = () => {
    return axios.get(`${API_URL}/appointments/courses`, {
      withCredentials: true,
    });
  };

  getUserDetails = (data: IAppointmentInviteRequest) => {
    return axios.post(`${API_URL}/appointments/invite`, data, {
      withCredentials: true,
    });
  };

  getAgoraToken = (appointmentId: string) => {
    return axios.get(
      `${API_URL}/appointments/call-token?appointmentId=${appointmentId}`,
      {
        withCredentials: true,
      }
    );
  };

  getInviteDetails = (hash: string) => {
    return axios.get(`${API_URL}/appointments/invite?hash=${hash}`, {
      withCredentials: true,
    });
  };

  updateInviteDetails = (hash: string, action: "accept" | "decline") => {
    return axios.patch(
      `${API_URL}/appointments/invite`,
      { hash, action },
      { withCredentials: true }
    );
  };

  getAppointments({ start, end }: { start: Date; end: Date }) {
    return axios.get(
      `${API_URL}/appointments/by-date?start=${start.getTime()}&end=${end.getTime()}`,
      { withCredentials: true }
    );
  }

  getAppointmentTeacher(appointmentId: string) {
    return axios.get(`${API_URL}/appointments/${appointmentId}/teacher`, {
      withCredentials: true,
    });
  }
}

const appointmentService = new AppointmentService();
export default appointmentService;
