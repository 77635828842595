import "./CallLeftContentStyles.scss";
import { BsFillPeopleFill, BsFillSendFill, BsPeopleFill } from "react-icons/bs";
import VideoCall from "../VideoCall";
import { ICameraVideoTrack } from "agora-rtc-react";
import { CallUser } from "../../../../interfaces/Call";
import { AiFillWarning } from "react-icons/ai";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import VideoCallControls from "../Controls";
import { IUserCallActions } from "../../interfaces/IUserCallActions";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { AiOutlineClose } from "react-icons/ai";
import CallMessages from "../CallMessages/CallMessages";
import { useCallActions } from "../../../../hooks/useReduxActions";

interface ICallLeftContent {
  courseName: string;
  adminUser: CallUser;
  action: (
    action: IUserCallActions,
    payload?: any,
    user?: CallUser
  ) => Promise<void>;
}

const CallLeftContent = (props: ICallLeftContent) => {
  const { courseName, adminUser, action } = props;

  const messages = useAppSelector((state) => state.callReducer.messages);

  const { users } = useAppSelector((state) => state.callReducer);

  const { startCall } = useCallActions();

  const [message, setMessage] = useState("");

  const handleMessageSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    action("sendmessage", message);
    setMessage("");
  };

  const msgModalRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [isMsgOpen, setIsMsgOpen] = useState(false);
  const toggleMessageOpen = () => setIsMsgOpen(!isMsgOpen);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [messages.length]);

  useEffect(() => {
    return () => {
      startCall(false);
    };
  }, []);

  return (
    <div className="ml_call_home-left_sidebar">
      <div className="ml_call_home-left_sidebar_container">
        <div className="ml_call_home-left_sidebar_navbar">
          <h2>{courseName} Session</h2>
          <span>
            Class <BsFillPeopleFill />
          </span>
        </div>

        <div className="ml_call_home-left_sidebar_body">
          <CSSTransition
            timeout={500}
            nodeRef={msgModalRef}
            classNames={"message-modal"}
            in={isMsgOpen}
            unmountOnExit
          >
            <div ref={msgModalRef} className="message-modal">
              <div className="message-modal__nav">
                <span className="close" onClick={toggleMessageOpen}>
                  <AiOutlineClose />
                </span>
              </div>

              <div className="message-modal__content">
                <CallMessages />

                <div ref={bottomRef}></div>
              </div>

              <div className="message-modal__action">
                <form
                  onSubmit={handleMessageSubmit}
                  className="message-modal__action_message"
                >
                  <input
                    placeholder="Type in Message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <button>
                    <BsFillSendFill className="icon" />
                  </button>
                </form>
              </div>
            </div>
          </CSSTransition>

          <div className="participants_info">
            <div className="participants_amount">
              <BsPeopleFill className="icon" />
              Participants
              <span>{users.filter((user) => !user.admin).length}</span>
            </div>
            <div className="absentee_amount">
              <AiFillWarning className="icon" />
              Absent People
              <span>0</span>
            </div>
          </div>

          <div className="ml_call_home-left_sidebar_body_call">
            <VideoCall adminUser={adminUser} />
          </div>
        </div>

        <VideoCallControls
          adminUser={adminUser}
          action={action}
          toggleMessage={toggleMessageOpen}
        />
      </div>
    </div>
  );
};

export default CallLeftContent;
