import { Profile_Interface, User } from "../../interfaces/User";
import produce from "immer";
import { ActionConstants } from "./action-constants";
import { AuthActionTypes } from "./action-types";

interface initialStateInterface {
  user_data: User | null;
  profile_data: Profile_Interface;
}

const initialState = {
  user_data: null,
  profile_data: {
    id: "",
    email: "",
    parent_email: "",
    email_verified: "false",
    given_name: "",
    family_name: "",
    address: "",
    parent_phone_number: "",
    phone_number_verified: "false",
    birthdate: "",
    profile_picture: "",
    phone_number: "",
    activeMfa: [],
    role: undefined,
  },
};

export const authReducer = (
  state: initialStateInterface = initialState,
  Action: AuthActionTypes
): initialStateInterface => {
  return produce(state, (auth) => {
    switch (Action.type) {
      case ActionConstants.LOGIN:
        auth.user_data = Action.payload;
        return auth;

      case ActionConstants.LOGOUT:
        auth.user_data = null;
        auth.profile_data = {
          id: "",
          email: "",
          parent_email: "",
          email_verified: "false",
          given_name: "",
          family_name: "",
          address: "",
          parent_phone_number: "",
          phone_number_verified: "false",
          birthdate: "",
          profile_picture: "",
          phone_number: "",
          activeMfa: [],
          role: undefined,
        };
        return auth;

      case ActionConstants.SET_PROFILE:
        auth.profile_data = Action.payload;
        return auth;
      case ActionConstants.UPDATE_PROFILE:
        auth.profile_data[Action.payload.key] = Action.payload.data;
        return auth;

      default:
        return auth;
    }
  });
};
