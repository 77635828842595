import { Space } from "antd";
import Button from "../../../../components/Button/Button";
import { ReactComponent as WarningIcon } from "../../../../assets/bx-warning.svg";
import "./DashboardLeftStyles.scss";
import DashboardMotivation from "../DashboardMotivation/DashboardMotivation";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import { AllRouteConstants } from "../../../../router/routes";
import { useNavigate } from "react-router-dom";
import { Profile_Interface } from "../../../../interfaces/User";
import LearningActivity from "../DashboardLearningActivity/LearningActivity";
import { setGreetings } from "../../../../utils/setGreetings";
import CopyWithToolTip from "../../../../components/CopyWithToolTip/CopyWithToolTip";
import CustomAlert from "../../../../components/Alert";

const DashboardLeft = () => {
  const { profile_data } = useAppSelector((state) => state.authReducer);
  const [profileComplete, setProfileComplete] = useState<boolean>(false);
  const navigate = useNavigate();
  const checkIfProfileComplete = () => {
    for (let value in profile_data) {
      if (!profile_data[value as keyof Profile_Interface]) {
        setProfileComplete(false);
        return;
      }
    }
    setProfileComplete(true);
  };
  useEffect(() => {
    checkIfProfileComplete();
  }, []);

  return (
    <div className="dashboard_left_container">
      <h1 className="dashboard_left_container_header">
        Good {setGreetings()},{" "}
        <span>{profile_data?.family_name ?? "Oluwapelumi"} 😇</span>
      </h1>
      {!profileComplete && (
        <CustomAlert
          header="Warning"
          message="Incomplete profile registration, kindly ensure all fields are completed"
          onActionClick={() =>
            navigate(AllRouteConstants.main.settings.profile.index)
          }
          actionLabel="Update Profile"
        />
      )}
      <DashboardMotivation />
      <LearningActivity />
    </div>
  );
};

export default DashboardLeft;
