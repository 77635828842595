// Package Imports
import { useState } from "react";

// Styles
import "./AppointmentBodyStyles.scss";

// Component Imports
import CreateAppointmentDrawer from "../AppointmentModals/CreateAppointmentDrawer";
import AppointmentFullCalendar from "../AppointmentFullCalendar/AppointmentFullCalendar";
import AppointmentInformationDrawer from "../AppointmentModals/AppointmentInformationDrawer";
import { Appointment } from "../../../../interfaces/Appointment";
import Button from "../../../../components/Button/Button";
import { CreateAppointmentData } from "../../../../interfaces/CreateAppointment";
import useAppointmentsCalendar from "../../hooks/useAppointmentsCalendar";

type TDrawerTogglers = {
  createAppointment: boolean;
  appointmentInfo: boolean;
  selectedAppointment: string;
  createAppointmentData: CreateAppointmentData | null;
};

const AppointmentBody = () => {
  const appointmentCalendarController = useAppointmentsCalendar();

  const [drawerTogglers, setDrawerToogglers] = useState<TDrawerTogglers>({
    createAppointment: false,
    appointmentInfo: false,
    selectedAppointment: "",
    createAppointmentData: null,
  });

  const openAppointmentDetail = (appointment: Appointment) => {
    setDrawerToogglers((prev) => ({
      ...prev,
      appointmentInfo: true,
      selectedAppointment: appointment.appointmentId,
    }));
  };

  const closeAppointmentDetail = () =>
    setDrawerToogglers((prev) => ({
      ...prev,
      appointmentInfo: false,
      selectedAppointment: "",
    }));

  const openCreateAppointment = (
    createAppointmentData: CreateAppointmentData | null
  ) => {
    setDrawerToogglers((prev) => {
      return {
        ...prev,
        createAppointment: true,
        appointmentInfo: false,
        createAppointmentData,
      };
    });
  };

  const closeCreateAppointment = () =>
    setDrawerToogglers((prev) => ({
      ...prev,
      createAppointment: false,
      createAppointmentData: null,
    }));

  return (
    <>
      <CreateAppointmentDrawer
        drawerToggler={drawerTogglers.createAppointment}
        openAppointmentDetail={openAppointmentDetail}
        onClose={closeCreateAppointment}
        createAppointmentData={drawerTogglers.createAppointmentData}
      />

      <AppointmentInformationDrawer
        drawerToggler={Boolean(
          drawerTogglers.appointmentInfo && drawerTogglers.selectedAppointment
        )}
        onClose={closeAppointmentDetail}
        appointment={drawerTogglers.selectedAppointment}
        getTeacher={appointmentCalendarController.getTeacher}
        deleteAppointment={appointmentCalendarController.deleteAppointment}
        deleting={appointmentCalendarController.deleting}
      />

      {/* Appointment Body */}
      <AppointmentFullCalendar
        addbutton={
          <Button
            label="Request Appointment"
            variant="primary"
            buttonClassName="appointment_add_button"
            onClick={() => openCreateAppointment(null)}
          />
        }
        openAppointmentDetail={openAppointmentDetail}
        openCreateAppointment={openCreateAppointment}
        appointmentCalendarController={appointmentCalendarController}
      />
    </>
  );
};

export default AppointmentBody;
