import "./WelcomeSectionLeft.scss";

interface ImagesProps {
  maleImage: string;
  femaleImage: string;
}
const WelcomeSectionLeft = (props: ImagesProps) => {
  return (
    <div className="welcome_section_container">
      <div className="welcome_section_inner_container">
        <div className="welcome_section_child_one">
          <img alt="Two ladies teaching" src={props.femaleImage} />
        </div>
        <div className="welcome_section_child_two">
          <img alt="Guy with laptop" src={props.maleImage} />
        </div>
      </div>

      <p className="welcome_section_child_text">
        Learn at the comfort of your house
      </p>
    </div>
  );
};

export default WelcomeSectionLeft;
