import Header from "../../../../../components/Header/Header";
import BackButton from "../../../components/BackButton/BackButton";
import { AllRouteConstants } from "../../../../../router/routes";
import authService from "../../../../../services/authenticationService/AuthService";
import useApi from "../../../../../hooks/useApi";
import QRCode from "react-qr-code";
import "../../SettingsStyles.scss";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import { useForm } from "../../../../../hooks/useForm";
import { emptyValidator } from "../../../../../utils/validators/emptyValidator";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TwoFactorVerifiedModal from "../../../components/Modals/TwoFactorVerifiedModal";
import { useAuthActions } from "../../../../../hooks/useReduxActions";

const sendTOTPCode = (data: { code: string }) => authService.postTOTP(data);

export const TwoFAVerification = () => {
  const sendTOTPCodeRequest = useApi<any, { code: string }>(sendTOTPCode);
  const [totpVerified, setTotpVerified] = useState(false);
  const verificationForm = useForm<{ code: string }>(
    {
      code: "",
    },
    {
      code: emptyValidator,
    }
  );
  const handleChange = (e: any) => {
    verificationForm.onChange(e.target.name, e.target.value);
  };

  const { updateProfile } = useAuthActions();

  useEffect(() => {
    if (sendTOTPCodeRequest.data?.Status == "SUCCESS") {
      // UPDATE PROFILE
      updateProfile({
        key: "activeMfa",
        data: sendTOTPCodeRequest.data.activeMfa,
      });
    }
  }, [sendTOTPCodeRequest.data]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await sendTOTPCodeRequest.request(verificationForm.form);
      if (response) {
        setTotpVerified(true);
      }
    } catch (error) {}
  };
  const location = useLocation();
  const navigate = useNavigate();
  let secretCode: string = location?.state?.secretCode;

  useEffect(() => {
    if (!secretCode) {
      navigate(AllRouteConstants.main.settings.account.enable2Fa.index);
    }
  }, []);

  return (
    <div
      className="settings_account_container"
      style={{ paddingBottom: "1em" }}
    >
      <TwoFactorVerifiedModal
        modalToggler={totpVerified}
        onClose={() => {
          navigate(AllRouteConstants.main.settings.account.index);
          setTotpVerified(false);
        }}
      />
      <div className="settings_phone_verification">
        <BackButton
          route={AllRouteConstants.main.settings.account.enable2Fa.index}
        />
        <div>
          <div className="settings_phone_verification_container">
            <div className="settings_phone_verification_inner_container settings_twofa_inner_container">
              <h2>Enable Authenticator Application</h2>
              <p>
                Scan the image with any valid authenticator app on your phone
                and enter the verification code, if you cant use a barcode use
                the text code instead
              </p>
            </div>
          </div>
          <div className="settings_2fa_verification_qr_container">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`otpauth://totp/Mobilearnings?secret=${secretCode}`}
              viewBox={`0 0 256 256`}
            />

            <form className="" onSubmit={handleSubmit}>
              <h2>Verification Code</h2>
              <Input
                id="Verification Code"
                label=""
                error={verificationForm.formErrors.code}
                inputClassName="settings_2fa_verification_qr_input"
                inputProps={{
                  name: "code",
                  placeholder: "Enter your Verification Code",
                  value: verificationForm.form.code,
                  onChange: handleChange,
                  maxLength: 6,
                }}
              />
              <div className="settings_2fa_verification_buttons_container">
                <Button
                  label="Cancel"
                  variant="secondary"
                  type="button"
                  buttonClassName="twofa_reset_button"
                  onClick={() => {
                    navigate(
                      AllRouteConstants.main.settings.account.enable2Fa.index
                    );
                  }}
                />

                <Button
                  label="Verify"
                  variant="primary"
                  loading={sendTOTPCodeRequest.loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
