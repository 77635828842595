import { useDispatch } from "react-redux";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";
import {
  appointmentActions,
  authActions,
  callActions,
  redirectActions,
  paymentActions,
} from "../redux/allActions";

export const useReduxActions = (action: ActionCreatorsMapObject<any>) => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, action), dispatch);
};

export const useAuthActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, authActions), dispatch);
};

export const useAppointmentActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, appointmentActions), dispatch);
};

export const useCallActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, callActions), dispatch);
};

export const useRedirectActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, redirectActions), dispatch);
};

export const usePaymentActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(Object.assign({}, paymentActions), dispatch);
};
