import "./CallMessagesStyles.scss";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import SingleCallMessage from "./CallMessage";

const CallMessages = () => {
  const { messages } = useAppSelector((state) => state.callReducer);

  return (
    <div className="call_messages">
      <h5>Messages</h5>

      {messages.map((message, index) => (
        <SingleCallMessage key={index} message={message} />
      ))}
    </div>
  );
};

export default CallMessages;
